import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { AlertTriangle, Loader2, Lock } from "lucide-react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { CertificateRemove } from "@/pages/companies/[document]/components/certificate-remove.tsx";

export function CertificateEdit() {
  const { document } = useParams();

  const { data: credentials, isLoading } = useQuery<{
    hasCertificate: boolean;
    hasCredentials: boolean;
    certificate?: {
      name: string;
      document: string;
      expiresAt: string;
    };
  }>({
    queryKey: [`/companies/${document}/credentials`],
    enabled: !!document,
  });

  return (
    <div>
      <p className="font-semibold text-lg">
        Informações sobre o seu certificado digital
      </p>
      <p className="text-sm text-muted-foreground mt-2">
        A sua empresa possui um certificado digital configurado em nosso
        sistema. Abaixo você verá as informações do certificado e também a opção
        de remover o certificado.
      </p>
      <p className="text-xs font-light mt-4">
        <Lock
          size={15}
          className="inline-block mr-1 cover-fill text-green-600/80"
        />
        O seu certificado está salvo de forma segura e criptografada em nossos
        servidores.
      </p>
      <div className="mt-4 grid grid-cols-3 gap-x-3 gap-y-5">
        {isLoading && <Loader2 className="animate-spin h-6 w-6" />}
        {credentials?.certificate && (
          <>
            <div>
              <Label>Nome do certificado</Label>
              <Input value={credentials?.certificate?.name} disabled />
            </div>
            <div>
              <Label>CNPJ Vinculado</Label>
              <Input value={credentials?.certificate?.document} disabled />
            </div>
            <div>
              <Label>Vencimento</Label>
              <Input
                value={format(
                  credentials?.certificate?.expiresAt,
                  "dd/MM/yyyy"
                )}
                disabled
              />
            </div>
          </>
        )}
      </div>
      <div className="mt-6">
        <CertificateRemove />
        <p className="font-semibold text-sm mt-3">
          <AlertTriangle className="text-destructive/70 inline-block mr-1" />
          Atenção! Orientamos não remover o seu certificado sem necessidade.
        </p>
      </div>
    </div>
  );
}
