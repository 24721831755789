import { cn } from "@/lib/utils.ts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { ChevronDown, FileInput, FileOutput } from "lucide-react";

const NavButton = ({
  name,
  path,
  active,
}: {
  name: string;
  path: string;
  active?: boolean;
}) => {
  return (
    <Button asChild={true} variant="underline" data-active={active}>
      <Link to={path}>{name}</Link>
    </Button>
  );
};

export function MainNav() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <nav className={cn("flex items-center space-x-4 lg:space-x-6 mx-6")}>
        <NavButton
          name="Dashboard"
          path="/"
          active={location.pathname === "/"}
        />
        <NavButton
          name="Empresas"
          path="/companies"
          active={location.pathname.includes("/companies")}
        />
        <NavButton
          name="Fornecedores"
          path="/vendors"
          active={location.pathname.includes("/vendors")}
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="underline"
              data-active={location.pathname.includes("/invoices")}
            >
              Fiscal
              <ChevronDown className="ml-1 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => navigate("/invoices/sent")}>
              <FileOutput className="mr-2" /> Notas enviadas
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate("/invoices/received")}>
              <FileInput className="mr-2" /> Notas recebidas
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <NavButton
          name="Usuários"
          path="/users"
          active={location.pathname.includes("/users")}
        />
      </nav>
    </>
  );
}
