import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";

const services = [
  {
    id: "product",
    name: "NFe (Produtos)",
    disabled: false,
  },
  {
    id: "service",
    name: "NFS-e (Serviços)",
    disabled: false,
  },
  {
    id: "consumer",
    name: "NFC-e (Consumidor Final)",
    disabled: true,
  },
  {
    id: "cte",
    name: "CT-e (Transporte)",
    disabled: true,
  },
  {
    id: "mdfe",
    name: "MDF-e (Manifesto)",
    disabled: true,
  },
  {
    id: "recebimento_ctes",
    name: "Recebimento de CT-e",
    disabled: true,
  },
] as const;

const schema = z.object({
  services: z.array(
    z
      .string()
      .refine((value) => services.some((s) => s.id === value))
      .optional()
  ),
});

type FormServicesValues = z.infer<typeof schema>;

export function FormServices() {
  const { document } = useParams();

  const { data, isLoading } = useQuery<FormServicesValues>({
    queryKey: [`/companies/${document}/services`],
    enabled: !!document,
  });

  if (isLoading || !data || !document) {
    return <Loader2 size={24} className="animate-spin" />;
  }

  return <FormServicesCheckbox data={data} document={document} />;
}

function FormServicesCheckbox({
  data,
  document,
}: {
  data: FormServicesValues;
  document: string;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isMutationPending } = useMutation({
    mutationKey: [`/companies/${document}/services`],
    mutationFn: (values: FormServicesValues) => {
      return fetchApi(`/companies/${document}/services`, {
        method: "patch",
        data: values,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/services`],
      });
    },
  });

  const form = useForm<FormServicesValues>({
    resolver: zodResolver(schema),
    defaultValues: data,
  });

  const onSubmit = async (values: FormServicesValues) => {
    const mutation = await mutateAsync(values);

    if (mutation.status !== 200) {
      form.reset(data);
      toast.error("Erro ao atualizar serviços");
      return;
    }

    toast.success("Serviços atualizados com sucesso");
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="services"
          render={() => (
            <FormItem>
              <div className="mb-4">
                <FormLabel className="font-semibold text-lg">
                  Emissor de documentos fiscais suportados
                </FormLabel>
                <FormDescription>
                  Selecione os serviços disponíveis para a sua empresa
                </FormDescription>
              </div>
              {services.map((service) => (
                <FormField
                  key={service.id}
                  control={form.control}
                  name="services"
                  render={({ field }) => {
                    return (
                      <FormItem
                        key={service.id}
                        className="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <FormControl>
                          <Checkbox
                            disabled={service.disabled}
                            checked={field.value?.includes(service.id)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                form.setValue(
                                  "services",
                                  field.value
                                    ? [...field.value, service.id]
                                    : [service.id]
                                );
                              } else {
                                form.setValue(
                                  "services",
                                  field.value?.filter((s) => s !== service.id)
                                );
                              }
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {service.name}
                        </FormLabel>
                      </FormItem>
                    );
                  }}
                />
              ))}
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={isMutationPending}>
          {isMutationPending ? (
            <Loader2 size={16} className="animate-spin" />
          ) : (
            "Salvar"
          )}
        </Button>
      </form>
    </Form>
  );
}
