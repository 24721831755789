"use client";

import * as React from "react";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { cn } from "@/lib/utils.ts";
import { Button } from "@/components/ui/button.tsx";
import { ArrowLeft, Loader2, Rocket } from "lucide-react";
import { Link, useSearchParams } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { useEffect } from "react";
import { useLocalStorage } from "@/hooks/use-local-storage.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp.tsx";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const formSchema = z.object({
  code: z
    .string({
      required_error: "O código de autenticação é obrigatório",
    })
    .min(6, "Preencha o PIN com 6 dígitos"),
});

export function AuthCodeUserForm({ className, ...props }: UserAuthFormProps) {
  const [preAuthSessionId, setPreAuthSessionId, removePreAuthSessionId] =
    useLocalStorage<string | null>("preAuthSessionId", null);

  const [lastEmail] = useLocalStorage<string | null>("lastEmail", null);

  const [resendCooldown, setResendCooldown] = React.useState<number>(0);

  const [params] = useSearchParams();

  const queryClient = useQueryClient();

  const { mutate: mutateConsumeCode, isPending: isPendingConsumeCode } =
    useMutation({
      mutationKey: ["/auth/code/consume"],
      mutationFn: async (data: z.infer<typeof formSchema>) => {
        return fetchApi.post("/auth/code/consume", {
          code: data.code,
          preAuthSessionId: preAuthSessionId,
        });
      },
      onSuccess: async () => {
        toast.success(
          "Autenticado com sucesso, você será direcionado para a dashboard."
        );

        await queryClient.invalidateQueries({
          queryKey: ["users/me"],
        });

        console.log("Invalidating query...");

        removePreAuthSessionId();
      },
      onError: () => {
        toast.error(
          "Ocorreu um erro ao tentar autenticar com o código, tente novamente."
        );
      },
    });

  const { mutate: mutateResendCode, isPending: isPendingRequestCode } =
    useMutation({
      mutationKey: ["/auth/code/request"],
      mutationFn: async () => {
        return fetchApi.post<{
          data: {
            flow: string;
            preAuthSessionId: string;
          };
        }>("/auth/code/request", {
          email: lastEmail,
        });
      },
      onSuccess: (response) => {
        const { flow, preAuthSessionId } = response.data.data;

        if (flow === "INPUT_CODE_AND_MAGIC_LINK") {
          setPreAuthSessionId(preAuthSessionId);
          setResendCooldown(60);
        }
      },
    });

  useEffect(() => {
    if (resendCooldown > 0) {
      const interval = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [resendCooldown]);

  const isResendCodeOnCooldown = resendCooldown > 0;

  const codeForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: params.get("pin") ?? "",
    },
  });

  const handleResentCode = async () => {
    mutateResendCode();
  };

  const onSubmitForm = async (values: z.infer<typeof formSchema>) => {
    mutateConsumeCode(values);
  };

  return (
    <Form {...codeForm}>
      <form onSubmit={codeForm.handleSubmit(onSubmitForm)}>
        <div className={cn("grid gap-6", className)} {...props}>
          <div className="grid gap-2">
            <div className="grid gap-1">
              {lastEmail && (
                <Alert className="border-green-800/80 bg-green-300/20">
                  <AlertTitle className="flex items-center">
                    <Rocket size={20} className="mr-1" />
                    Tudo certo!
                  </AlertTitle>
                  <AlertDescription>
                    O código de autenticação foi enviado para o email{" "}
                    <span className="font-medium">{lastEmail}</span>. Verifique
                    a sua Caixa de Entrada ou Lixo Eletrônico.
                  </AlertDescription>
                </Alert>
              )}
              <FormField
                name="code"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2 items-center">
                    <FormLabel>Autenticação por Código de Acesso</FormLabel>
                    <FormControl>
                      <InputOTP maxLength={6} {...field}>
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormDescription>
                      <span className="text-xs text-gray-500">
                        Informe o código que você recebeu por email
                      </span>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button disabled={isPendingConsumeCode} type="submit">
              {isPendingConsumeCode && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Autenticar
            </Button>
            <Button
              type="button"
              variant="outline"
              disabled={
                !lastEmail || isResendCodeOnCooldown || isPendingRequestCode
              }
              onClick={handleResentCode}
            >
              {isPendingRequestCode && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              {isResendCodeOnCooldown
                ? `Código enviado (${resendCooldown}s)`
                : "Reenviar código"}
            </Button>
          </div>
          <p className="text-xs flex items-center hover:text-muted-foreground ease-in transform duration-100">
            <ArrowLeft size={12} />
            <Link to="/auth/login" className="ml-1">
              Voltar para a página inicial de login
            </Link>
          </p>
        </div>
      </form>
    </Form>
  );
}
