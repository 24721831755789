import type { ColumnDef, SortingFn } from "@tanstack/react-table";

import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";

import { Badge } from "@/components/ui/badge.tsx";
import {
  AlarmCheckIcon,
  CheckCheck,
  File,
  SquarePen,
  UploadCloud,
  X,
} from "lucide-react";
import { formatCNPJ, onlyNumbers } from "@shared/format.ts";
import { format } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { DataTableRowActions } from "@/components/table/data-table-row-actions.tsx";
import { pushModal } from "@/modals";
import { toast } from "sonner";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card.tsx";

interface SentDataTable {
  id: string;
  status: string;
  type: "product" | "service";
  issuer: {
    document: string;
    name: string;
  };
  recipient: {
    document: string;
    name: string;
  };
  createdAt: string;
}

const invoiceStatuses = [
  {
    id: "draft",
    label: "Rascunho",
    icon: SquarePen,
  },
  {
    id: "processing",
    label: "Processando",
    icon: UploadCloud,
  },
  {
    id: "pending",
    label: "Enviada",
    icon: AlarmCheckIcon,
  },
  {
    id: "success",
    label: "Concluída",
    icon: CheckCheck,
  },
  {
    id: "cancelled",
    label: "Cancelada",
    icon: X,
  },
  {
    id: "error",
    label: "Erro",
    icon: X,
  },
];

const receivedInvoiceStatuses = [
  {
    id: "authorized",
    label: "Autorizada",
    icon: CheckCheck,
  },
  {
    id: "cancelled",
    label: "Cancelada",
    icon: X,
  },
  {
    id: "denied",
    label: "Denegada",
    icon: X,
  },
];

const statuses = [...invoiceStatuses, ...receivedInvoiceStatuses];

const sortCreatedAt: SortingFn<SentDataTable> = (rowA, rowB, columnId) => {
  if (columnId === "createdAt") {
    return (
      new Date(rowA.original.createdAt).getTime() -
      new Date(rowB.original.createdAt).getTime()
    );
  }

  return 0;
};

export const columnsSent: ColumnDef<SentDataTable>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data" column={column} />
    ),
    cell: ({ row }) => (
      <div className="w-fit">
        {format(row.getValue("createdAt"), "dd/MM/yyyy HH:mm")}
      </div>
    ),
    enableHiding: false,
    sortingFn: sortCreatedAt,
  },
  {
    accessorKey: "issuer",
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id) as { document: string; name?: string };
      const inputValue = value.toLowerCase();

      const document = onlyNumbers(rowValue.document);
      const valueAsNumbers = onlyNumbers(inputValue);

      const matchesDocument =
        valueAsNumbers.length >= 1 && document.includes(valueAsNumbers);

      if (!rowValue.name) {
        return matchesDocument;
      }

      const matchesName = rowValue.name.toLowerCase().includes(inputValue);

      return matchesDocument || matchesName;
    },
    header: ({ column }) => (
      <DataTableColumnHeader title="Emissor" column={column} />
    ),
    cell: ({ row }) => {
      const issuer = row.getValue("issuer") as {
        document: string;
        name?: string;
      };

      const formattedIssuer = formatCNPJ(issuer.document);

      if (!issuer.name) {
        return <span className="overflow-hidden">{formattedIssuer}</span>;
      }

      return (
        <HoverCard openDelay={100}>
          <HoverCardTrigger>{issuer.name}</HoverCardTrigger>
          <HoverCardContent className="flex flex-col gap-2 w-80">
            <span>{issuer.name}</span>
            <span className="text-muted-foreground text-xs">
              CNPJ: {formattedIssuer}
            </span>
          </HoverCardContent>
        </HoverCard>
      );
    },
  },

  {
    accessorKey: "recipient",
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id) as { document: string; name?: string };
      const inputValue = value.toLowerCase();

      const document = onlyNumbers(rowValue.document);
      const valueAsNumbers = onlyNumbers(inputValue);

      const matchesDocument =
        valueAsNumbers.length >= 1 && document.includes(valueAsNumbers);

      if (!rowValue.name) {
        return matchesDocument;
      }

      const matchesName = rowValue.name.toLowerCase().includes(inputValue);

      return matchesDocument || matchesName;
    },
    header: ({ column }) => (
      <DataTableColumnHeader title="Tomador" column={column} />
    ),
    cell: ({ row }) => {
      const recipient = row.getValue("recipient") as {
        document: string;
        name: string;
      };

      const formattedRecipient = formatCNPJ(recipient.document);

      if (!recipient.name) {
        return <span>{formattedRecipient}</span>;
      }

      return (
        <HoverCard>
          <HoverCardTrigger>{recipient.name}</HoverCardTrigger>
          <HoverCardContent className="flex flex-col gap-2 w-80">
            <span>{recipient.name}</span>
            <span className="text-muted-foreground text-xs">
              CNPJ: {formattedRecipient}
            </span>
          </HoverCardContent>
        </HoverCard>
      );
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader title="Tipo" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <Badge variant="outline">
          {row.getValue("type") === "product" ? "Produto" : "Serviço"}
        </Badge>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "status",
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.id === row.getValue("status")
      );

      if (!status) {
        return (
          <div className="flex min-w-[50px] items-center">
            <span>{row.getValue("status")}</span>
          </div>
        );
      }

      return (
        <div className="flex min-w-[50px] items-center">
          {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <DataTableRowActions
          row={row}
          options={[
            {
              label: "Ver detalhes",
              icon: <File />,
              onClick: (row) => {
                if (row.original.type === "product") {
                  pushModal("ViewSentProductInvoice", {
                    id: row.original.id,
                  });
                } else if (row.original.type === "service") {
                  pushModal("ViewSentServiceInvoice", {
                    id: row.original.id,
                  });
                }
              },
            },
            {
              label: <span className={"text-destructive"}>Cancelar nota</span>,
              icon: <X className={"text-destructive"} />,
              onClick: (row) => {
                const status = row.original.status;
                const type = row.original.type;

                if (type === "product") {
                  if (status !== "authorized") {
                    toast.warning("Esta nota não pode ser cancelada.");
                    return;
                  }

                  // Check if passes more than 24 hours
                  if (
                    new Date().getTime() -
                      new Date(row.original.createdAt).getTime() >
                    24 * 60 * 60 * 1000
                  ) {
                    toast.error(
                      "Não foi possível processar o cancelamento da nota fiscal porque o prazo legal para solicitar o cancelamento já expirou. De acordo com a legislação vigente, o cancelamento de uma nota fiscal de produtos deve ser solicitado dentro de 24 horas após sua emissão.",
                      {
                        position: "top-center",
                        duration: 12000,
                      }
                    );
                    return;
                  }

                  pushModal("CancelSentProductInvoice", {
                    id: row.original.id,
                  });
                } else if (type === "service") {
                  if (status !== "success") {
                    toast.info("Esta nota não pode ser cancelada.");
                    return;
                  }

                  pushModal("CancelSentServiceInvoice", {
                    id: row.original.id,
                  });
                }
              },
            },
          ]}
        />
      );
    },
  },
];
