import { cn } from "@/lib/utils.ts";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { companySchema, RegimeTributary } from "@shared/schemas/company.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateCompanyStore } from "@/pages/companies/create/store/useCreateCompanyStore.ts";
import type { ComponentPropsWithoutRef } from "react";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

export function CreateCompanyTributes({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) {
  const { setBasicInfo, basicInfo, nextStep } = useCreateCompanyStore(
    (state) => ({
      setBasicInfo: state.setBasicInfo,
      basicInfo: state.basicInfo,
      nextStep: state.nextStep,
    })
  );

  const form = useForm<z.infer<typeof companySchema>>({
    resolver: zodResolver(companySchema),
    defaultValues: basicInfo,
  });

  const submit = (values: z.infer<typeof companySchema>) => {
    setBasicInfo({
      ...basicInfo,
      ...values,
    });

    nextStep();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <div className={cn("grid gap-6", className)} {...props}>
          <FormField
            name="municipalRegistration"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Inscrição Municipal</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="stateRegistration"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Inscrição Estadual</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="tributaryRegime"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Regime Tributário</FormLabel>
                <Select
                  onValueChange={(value) =>
                    field.onChange(Number.parseInt(value))
                  }
                  defaultValue={field.value.toString()}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione o regime tributário" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem
                      value={RegimeTributary.REGIME_NORMAL.toString()}
                    >
                      Regime Normal
                    </SelectItem>
                    <SelectItem
                      value={RegimeTributary.SIMPLES_NACIONAL.toString()}
                    >
                      Simples Nacional
                    </SelectItem>
                    <SelectItem
                      value={RegimeTributary.SIMPLES_NACIONAL_EXCESSO.toString()}
                    >
                      Simples Nacional - Excesso de Receita Bruta
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {children}
      </form>
    </Form>
  );
}
