import type { Row } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { type ReactNode } from "react";

interface DataTableRowActionsProps<TData> {
  children?: ReactNode;
  row: Row<TData>;
  options?: {
    label: string | ReactNode;
    icon?: ReactNode;
    onClick?: (row: Row<TData>) => void;
  }[];
}

export function DataTableRowActions<TData>({
  row,
  options,
  children,
}: DataTableRowActionsProps<TData>) {
  if (!options?.length) {
    console.error(
      `You are trying to render a DataTableRowActions component without any options.`
    );
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {children || (
          <Button
            variant="secondary"
            size="icon"
            className="flex h-8 w-8 data-[state=open]:bg-muted"
          >
            <DotsVerticalIcon />
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        {options?.map((option, index) => (
          <DropdownMenuItem
            key={index}
            onClick={() => option.onClick?.(row)}
            className="flex items-center space-x-2"
          >
            {option.icon && option.icon}
            <span>{option.label}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
