import InvoiceManagerDialog from "@/modals/invoice/invoice-state-manager.tsx";
import EditVendorStatusConfirmationDialog from "@/modals/vendors/edit-vendor-status.tsx";
import ViewReceivedProductInvoice from "@/modals/invoice/view-received-product-invoice.tsx";
import ProductItemCreate from "@/modals/invoice/product-item-create.tsx";
import CancelSentProductInvoice from "@/modals/invoice/cancel-sent-product-invoice.tsx";
import ViewSentProductInvoice from "@/modals/invoice/view-sent-product-invoice.tsx";
import ViewSentServiceInvoice from "@/modals/invoice/view-sent-service-invoice.tsx";
import CancelSentServiceInvoice from "@/modals/invoice/cancel-sent-service-invoice.tsx";
import ConfirmationModal from "@/modals/confirmation.tsx";
import ChangeTenancyModal from "@/modals/user/change-tenancy.tsx";
import { createPushModal } from "@/modals/factory.tsx";

export const {
  pushModal,
  popModal,
  popAllModals,
  replaceWithModal,
  useOnPushModal,
  onPushModal,
  ModalProvider,
} = createPushModal({
  modals: {
    InvoiceManagerDialog,
    EditVendorStatusConfirmationDialog,
    ViewReceivedProductInvoice,
    ProductItemCreate,
    CancelSentProductInvoice,
    CancelSentServiceInvoice,
    ViewSentProductInvoice,
    ViewSentServiceInvoice,
    ConfirmationModal,
    ChangeTenancyModal,
  },
});
