/**
1 – Contribuinte ICMS (informar a IE do destinatário);
2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS;
9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.
 */
export enum RecipientStateInscriptionType {
  CONTRIBUINTE_ICMS = 1,
  CONTRIBUINTE_ISENTO = 2,
  NAO_CONTRIBUINTE = 9,
}
