import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form.tsx";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ProcessedNfseFile } from "@/pages/invoices/import/components/nfse/processed-nfse-file.tsx";
import { ArrowBigDownDash, Loader2 } from "lucide-react";
import { toast } from "sonner";
import { csvFileParser } from "@/lib/csv-parser.ts";
import { onlyNumbers } from "@shared/format.ts";
import { useMutation } from "@tanstack/react-query";
import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import { useEffect, useState } from "react";
import { parse } from "date-fns";

const schema = z.object({
  csvFile: z
    .custom<File | undefined>()
    .refine((value) => value && value.name.endsWith(".csv"), {
      message: "Por favor, adicione um arquivo no format CSV válido.",
    }),
});

type FileUpload = z.infer<typeof schema>;

const transformCurrency = (input: string) => {
  const trimmedInput = input.trim();

  if (/^\d+(\.\d+)?$/.test(trimmedInput)) {
    return trimmedInput;
  }

  const withoutThousandSeparators = trimmedInput.replace(/\./g, "");
  return withoutThousandSeparators.replace(/,/g, ".");
};

const csvSchema = z
  .object({
    data_emissao: z
      .string()
      .min(1, "Data de emissão é obrigatória")
      .transform((value) =>
        parse(value, "dd/MM/yyyy", new Date()).toISOString()
      ),
    cnpj_emissor: z
      .string()
      .min(1, "CNPJ emissor é obrigatório")
      .transform(onlyNumbers)
      .refine((value) => value.length === 14, {
        message: "CNPJ emissor inválido",
      }),
    cpf_cnpj: z
      .string()
      .min(1, "CPF/CNPJ é obrigatório")
      .transform(onlyNumbers)
      .refine((value) => [11, 14].includes(value.length), {
        message: "CPF/CNPJ deve ter 11 ou 14 dígitos respectivamente",
      }),
    inscricao_estadual: z
      .string()
      .min(1, "Inscrição estadual é obrigatório")
      .transform(onlyNumbers),
    inscricao_municipal: z
      .string()
      .min(1, "Inscrição municipal é obrigatório")
      .transform(onlyNumbers),
    razao_social: z.string().min(1, "Razão social é obrigatória"),
    logradouro: z.string().min(1, "Logradouro é obrigatório"),
    numero: z.string().min(1, "Número é obrigatório"),
    complemento: z.string(),
    bairro: z.string().min(1, "Bairro é obrigatório"),
    cep: z.string().min(1, "CEP é obrigatório"),
    codigo_municipio: z.string().min(1, "Código do município é obrigatório"),
    municipio: z.string().min(1, "Município é obrigatório"),
    uf: z.string().min(1, "UF é obrigatório"),
    telefone: z.string(),
    email: z.string(),
    item_lista_servico: z
      .string()
      .min(1, "Item lista de serviço é obrigatório"),
    codigo_cnae: z.string().optional(),
    codigo_tributario_municipio: z.string().optional(),
    discriminacao: z.string().min(1, "Discriminação é obrigatória"),
    valor_servicos: z.string().min(1, "Valor dos serviços é obrigatório"),
    aliquota: z.string().min(1, "Alíquota é obrigatório"),
    iss_retido: z.string().min(1, "Retenção ISS é obrigatória (sim/não)"),
    valor_inss: z.string().transform(transformCurrency),
    valor_pis: z.string().transform(transformCurrency),
    valor_cofins: z.string().transform(transformCurrency),
    valor_csll: z.string().transform(transformCurrency),
    valor_ir: z.string().transform(transformCurrency),
    valor_deducoes: z.string().transform(transformCurrency),
  })
  .transform((data) => ({
    issuedAt: data.data_emissao,
    issuer: {
      document: data.cnpj_emissor,
      operationNature: "1",
    },
    recipient: {
      document: data.cpf_cnpj,
      documentType: (data.cpf_cnpj.length === 14 ? "CNPJ" : "CPF") as
        | "CPF"
        | "CNPJ",
      companyName: data.razao_social,
      email: data.email,
      address: {
        street: data.logradouro,
        number: data.numero,
        complement: data.complemento,
        district: data.bairro,
        zipCode: data.cep,
        state: data.uf,
        city: data.municipio,
        cityCode: data.codigo_municipio,
      },
    },
    items: [
      {
        description: data.discriminacao,
        item: data.item_lista_servico,
        issRetained: data.iss_retido === "sim",
        municipalTaxCode: data.codigo_tributario_municipio,
        aliquot: Number(data.aliquota),
        value: Number(data.valor_servicos),
      },
    ],
    totals: {
      pis: data.valor_pis ?? "0",
      cofins: data.valor_cofins ?? "0",
      inss: data.valor_inss ?? "0",
      ir: data.valor_ir ?? "0",
      csll: data.valor_csll ?? "0",
      deductions: data.valor_deducoes ?? "0",
    },
  }));

const transformCsvData = (data: any) => {
  return {
    issuedAt: data.data_emissao ?? "",
    issuer: {
      document: data.cnpj_emissor ?? "",
      operationNature: "1",
    },
    recipient: {
      document: data.cpf_cnpj ?? "",
      documentType: (data.cpf_cnpj.length === 14 ? "CNPJ" : "CPF") as
        | "CPF"
        | "CNPJ",
      companyName: data.razao_social ?? "",
      email: data.email ?? "",
      address: {
        street: data.logradouro ?? "",
        number: data.numero ?? "",
        complement: data.complemento ?? "",
        district: data.bairro ?? "",
        zipCode: data.cep ?? "",
        state: data.uf ?? "",
        city: data.municipio ?? "",
        cityCode: data.codigo_municipio ?? "",
      },
    },
    items: [
      {
        description: data.discriminacao ?? "",
        item: data.item_lista_servico ?? "",
        issRetained: data?.iss_retido === "sim",
        municipalTaxCode: data.codigo_tributario_municipio ?? "",
        aliquot: Number(data.aliquota ?? "0"),
        value: Number(data.valor_servicos ?? "0"),
      },
    ],
    totals: {
      pis: data.valor_pis ?? "0",
      cofins: data.valor_cofins ?? "0",
      inss: data.valor_inss ?? "0",
      ir: data.valor_ir ?? "0",
      csll: data.valor_csll ?? "0",
      deductions: data.valor_deducoes ?? "0",
    },
  };
};

export function ImportServiceDocument() {
  const [processingTime, setProcessingTime] = useState(0);

  useEffect(() => {
    if (processingTime > 0) {
      setTimeout(() => {
        setProcessingTime(processingTime - 1000);
      }, 1000);
    }
  }, [processingTime, setProcessingTime]);

  const { mutate, data, isPending } = useMutation({
    mutationKey: ["load-csv"],
    mutationFn: async (
      file: File
    ): Promise<{
      fileName: string;
      data: {
        status: "success" | "error";
        invoice: CreateServiceInvoice;
        errors?: string[];
      }[];
    }> => {
      const parsedCsvFile = await csvFileParser(file, {
        header: true,
      });

      console.log(parsedCsvFile);

      const processingTime = 240 * parsedCsvFile.data.length;
      setProcessingTime(processingTime);
      await new Promise((resolve) => setTimeout(resolve, processingTime));

      const result = parsedCsvFile.data.map((invoice) => {
        const parsedResult = csvSchema.safeParse(invoice);

        return {
          invoice: parsedResult.data ?? transformCsvData(invoice),
          status: (parsedResult.success ? "success" : "error") as
            | "success"
            | "error",
          errors:
            parsedResult.error?.issues.map((issue) => issue.message) ?? [],
        };
      });

      return { fileName: file.name, data: result };
    },
    onError: () => {
      toast.error(
        "Ocorreu um erro ao carregar o arquivo CSV. Por favor, verifique todos os dados informados e tente novamente."
      );
    },
  });

  const form = useForm<FileUpload>({
    resolver: zodResolver(schema),
  });

  const submit = async ({ csvFile }: FileUpload) => {
    if (!csvFile) {
      toast.error("Por favor, selecione um arquivo CSV válido.");
      return;
    }

    mutate(csvFile);
  };

  const requestNfseTemplate = () => {
    alert("TODO: request nfse template");
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Importar arquivo CSV para emissão de NFSes</CardTitle>
          <CardDescription>
            Importe um arquivo CSV com os dados para realizar a emissão em lote.
            O modelo do arquivo pode ser encontrado{" "}
            <a href={"#"} onClick={requestNfseTemplate} className={"underline"}>
              aqui.
            </a>
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-3">
              <div className={"flex w-full space-x-2"}>
                <FormField
                  control={form.control}
                  name="csvFile"
                  render={({ field }) => (
                    <FormItem className={"w-full"}>
                      <FormControl>
                        <Input
                          type="file"
                          accept=".csv"
                          // disabled={true}
                          multiple={false}
                          onChange={async (e) => {
                            const file = e.target.files?.[0];
                            field.onChange(file ? file : undefined);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  disabled={!form.watch("csvFile") || isPending}
                >
                  {isPending && (
                    <Loader2 className={"animate-spin size-4 mr-2"} />
                  )}
                  Enviar
                </Button>
              </div>
            </form>
          </Form>
          {isPending && (
            <div className={"mt-2"}>
              <ArrowBigDownDash
                className={"inline-block animate-bounce mr-2"}
              />
              <span className={"font-medium text-sm tracking-wide"}>
                Estamos processando o arquivo.{" "}
                {processingTime > 0 &&
                  `Tempo estimado: ${Math.floor(processingTime / 1000)}s`}
              </span>
            </div>
          )}
        </CardContent>
      </Card>
      {!!data && (
        <ProcessedNfseFile data={data.data} fileName={data.fileName} />
      )}
    </>
  );
}
