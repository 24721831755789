import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegimeTributary } from "@shared/schemas/company.ts";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { InfoIcon } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { useNfeStore } from "@/pages/invoices/product/store/useNfeStore.ts";
import { toast } from "sonner";
import { useCompanies } from "@/hooks/use-companies.ts";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import {
  type CreateProductInvoice,
  productInvoiceSchema,
} from "@shared/schemas/invoices/product.ts";

type NfeIssuerType = CreateProductInvoice["issuer"];

const companySelectSchema = z.object({
  company: z.string(),
});

export function NfeIssuer() {
  const { data } = useCompanies();

  const { mutateAsync } = useMutation({
    mutationKey: ["/companies"],
    mutationFn: async (data: z.infer<typeof companySelectSchema>) => {
      const res = await fetchApi<{ data: CompanyWithAddress }>(
        `/companies/${data.company}`,
        {
          method: "GET",
        }
      );
      return res.data.data;
    },
  });

  const { issuer, setIssuer } = useNfeStore((state) => ({
    issuer: state.nfeData.issuer,
    setIssuer: state.setIssuerSchema,
  }));

  const form = useForm<NfeIssuerType>({
    resolver: zodResolver(productInvoiceSchema.shape.issuer),
    defaultValues: {
      ...issuer,
    },
  });

  const companySelectForm = useForm<z.infer<typeof companySelectSchema>>({
    resolver: zodResolver(companySelectSchema),
    defaultValues: {
      company: "",
    },
  });

  const submit = form.handleSubmit((data) => {
    setIssuer(data);
    toast.success("As informações do emitente foram salvas com sucesso!");
  });

  const updateFields = async (company: string) => {
    const companyDocument = data?.companies.find((c) => c.document === company);
    if (!companyDocument) {
      toast.error(
        "Houve um erro ao carregar os dados do emitente, por favor, tente novamente."
      );
      return;
    }

    const companyData = await mutateAsync({
      company: companyDocument.document,
    });

    form.setValue("name", companyData.name);
    form.setValue("fantasyName", companyData.fantasyName);
    form.setValue("document", companyData.document);
    form.setValue("stateRegistration", companyData.stateRegistration ?? "");
    form.setValue(
      "tributaryRegime",
      companyData?.tributaryRegime ?? RegimeTributary.REGIME_NORMAL
    );
    form.setValue("address.street", companyData.address?.street ?? "");
    form.setValue("address.number", companyData.address?.number ?? "");
    form.setValue("address.district", companyData.address?.district ?? "");
    form.setValue("address.city", companyData.address?.city ?? "");
    form.setValue("address.state", companyData.address?.state ?? "");
    form.setValue("address.zipCode", companyData.address?.zipCode ?? "");

    toast.success("As informações do emitente foram carregadas com sucesso!");
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Dados do Emitente</CardTitle>
          <CardDescription>
            Selecione a empresa emitente da nota fiscal
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          {!!data && (
            <Form {...companySelectForm}>
              <form>
                <FormField
                  name="company"
                  render={({ field }) => (
                    <Select
                      onValueChange={async (value) => {
                        field.onChange(value);
                        await updateFields(value);
                      }}
                      defaultValue={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione o CNPJ" />
                      </SelectTrigger>
                      <SelectContent>
                        {data.companies.map((company) => (
                          <SelectItem
                            key={company.document}
                            value={company.document}
                          >
                            {company.document} - {company.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </form>
            </Form>
          )}
          <Separator />
          <Form {...form}>
            <form onSubmit={submit}>
              <div className="grid grid-cols-6 gap-3">
                <FormField
                  name="name"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormLabel>Razão Social</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="fantasyName"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormLabel>Nome Fantasia</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  name="documentType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="space-x-2 inline-flex items-center">
                        <span>Tipo do Documento</span>
                        <InfoIcon size={14} />
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <div className="inline-flex space-x-3">
                            <FormItem className="flex items-center space-x-2">
                              <FormControl>
                                <RadioGroupItem value="CNPJ" />
                              </FormControl>
                              <FormLabel className="font-normal">
                                CNPJ
                              </FormLabel>
                            </FormItem>
                            <FormItem className="flex items-center space-x-2">
                              <FormControl>
                                <RadioGroupItem value="CPF" />
                              </FormControl>
                              <FormLabel className="font-normal">CPF</FormLabel>
                            </FormItem>
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="document"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="space-x-2 inline-flex items-center">
                        <span>Documento</span>
                        <InfoIcon size={14} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="stateRegistration"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inscrição Estadual</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="tributaryRegime"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Regime Tributário</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) =>
                            field.onChange(Number.parseInt(value))
                          }
                          defaultValue={field.value.toString()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Selecione o regime" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem
                              value={RegimeTributary.SIMPLES_NACIONAL.toString()}
                            >
                              Simples Nacional
                            </SelectItem>
                            <SelectItem
                              value={RegimeTributary.REGIME_NORMAL.toString()}
                            >
                              Regime Normal
                            </SelectItem>
                            <SelectItem
                              value={RegimeTributary.SIMPLES_NACIONAL_EXCESSO.toString()}
                            >
                              Simples Nacional Excesso
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.street"
                  render={({ field }) => (
                    <FormItem className="col-span-5">
                      <FormLabel>Logradouro</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.zipCode"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <FormLabel>CEP</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.number"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <FormLabel>Número</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.district"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Bairro</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.city"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Município</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.state"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <FormLabel>UF</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-end mt-6">
                <Button variant="outline">Salvar</Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
