import { z } from "zod";
import {
  NfeDocumentPaymentType,
  NfeOperationType,
  NfeDocumentDestination,
  NfeDocumentType,
} from "../shared/general";
import { RecipientStateInscriptionType } from "./recipient";
import { IcmsOrigem, PISCOFINSTaxes, ICMSTaxes } from "../shared/items";
import { RegimeTributary } from "../../../company";

export enum FocusShippingType {
  EMITENTE = 0,
  DESTINATARIO = 1,
  TERCEIROS = 2,
  SEM_FRETE = 9,
}

const issuerSchema = z.object({
  nome_emitente: z.string(),
  nome_fantasia_emitente: z.string().nullish(),
  cnpj_emitente: z.string().optional(),
  cpf_emitente: z.string().optional(),
  inscricao_estadual_emitente: z.string(),
  logradouro_emitente: z.string(),
  cep_emitente: z.string(),
  numero_emitente: z.string(),
  bairro_emitente: z.string(),
  municipio_emitente: z.string(),
  uf_emitente: z.string(),
  regime_tributario_emitente: z.nativeEnum(RegimeTributary),
});

const recipientSchema = z
  .object({
    nome_destinatario: z.string().describe("Nome completo do destinatário"),
    telefone_destinatario: z.string().describe("Telefone do destinatário"),
    cnpj_destinatario: z
      .string()
      .optional()
      .describe(
        "CNPJ da empresa destinatária. Caso utilize este campo, não enviar o campo “cpf_destinatario”"
      )
      .nullable(),
    cpf_destinatario: z
      .string()
      .optional()
      .describe(
        "CPF do destinatário. Caso utilize este campo, não enviar o campo “cnpf_destinatario”"
      )
      .nullable(),
    inscricao_estadual_destinatario: z
      .string()
      .describe("Informar a Inscrição Estadual do destinatário"),
    logradouro_destinatario: z
      .string()
      .describe("Logradouro do destinatário")
      .min(1, "O logradouro é obrigatório"),
    numero_destinatario: z
      .string()
      .describe("Número do logradouro do destinatário")
      .default("S/N"),
    bairro_destinatario: z.string().describe("Bairro do destinatário"),
    municipio_destinatario: z.string().describe("Município do destinatário"),
    uf_destinatario: z.string().describe("UF do destinatário"),
    pais_destinatario: z.string().describe("País do destinatário"),
    cep_destinatario: z.string().describe("CEP do destinatário"),
    indicador_inscricao_estadual_destinatario: z
      .nativeEnum(RecipientStateInscriptionType)
      .optional()
      .describe("Indicador da Inscrição Estadual do destinatário"),
  })
  .partial();

const nfeItemSchema = z.object({
  numero_item: z.string(),
  codigo_produto: z.string(),
  descricao: z.string(),
  cfop: z.string(),
  quantidade_comercial: z.number(),
  quantidade_tributavel: z.number(),
  valor_unitario_comercial: z.number(),
  valor_unitario_tributavel: z.number(),
  unidade_comercial: z.string().optional(),
  unidade_tributavel: z.string(),
  valor_bruto: z.string().optional(),
  codigo_ncm: z.string(),
  inclui_no_total: z.boolean().optional(),
  icms_origem: z.nativeEnum(IcmsOrigem).optional(),
  icms_situacao_tributaria: z.nativeEnum(ICMSTaxes).optional(),
  pis_situacao_tributaria: z.nativeEnum(PISCOFINSTaxes).optional(),
  cofins_situacao_tributaria: z.nativeEnum(PISCOFINSTaxes).optional(),
});

const focusNfeSchema = z
  .object({
    natureza_operacao: z.string(),
    data_emissao: z.string(),
    tipo_documento: z.nativeEnum(NfeDocumentType),
    local_destino: z.nativeEnum(NfeDocumentDestination),
    finalidade_emissao: z
      .nativeEnum(NfeOperationType)
      .describe("Finalidade da emissão do documento"),
    consumidor_final: z.boolean().describe("Destinatário do documento"),
    presenca_comprador: z
      .nativeEnum(NfeDocumentPaymentType)
      .describe("Presença do comprador"),
    itens: z.array(nfeItemSchema),
    icms_base_calculo: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total da base de cálculo do ICMS"),
    icms_valor_total: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total do ICMS"),
    icms_base_calculo_st: z
      .number()
      .default(0)
      .optional()
      .describe(
        "Valor total da base de cálculo do ICMS do substituto tributário"
      ),
    icms_valor_total_st: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total do ICMS do substituto tributário"),
    valor_produtos: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total dos produtos"),
    valor_frete: z
      .number()
      .optional()
      .default(0)
      .describe("Valor total do frete"),
    valor_seguro: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total do seguro"),
    valor_desconto: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total do desconto"),
    ipi_valor: z
      .number()
      .default(0)
      .optional()
      .describe("Valor total do IPI, assume zero se não informado"),
    pis_valor: z
      .number()
      .default(0)
      .optional()
      .describe("Valor do PIS, assume zero se não informado"),
    cofins_valor: z
      .number()
      .default(0)
      .optional()
      .describe("Valor do COFINS, assume zero se não informado"),
    valor_outras_despesas: z
      .number()
      .default(0)
      .optional()
      .describe("Valor das despesas acessórias"),
    valor_total: z.number().optional(),
    modalidade_frete: z.nativeEnum(FocusShippingType),
  })
  .merge(issuerSchema)
  .merge(recipientSchema);

export * from "./recipient";

export { focusNfeSchema };
