import { z } from "zod";
import { IcmsOrigem, ICMSTaxes, PISCOFINSTaxes } from "./nfe/shared/items";
import { RegimeTributary } from "../company";
import {
  NfeDocumentDestination,
  NfeDocumentPaymentType,
  NfeDocumentType,
  NfeOperationType,
} from "./nfe/shared/general";
import { RecipientStateInscriptionType } from "./nfe/focus";

const invoiceSchema = z.object({
  operationNature: z
    .string()
    .min(1, "É necessário informar a natureza da operação."),
  documentType: z.nativeEnum(NfeDocumentType).describe("Tipo de documento"),
  paymentType: z.nativeEnum(NfeDocumentPaymentType),
  destination: z.nativeEnum(NfeDocumentDestination),
  issuedAt: z.string(),
  issueReason: z.nativeEnum(NfeOperationType).default(NfeOperationType.NORMAL),
  endConsumer: z.boolean(),
});

const companySchema = z.object({
  name: z.string().min(1, "Razão Social é obrigatório"),
  document: z.string().min(1, "CNPJ/CPF é obrigatório").max(14),
  documentType: z.enum(["CPF", "CNPJ"] as const),
  fantasyName: z.string().nullish(),
  address: z.object({
    street: z.string().min(1, "Logradouro é obrigatório"),
    number: z.string().min(1, "Número é obrigatório"),
    district: z.string().min(1, "Bairro é obrigatório"),
    city: z.string().min(1, "Cidade é obrigatória"),
    state: z.string().min(1, "Estado é obrigatório"),
    zipCode: z.string().min(1, "CEP é obrigatório"),
    country: z.string().nullish().default("Brasil"),
  }),
  stateRegistration: z.string().min(1, "Inscrição estadual é obrigatória"),
});

const issuerSchema = companySchema.extend({
  tributaryRegime: z.nativeEnum(RegimeTributary, {
    required_error: "Regime tributário é obrigatório",
  }),
});

const recipientSchema = companySchema.extend({
  stateRegistrationType: z.nativeEnum(RecipientStateInscriptionType, {
    required_error: "Tipo de inscrição estadual é obrigatório",
  }),
});

const valuesSchema = z.object({
  freight: z.string().default("0"),
  freightType: z
    .nativeEnum({
      ISSUER: 0,
      RECEIVER: 1,
      THIRD_PARTY: 2,
      NO_FREIGHT: 9,
    })
    .default(9),
  insurance: z.string().default("0"),
  total: z.string().default("0"),
  totalProducts: z.string().default("0"),
  discount: z.string().default("0"),
});

export const itemSchema = z.object({
  itemCode: z
    .string({
      required_error: "Código do item é obrigatório",
    })
    .min(1, "Código do item é obrigatório"),
  productCode: z
    .string({
      required_error: "Código do produto é obrigatório",
    })
    .min(1, "Código do produto é obrigatório"),
  description: z
    .string({
      required_error: "Descrição do produto é obrigatória",
    })
    .min(1, "Descrição do produto é obrigatória"),
  cfop: z
    .string({
      required_error: "CFOP é obrigatório",
    })
    .min(4, "CFOP é obrigatório")
    .max(4, "CFOP inválido"),
  commercialQuantity: z
    .union([
      z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")),
      z.number(),
    ])
    .pipe(
      z.coerce
        .number()
        .min(0.0001, "É necessário especificar uma quantidade")
        .max(999999999, "Quantidade acima do permitido")
    ),
  commercialUnitValue: z
    .union([
      z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")),
      z.number(),
    ])
    .pipe(
      z.coerce
        .number()
        .min(0.0001, "É necessário especificar um valor")
        .max(999999999, "Valor acima do permitido")
    ),
  commercialUnit: z
    .string({
      required_error: "Unidade comercial é obrigatória",
    })
    .min(1, "Unidade comercial é obrigatória"),

  taxableValue: z.number().optional(),
  taxableQuantity: z
    .union([
      z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")),
      z.number(),
    ])
    .pipe(
      z.coerce
        .number()
        .min(0.0001, "É necessário especificar uma quantidade")
        .max(999999999, "Quantidade acima do permitido")
    ),
  taxableUnitValue: z
    .union([
      z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")),
      z.number(),
    ])
    .pipe(
      z.coerce
        .number()
        .min(0.0001, "É necessário especificar um valor")
        .max(999999999, "Valor acima do permitido")
    ),
  taxableUnit: z
    .string({
      required_error: "Unidade tributável é obrigatória",
    })
    .min(1, "Unidade tributável é obrigatória"),

  grossValue: z.number().optional(),

  ncmCode: z
    .string({
      required_error: "Código NCM é obrigatório",
    })
    .min(8, "Código NCM é obrigatório")
    .max(8, "Código NCM inválido"),

  includeInTotal: z.boolean().optional(),

  icmsOrigin: z.nativeEnum(IcmsOrigem, {
    required_error: "Origem da ICMS é obrigatória",
  }),
  icmsTaxSituation: z.nativeEnum(ICMSTaxes, {
    required_error: "Situação da ICMS é obrigatória",
  }),
  pisTaxSituation: z.nativeEnum(PISCOFINSTaxes, {
    required_error: "Situação da PIS é obrigatória",
  }),
  cofinsTaxSituation: z.nativeEnum(PISCOFINSTaxes, {
    required_error: "Situação da COFINS é obrigatória",
  }),
});

export const productInvoiceSchema = z.object({
  invoice: invoiceSchema,
  issuer: issuerSchema,
  recipient: recipientSchema,
  values: valuesSchema,
  items: z.array(itemSchema),
});

export type ProductInvoiceItem = z.infer<typeof itemSchema>;
export type ProductInvoiceTotals = z.infer<typeof valuesSchema>;
export type ProductInvoiceStatus =
  | "pending"
  | "authorized"
  | "cancelled"
  | "denied"
  | "error"
  | "processing";

export type ProductInvoiceManifest =
  | "pending"
  | "acknowledged"
  | "authorized"
  | "unauthorized"
  | "discarded";

export type CreateProductInvoice = z.infer<typeof productInvoiceSchema>;
