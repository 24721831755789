import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DatePicker } from "@/components/ui/date-picker.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { IssuerForm } from "@/pages/invoices/service/components/issuer-form.tsx";
import { RecipientForm } from "@/pages/invoices/service/components/recipient-form.tsx";
import { fetchApi } from "@/lib/api.ts";
import { ServicesForm } from "@/pages/invoices/service/components/services-form.tsx";
import { useNavigate } from "react-router-dom";

type CreateNfse = z.infer<typeof serviceInvoiceSchema>;

export function CreateServiceInvoice() {
  const form = useForm<CreateNfse>({
    resolver: zodResolver(serviceInvoiceSchema),
    defaultValues: {
      issuedAt: new Date().toISOString(),
      issuer: {
        document: "",
        operationNature: "1",
      },
      recipient: {
        companyName: "",
        email: "",
        document: "",
        documentType: "CNPJ",
        address: {
          zipCode: "",
          street: "",
          number: "",
          complement: "",
          district: "",
          state: "",
          cityCode: "",
        },
      },
      items: [
        {
          description: "",
          item: "",
          issRetained: false,
          municipalTaxCode: "",
          aliquot: 0,
          value: 0,
        },
      ],
    },
  });

  console.log(form.formState.errors);

  const { data, isError } = useQuery<{
    companies: CompanyWithAddress[];
  }>({
    queryKey: ["/companies"],
  });

  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationKey: [`/invoices/sent/services`],
    mutationFn: (nfse: CreateNfse) => {
      return fetchApi(`/invoices/sent/services`, {
        method: "POST",
        data: nfse,
      });
    },
    onSuccess: () => {
      navigate("/invoices/sent");
    },
  });

  if (isError) {
    return (
      <div>
        Erro ao sincronizar as empresas que o seu usuário possui acesso. Contate
        o suporte.
      </div>
    );
  }

  const submit = form.handleSubmit((data) => {
    mutate(data);
  });

  return (
    <>
      <div className="space-y-6 px-10 pt-5 pb-16">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight ">
            Emitir Nota Fiscal de Serviços Eletrônica
          </h2>
          <p className="text-muted-foreground">
            Preencha os campos abaixo para emitir uma nota fiscal de serviços
            eletrônica.
          </p>
          <Separator />
        </div>

        <Form {...form}>
          <form onSubmit={submit} className="space-y-3 mt-8">
            <Card>
              <CardHeader>
                <CardTitle>Competência da nota fiscal</CardTitle>
                <CardContent>
                  <FormField
                    name="issuedAt"
                    render={({ field }) => (
                      <FormItem className="max-w-fit mt-4">
                        <FormLabel>Data</FormLabel>
                        <FormControl>
                          <DatePicker
                            setDate={(date) =>
                              field.onChange(date?.toISOString())
                            }
                            date={new Date(field.value)}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </CardContent>
              </CardHeader>
            </Card>
            <IssuerForm form={form} companies={data?.companies} />
            <RecipientForm form={form} />
            <ServicesForm form={form} companies={data?.companies} />
            <div className="flex justify-end">
              <Button disabled={isPending} type="submit">
                Salvar e Emitir
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
}
