import { useEffect } from "react";

export function useTitle(title: string) {
  useEffect(() => {
    document.title = `Twokei Docs - ${title}`;
  }, [title]);

  return {
    title,
    setTitle: (title: string) => {
      document.title = `Twokei Docs - ${title}`;
    },
  };
}
