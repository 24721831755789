import { Link } from "react-router-dom";
import { MainNav } from "@/components/main-nav.tsx";
import { UserNav } from "@/components/user-nav.tsx";
import { NotificationNav } from "@/components/notification-nav.tsx";

export function DesktopNavbar() {
  return (
    <header className="container border-b hidden md:block">
      <div className="flex h-16 items-center">
        <Link to={"/"}>
          <picture>
            <source
              srcSet="/logo/twokei_black.png"
              media="(prefers-color-scheme: dark)"
            />
            <img
              src="/logo/twokei_white.png"
              alt="Twokei Logo"
              className="h-auto w-32"
            />
          </picture>
        </Link>
        <MainNav />
        <div className="ml-auto flex items-center space-x-4">
          <NotificationNav />
          <UserNav />
        </div>
      </div>
    </header>
  );
}
