import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { cn } from "@/lib/utils.ts";
import { Input } from "@/components/ui/input.tsx";
import type { ComponentPropsWithoutRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateCompanyStore } from "@/pages/companies/create/store/useCreateCompanyStore.ts";
import type { z } from "zod";
import { addressSchema } from "@shared/schemas/common.ts";

export function CreateCompanyAddress({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<"form">) {
  const { setAddress, nextStep, address } = useCreateCompanyStore((state) => ({
    address: state.address,
    setAddress: state.setAddress,
    nextStep: state.nextStep,
    basicInfo: state.basicInfo,
  }));

  const form = useForm<z.infer<typeof addressSchema>>({
    resolver: zodResolver(addressSchema),
    defaultValues: {
      ...address,
    },
  });

  const submit = async (values: z.infer<typeof addressSchema>) => {
    console.log(values);
    setAddress(values);
    nextStep();
  };

  return (
    <Form {...form}>
      <form
        className={cn("grid gap-6", className)}
        {...props}
        onSubmit={form.handleSubmit(submit)}
      >
        <FormField
          name="street"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Endereço</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Avenida Paulista" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-3 gap-x-4">
          <FormField
            name="number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Número</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="1234" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="complement"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Complemento</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Sala 1 Andar 13" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-4 gap-x-4">
          <FormField
            name="district"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Bairro</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Centro" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="zipCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>CEP</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="12345-678" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Cidade</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="São Paulo" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Estado</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="SP" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {children}
      </form>
    </Form>
  );
}
