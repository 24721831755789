import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  companyWithAddressSchema,
  RegimeTributary,
} from "@shared/schemas/company.ts";
import { useParams } from "react-router-dom";
import { useCompany } from "@/hooks/use-company.ts";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { formatCNPJ } from "@shared/format.ts";
import type { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";

export function FormBasicInfo() {
  const { document } = useParams();
  const { data, isError } = useCompany(document);
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof companyWithAddressSchema>>({
    resolver: zodResolver(companyWithAddressSchema),
    defaultValues: data,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [`companies/${document}`],
    mutationFn: (data: z.infer<typeof companyWithAddressSchema>) =>
      fetchApi(`/companies/${document}`, {
        method: "PATCH",
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}`],
      });
      toast.success("Dados atualizados com sucesso!");
    },
  });

  if (isError || !data) {
    return (
      <div className="flex justify-center items-center h-64">
        <p>Erro ao carregar os dados</p>
      </div>
    );
  }

  return (
    <>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((val) => mutate(val))}>
            <p className="font-semibold text-lg">Dados Cadastrais</p>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-y-5 gap-x-3">
              <FormField
                name="name"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Razão Social</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        autoCorrect="off"
                        autoComplete="off"
                        disabled={true}
                      />
                    </FormControl>
                    <FormDescription>
                      Por motivos de segurança, você não consegue alterar este
                      campo.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="document"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>CNPJ</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        autoCorrect="off"
                        autoComplete="off"
                        disabled={true}
                        value={formatCNPJ(field.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="stateRegistration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Inscrição Estadual</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="municipalRegistration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Inscrição Municipal</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="tributaryRegime"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Regime Tributário</FormLabel>
                    <Select
                      onValueChange={(value) =>
                        field.onChange(Number.parseInt(value))
                      }
                      value={field.value.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Selecione o regime" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem
                          value={RegimeTributary.SIMPLES_NACIONAL.toString()}
                        >
                          Simples Nacional
                        </SelectItem>
                        <SelectItem
                          value={RegimeTributary.SIMPLES_NACIONAL_EXCESSO.toString()}
                        >
                          Simples Nac. Excedente
                        </SelectItem>
                        <SelectItem
                          value={RegimeTributary.REGIME_NORMAL.toString()}
                        >
                          Regime Normal
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="fantasyName"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Nome Fantasia</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <p className="tracking-wide text-lg mt-8 font-semibold">
              Informações de Contato
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-y-5 gap-x-3">
              <FormField
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>E-mail</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Número Telefone</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <p className="tracking-wide text-lg mt-8 font-semibold">Endereço</p>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-y-5 gap-x-3">
              <FormField
                name="address.street"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Logradouro</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Número</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.complement"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Complemento</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.district"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Bairro</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.zipCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>CEP</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Cidade</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Estado</FormLabel>
                    <FormControl>
                      <Input {...field} autoCorrect="off" autoComplete="off" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end mt-8">
              <Button disabled={isPending}>Salvar</Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
}
