import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card.tsx";
import type { ComponentPropsWithoutRef } from "react";
import { formatCurrency } from "@/lib/currency.ts";
import type { ProductInvoiceItem } from "@shared/schemas/invoices/product.ts";

export function ProductHoverCard({
  data,
  children,
  ...props
}: {
  data: ProductInvoiceItem;
} & ComponentPropsWithoutRef<typeof HoverCardContent>) {
  return (
    <HoverCard openDelay={100} closeDelay={100}>
      <HoverCardTrigger asChild>{children}</HoverCardTrigger>
      <HoverCardContent
        {...props}
        side={"right"}
        align={"center"}
        className="w-[300px] max-h-[700px] overflow-y-auto"
      >
        <p className={"text-sm font-semibold"}>{data.description}</p>
        <p className={"text-xs"}>CFOP: {data.cfop}</p>
        <p className={"text-xs"}>Unidade Comercial: {data.commercialUnit}</p>
        <p className={"text-xs"}>
          Quantidade Comercial: {data.commercialQuantity}
        </p>
        <p className={"text-xs"}>
          Preço Unitário Comercial:{" "}
          {formatCurrency(data.commercialUnitValue, "BRL")}
        </p>
        <p className={"text-xs"}>
          Valor Bruto Comercial: {formatCurrency(data.grossValue || 0, "BRL")}
        </p>
      </HoverCardContent>
    </HoverCard>
  );
}
