import { useQuery } from "@tanstack/react-query";
import { onlyNumbers } from "@shared/format.ts";
import type { RegimeTributary } from "@shared/schemas/company.ts";

export interface ExternalCompany {
  name: string;
  fantasyName: string;
  document: string;
  status: string;
  cnae: string;
  regime: RegimeTributary;
  address: {
    cityCode: string;
    cityName: string;
    street: string;
    complement: string;
    number: string;
    district: string;
    zipCode: string;
    state: string;
  };
}

export function useExternalCompany(document: string) {
  const safeDocument = onlyNumbers(document);

  return useQuery<ExternalCompany>({
    queryKey: [`/external/company/${safeDocument}`],
    enabled: !!document && safeDocument.length > 10,
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  });
}
