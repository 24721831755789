import { Separator } from "@/components/ui/separator.tsx";
import { EditVendor } from "@/pages/vendors/[document]/components/edit-vendor.tsx";

export function VendorEdit() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Dados do fornecedor</h3>
        <p className="text-sm text-muted-foreground">
          Abaixo você pode editar as informações de cadastro do seu fornecedor
        </p>
      </div>
      <Separator />
      <EditVendor />
    </div>
  );
}
