export enum IncludeOnTotal {
  YES = 0,
  NO = 1,
}

export enum IcmsOrigem {
  NACIONAL = 0,
  ESTRANGEIRA_IMPORTACAO_DIRETA = 1,
  ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO = 2,
  NACIONAL_MAIS_40_CONTEUDO_ESTRANGEIRO = 3,
  NACIONAL_PROCESSOS_PRODUTIVOS_BASICOS = 4,
  NACIONAL_MENOS_40_CONTEUDO_ESTRANGEIRO = 5,
  ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_PRODUTO_NACIONAL_SIMILAR = 6,
  ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO_SEM_PRODUTO_NACIONAL_SIMILAR = 7,
}

export const IcmsOrigemTranslated = {
  [IcmsOrigem.NACIONAL]: "Nacional",
  [IcmsOrigem.ESTRANGEIRA_IMPORTACAO_DIRETA]: "Estrangeira (importação direta)",
  [IcmsOrigem.ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO]:
    "Estrangeira (adquirida no mercado interno)",
  [IcmsOrigem.NACIONAL_MAIS_40_CONTEUDO_ESTRANGEIRO]:
    "Nacional com mais de 40% de conteúdo estrangeiro",
  [IcmsOrigem.NACIONAL_PROCESSOS_PRODUTIVOS_BASICOS]:
    "Nacional produzida através de processos produtivos básicos",
  [IcmsOrigem.NACIONAL_MENOS_40_CONTEUDO_ESTRANGEIRO]:
    "Nacional com menos de 40% de conteúdo estrangeiro",
  [IcmsOrigem.ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_PRODUTO_NACIONAL_SIMILAR]:
    "Estrangeira (importação direta) sem produto nacional similar",
  [IcmsOrigem.ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO_SEM_PRODUTO_NACIONAL_SIMILAR]:
    "Estrangeira (adquirida no mercado interno) sem produto nacional similar",
};

/**
Informar qual a situação do ICMS para a operação.
00 – Tributada integralmente

10 – Tributada e com cobrança do ICMS por substituição tributária

20 – Tributada com redução de base de cálculo

30 – Isenta ou não tributada e com cobrança do ICMS por substituição tributária

40 – Isenta

41 – Não tributada

50 – Suspensão

51 – Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)

60 – Cobrado anteriormente por substituição tributária

70 – Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária

 90 – Outras (regime Normal)

101 – Ttributada pelo Simples Nacional com permissão de crédito

102 – Tributada pelo Simples Nacional sem permissão de crédito

103 – Isenção do ICMS no Simples Nacional para faixa de receita bruta

201 – Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária

202 – Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária

203 – Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária

300 – Imune

400 – Não tributada pelo Simples Nacional

500 – ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação

900 – Outras (regime Simples Nacional)

*/
export enum ICMSTaxes {
  TRIBUTADA_INTEGRALMENTE = 0,
  TRIBUTADA_COM_COBRANCA_SUBSTITUICAO_TRIBUTARIA = 10,
  TRIBUTADA_COM_REDUCAO_BASE_CALCULO = 20,
  ISENTA_NAO_TRIBUTADA_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA = 30,
  ISENTA = 40,
  NAO_TRIBUTADA = 41,
  SUSPENSAO = 50,
  DIFERIMENTO = 51,
  COBRADO_ANTERIORMENTE_SUBSTITUICAO_TRIBUTARIA = 60,
  TRIBUTADA_COM_REDUCAO_BASE_CALCULO_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA = 70,
  OUTRAS_REGIME_NORMAL = 90,
  TRIBUTADA_SIMPLES_NACIONAL_PERMISSAO_CREDITO = 101,
  TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_CREDITO = 102,
  ISENCAO_ICMS_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA = 103,
  TRIBUTADA_SIMPLES_NACIONAL_PERMISSAO_CREDITO_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA = 201,
  TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_CREDITO_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA = 202,
  ISENCAO_ICMS_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA = 203,
  IMUNE = 300,
  NAO_TRIBUTADA_SIMPLES_NACIONAL = 400,
  ICMS_COBRADO_ANTERIORMENTE_SUBSTITUICAO_TRIBUTARIA_SUBSTITUIDO_ANTICIPACAO = 500,
  OUTRAS_REGIME_SIMPLES_NACIONAL = 900,
}

export const ICMSTaxesTranslated = {
  [ICMSTaxes.TRIBUTADA_INTEGRALMENTE]: "Tributada integralmente",
  [ICMSTaxes.TRIBUTADA_COM_COBRANCA_SUBSTITUICAO_TRIBUTARIA]:
    "Tributada com cobrança do ICMS por substituição tributária",
  [ICMSTaxes.TRIBUTADA_COM_REDUCAO_BASE_CALCULO]:
    "Tributada com redução de base de cálculo",
  [ICMSTaxes.ISENTA_NAO_TRIBUTADA_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA]:
    "Isente ou não tributada e com cobrança do ICMS por substituição tributária",
  [ICMSTaxes.ISENTA]: "Isenta",
  [ICMSTaxes.NAO_TRIBUTADA]: "Não tributada",
  [ICMSTaxes.SUSPENSAO]: "Suspensão",
  [ICMSTaxes.DIFERIMENTO]:
    "Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)",
  [ICMSTaxes.COBRADO_ANTERIORMENTE_SUBSTITUICAO_TRIBUTARIA]:
    "Cobrado anteriormente por substituição tributária",
  [ICMSTaxes.TRIBUTADA_COM_REDUCAO_BASE_CALCULO_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA]:
    "Tributada com redução de base de cálculo cobrança do ICMS por substituição tributária",
  [ICMSTaxes.OUTRAS_REGIME_NORMAL]: "Outras (regime Normal)",
  [ICMSTaxes.TRIBUTADA_SIMPLES_NACIONAL_PERMISSAO_CREDITO]:
    "Tributada pelo Simples Nacional com permissão de crédito",
  [ICMSTaxes.TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_CREDITO]:
    "Tributada pelo Simples Nacional sem permissão de crédito",
  [ICMSTaxes.ISENCAO_ICMS_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA]:
    "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  [ICMSTaxes.TRIBUTADA_SIMPLES_NACIONAL_PERMISSAO_CREDITO_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA]:
    "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  [ICMSTaxes.TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_CREDITO_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA]:
    "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  [ICMSTaxes.ISENCAO_ICMS_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA]:
    "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
  [ICMSTaxes.IMUNE]: "Imune",
  [ICMSTaxes.NAO_TRIBUTADA_SIMPLES_NACIONAL]:
    "Não tributada pelo Simples Nacional",
  [ICMSTaxes.ICMS_COBRADO_ANTERIORMENTE_SUBSTITUICAO_TRIBUTARIA_SUBSTITUIDO_ANTICIPACAO]:
    "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  [ICMSTaxes.OUTRAS_REGIME_SIMPLES_NACIONAL]:
    "Outras (regime Simples Nacional)",
};

/**
Informar qual a situação do PIS para a operação.
01 – Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo)

02 – Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)

03 – Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto

04 – Operação tributável: tributação monofásica (alíquota zero)

05 – Operação tributável: substituição tributária

06 – Operação tributável: alíquota zero

07 – Operação isenta da contribuição

08 – Operação sem incidência da contribuição

09 – Operação com suspensão da contribuição

49 – Outras operações de saída

50 – Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno

51 – Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno

52 – Operação com direito a crédito: vinculada exclusivamente a receita de exportação

53 – Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno

54 – Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação

55 – Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exprtação

56 – Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação

60 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno

61 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno

62 – Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação

63 – Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno

64 – Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação

65 – Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação

66 – Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação

67 – Crédito presumido: outras operações

70 – Operação de aquisição sem direito a crédito

71 – Operação de aquisição com isenção

72 – Operação de aquisição com suspensão

73 – Operação de aquisição a alíquota zero

74 – Operação de aquisição sem incidência da contribuição

75 – Operação de aquisição por substituição tributária

98 – Outras operações de entrada

99 – Outras operações
*/
export enum PISCOFINSTaxes {
  OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_NORMAL_CUMULATIVO_NAO_CUMULATIVO = 1,
  OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_DIFERENCIADA = 2,
  OPERACAO_TRIBUTAVEL_BASE_CALCULO_QUANTIDADE_VENDIDA_ALIQUOTA_UNIDADE_PRODUTO = 3,
  OPERACAO_TRIBUTAVEL_TRIBUTACAO_MONOFASICA_ALIQUOTA_ZERO = 4,
  OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA = 5,
  OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO = 6,
  OPERACAO_ISENTA_CONTRIBUICAO = 7,
  OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO = 8,
  OPERACAO_SUSPENSAO_CONTRIBUICAO = 9,
  OUTRAS_OPERACOES_SAIDA = 49,
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO = 50,
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO = 51,
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO = 52,
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO = 53,
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO = 54,
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO = 55,
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO = 56,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO = 60,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO = 61,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO = 62,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO = 63,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO = 64,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO = 65,
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO = 66,
  CREDITO_PRESUMIDO_OUTRAS_OPERACOES = 67,
  OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO = 70,
  OPERACAO_AQUISICAO_COM_ISENCAO = 71,
  OPERACAO_AQUISICAO_COM_SUSPENSAO = 72,
  OPERACAO_AQUISICAO_ALIQUOTA_ZERO = 73,
  OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO = 74,
  OPERACAO_AQUISICAO_SUBSTITUICAO_TRIBUTARIA = 75,
  OUTRAS_OPERACOES_ENTRADA = 98,
  OUTRAS_OPERACOES = 99,
}

export const PISCOFINSTaxesTranslated = {
  [PISCOFINSTaxes.OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_NORMAL_CUMULATIVO_NAO_CUMULATIVO]:
    "Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo)",
  [PISCOFINSTaxes.OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_DIFERENCIADA]:
    "Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)",
  [PISCOFINSTaxes.OPERACAO_TRIBUTAVEL_BASE_CALCULO_QUANTIDADE_VENDIDA_ALIQUOTA_UNIDADE_PRODUTO]:
    "Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto",
  [PISCOFINSTaxes.OPERACAO_TRIBUTAVEL_TRIBUTACAO_MONOFASICA_ALIQUOTA_ZERO]:
    "Operação tributável: tributação monofásica (alíquota zero)",
  [PISCOFINSTaxes.OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA]:
    "Operação tributável: substituição tributária",
  [PISCOFINSTaxes.OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO]:
    "Operação tributável: alíquota zero",
  [PISCOFINSTaxes.OPERACAO_ISENTA_CONTRIBUICAO]:
    "Operação isenta da contribuição",
  [PISCOFINSTaxes.OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO]:
    "Operação sem incidência da contribuição",
  [PISCOFINSTaxes.OPERACAO_SUSPENSAO_CONTRIBUICAO]:
    "Operação com suspensão da contribuição",
  [PISCOFINSTaxes.OUTRAS_OPERACOES_SAIDA]: "Outras operações de saída",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO]:
    "Operação direito crédito vinculada exclusivamente a receita tributada no mercado interno",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO]:
    "Operação direito crédito vinculada exclusivamente a receita não tributada no mercado interno",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO]:
    "Operação direito crédito vinculada exclusivamente a receita exportada",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO]:
    "Operação direito crédito vinculada a receitas tributadas não-tributadas no mercado interno",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO]:
    "Operação direito crédito vinculada a receitas tributadas no mercado interno e exportadas",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO]:
    "Operação direito crédito vinculada a receitas não-tributadas no mercado interno e exportadas",
  [PISCOFINSTaxes.OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO]:
    "Operação direito crédito vinculada a receitas tributadas não-tributadas no mercado interno e exportadas",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO]:
    "Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO]:
    "Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO]:
    "Crédito presumido: operação de aquisição vinculada exclusivamente a receita exportada",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO]:
    "Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO]:
    "Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e exportadas",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO]:
    "Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e exportadas",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO]:
    "Crédito presumido: operação de aquisição vinculada a receitas tributadas não-tributadas no mercado interno e exportadas",
  [PISCOFINSTaxes.CREDITO_PRESUMIDO_OUTRAS_OPERACOES]:
    "Crédito presumido: outras operações",
  [PISCOFINSTaxes.OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO]:
    "Operação de aquisição sem direito crédito",
  [PISCOFINSTaxes.OPERACAO_AQUISICAO_COM_ISENCAO]:
    "Operação de aquisição com isenção",
  [PISCOFINSTaxes.OPERACAO_AQUISICAO_COM_SUSPENSAO]:
    "Operação de aquisição com suspensão",
  [PISCOFINSTaxes.OPERACAO_AQUISICAO_ALIQUOTA_ZERO]:
    "Operação de aquisição com alíquota zero",
  [PISCOFINSTaxes.OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO]:
    "Operação de aquisição sem incidência da contribuição",
  [PISCOFINSTaxes.OPERACAO_AQUISICAO_SUBSTITUICAO_TRIBUTARIA]:
    "Operação de aquisição com substituição tributária",
  [PISCOFINSTaxes.OUTRAS_OPERACOES_ENTRADA]: "Outras operações de entrada",
  [PISCOFINSTaxes.OUTRAS_OPERACOES]: "Outras operações",
};
