import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { popModal } from "@/modals";
import { Separator } from "@/components/ui/separator.tsx";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";

interface InvoiceStateManager {
  id: string;
  state: string;
}

const formSchema = z.object({
  reason: z.string(),
});

type FormProps = z.infer<typeof formSchema>;

export default function InvoiceManagerDialog(data: InvoiceStateManager) {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationKey: [`/invoices/${data.id}/approval`],
    mutationFn: ({ status, reason }: { status: string; reason: string }) => {
      return fetchApi(`/invoices/${data.id}/approval`, {
        method: "POST",
        data: {
          status,
          reason,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [`/invoices/received/product/${data.id}`],
      });

      popModal("InvoiceManagerDialog");
    },
  });

  const form = useForm<FormProps>({
    resolver: zodResolver(
      data.state === "approved"
        ? formSchema.extend({ reason: z.string().optional() })
        : formSchema.extend({ reason: z.string().min(1, "Campo obrigatório") })
    ),
    defaultValues: {
      reason: "",
    },
  });

  const submit = form.handleSubmit(async ({ reason }) => {
    mutate({ status: data.state, reason });
  });

  return (
    <>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <DialogHeader className="text-lg font-medium">
          <DialogTitle>Confirmar Ação</DialogTitle>
        </DialogHeader>
        <Separator />
        Você deseja {data.state == "approved" ? "aprovar" : "rejeitar"} o
        documento fiscal?
        <Form {...form}>
          <form onSubmit={submit}>
            <FormField
              name="reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">
                    {data.state == "approved"
                      ? "Observações adicionais"
                      : "Motivo da recusa"}
                  </FormLabel>
                  <Textarea {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-4">
              <Button
                type="button"
                variant="ghost"
                disabled={isPending}
                onClick={() => popModal("InvoiceManagerDialog")}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                disabled={isPending}
                variant={data.state == "approved" ? "default" : "destructive"}
              >
                {data.state == "approved" ? "Aprovar" : "Rejeitar"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </>
  );
}
