import type { ColumnDef, SortingFn } from "@tanstack/react-table";

import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";

import { Badge } from "@/components/ui/badge.tsx";
import {
  AlarmCheckIcon,
  CheckCheck,
  File,
  SquarePen,
  UploadCloud,
  X,
} from "lucide-react";
import { formatCNPJ, onlyNumbers } from "@shared/format.ts";
import { format } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { DataTableRowActions } from "@/components/table/data-table-row-actions.tsx";
import { pushModal } from "@/modals";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card.tsx";

const invoiceStatuses = [
  {
    id: "draft",
    label: "Rascunho",
    icon: SquarePen,
  },
  {
    id: "processing",
    label: "Processando",
    icon: UploadCloud,
  },
  {
    id: "pending",
    label: "Enviada",
    icon: AlarmCheckIcon,
  },
  {
    id: "success",
    label: "Concluída",
    icon: CheckCheck,
  },
  {
    id: "cancelled",
    label: "Cancelada",
    icon: X,
  },
  {
    id: "error",
    label: "Erro",
    icon: X,
  },
];

const invoiceInternalStatuses = [
  {
    id: "approved",
    label: "Aprovado",
    variant: "default",
  },
  {
    id: "rejected",
    label: "Rejeitado",
    variant: "destructive",
  },
  {
    id: null,
    label: "Em análise",
    variant: "outline",
  },
] as const;

const receivedInvoiceStatuses = [
  {
    id: "authorized",
    label: "Autorizada",
    icon: CheckCheck,
  },
  {
    id: "cancelled",
    label: "Cancelada",
    icon: X,
  },
  {
    id: "denied",
    label: "Denegada",
    icon: X,
  },
];

const statuses = [...invoiceStatuses, ...receivedInvoiceStatuses];

type ReceivedDataTable = {
  id: string;
  status: string;
  type: "product" | "service";
  issuer: {
    document: string;
    name?: string;
  };
  recipient: {
    document: string;
    name: string;
  };
  issuedAt: string;
  approvalStatus: string;
};

const sortIssuedAt: SortingFn<ReceivedDataTable> = (rowA, rowB, columnId) => {
  if (columnId === "issuedAt") {
    return (
      new Date(rowA.original.issuedAt).getTime() -
      new Date(rowB.original.issuedAt).getTime()
    );
  }

  return 0;
};

export const columns: ColumnDef<ReceivedDataTable>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "issuedAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data" column={column} />
    ),
    cell: ({ row }) => (
      <div className="w-fit">
        {format(row.getValue("issuedAt"), "dd/MM/yyyy HH:mm")}
      </div>
    ),
    enableHiding: false,
    sortingFn: sortIssuedAt,
  },
  {
    accessorKey: "issuer",
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id) as { document: string; name?: string };
      const inputValue = value.toLowerCase();

      const document = onlyNumbers(rowValue.document);
      const valueAsNumbers = onlyNumbers(inputValue);

      const matchesDocument =
        valueAsNumbers.length >= 1 && document.includes(valueAsNumbers);

      if (!rowValue.name) {
        return matchesDocument;
      }

      const matchesName = rowValue.name.toLowerCase().includes(inputValue);

      return matchesDocument || matchesName;
    },
    header: ({ column }) => (
      <DataTableColumnHeader title="Emissor" column={column} />
    ),
    cell: ({ row }) => {
      const issuer = row.getValue("issuer") as {
        document: string;
        name?: string;
      };

      const formattedIssuer = formatCNPJ(issuer.document);

      if (!issuer.name) {
        return <span className="overflow-hidden">{formattedIssuer}</span>;
      }

      return (
        <HoverCard openDelay={100}>
          <HoverCardTrigger>{issuer.name}</HoverCardTrigger>
          <HoverCardContent className="flex flex-col gap-2 w-80">
            <span>{issuer.name}</span>
            <span className="text-muted-foreground text-xs">
              CNPJ: {formattedIssuer}
            </span>
          </HoverCardContent>
        </HoverCard>
      );
    },
  },
  {
    accessorKey: "recipient",
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id) as { document: string; name?: string };
      const inputValue = value.toLowerCase();

      const document = onlyNumbers(rowValue.document);
      const valueAsNumbers = onlyNumbers(inputValue);

      const matchesDocument =
        valueAsNumbers.length >= 1 && document.includes(valueAsNumbers);

      if (!rowValue.name) {
        return matchesDocument;
      }

      const matchesName = rowValue.name.toLowerCase().includes(inputValue);

      return matchesDocument || matchesName;
    },
    header: ({ column }) => (
      <DataTableColumnHeader title="Tomador" column={column} />
    ),
    cell: ({ row }) => {
      const recipient = row.getValue("recipient") as {
        document: string;
        name: string;
      };

      const formattedRecipient = formatCNPJ(recipient.document);

      if (!recipient.name) {
        return <span>{formattedRecipient}</span>;
      }

      return (
        <HoverCard>
          <HoverCardTrigger>{recipient.name}</HoverCardTrigger>
          <HoverCardContent className="flex flex-col gap-2 w-80">
            <span>{recipient.name}</span>
            <span className="text-muted-foreground text-xs">
              CNPJ: {formattedRecipient}
            </span>
          </HoverCardContent>
        </HoverCard>
      );
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader title="Tipo" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <Badge variant="outline">
          {row.getValue("type") === "product" ? "Produto" : "Serviço"}
        </Badge>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "status",
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.id === row.getValue("status")
      );

      if (!status) {
        return (
          <div className="flex min-w-[50px] items-center">
            <span>{row.getValue("status")}</span>
          </div>
        );
      }

      return (
        <div className="flex min-w-[50px] items-center">
          {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: "status_internal",
    enableHiding: false,
    filterFn: (row, _id, value: string[]) => {
      const rowValue = row.original.approvalStatus || "pending";
      return value.includes(rowValue);
    },
    header: ({ column }) => (
      <DataTableColumnHeader title="Status da Aprovação" column={column} />
    ),
    cell: ({ row }) => {
      const internalStatus = invoiceInternalStatuses.find(
        (status) => row.original.approvalStatus === status.id
      );

      return (
        <Badge className="text-center" variant={internalStatus?.variant}>
          {internalStatus?.label}
        </Badge>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <DataTableRowActions
          row={row}
          options={[
            {
              label: "Ver detalhes",
              icon: <File size={17} />,
              onClick: (row) => {
                if (row.getValue("type") === "product") {
                  pushModal("ViewReceivedProductInvoice", {
                    id: row.original.id,
                  });
                }
              },
            },
          ]}
        />
      );
    },
  },
];
