import { Separator } from "@/components/ui/separator.tsx";
import { FormSettings } from "@/pages/companies/[document]/components/form-settings.tsx";

export function CompanySettingsEdit() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Configurações</h3>
        <p className="text-sm text-muted-foreground">
          Abaixo você pode editar as configurações da sua empresa no sistema
        </p>
      </div>
      <Separator />
      <FormSettings />
    </div>
  );
}
