import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { processedNfseColumns } from "@/pages/invoices/import/components/nfse/components/processed-data-table-columns.tsx";
import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";

interface ProcessedDataTableProps {
  data: {
    status: "success" | "error";
    invoice: CreateServiceInvoice;
    errors?: string[];
  }[];
}

export function ProcessedDataTable({ data }: ProcessedDataTableProps) {
  const table = useGenericTable({
    columns: processedNfseColumns,
    data: data,
  });

  if (!data) return null;

  return (
    <div className={"space-y-4"}>
      <ScrollArea className={"rounded-md border"}>
        <div className={"max-h-[300px]"}>
          <DataTable table={table} />
        </div>
        <ScrollBar orientation={"horizontal"} />
        <ScrollBar orientation={"vertical"} />
      </ScrollArea>
      <DataTablePagination table={table} />
    </div>
  );
}
