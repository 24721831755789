import Papa from "papaparse";

export function csvFileParser(file: File, options?: Papa.ParseConfig) {
  return new Promise<Papa.ParseResult<Record<string, string>>>((resolve) => {
    Papa.parse<any>(file, {
      encoding: "ISO-8859-1",
      skipEmptyLines: "greedy",
      ...options,
      complete(results) {
        resolve(results);
      },
    });
  });
}
