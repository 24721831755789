import { Input } from "@/components/ui/input.tsx";
import type { Column } from "@tanstack/react-table";
import { type ComponentPropsWithoutRef } from "react";

interface Props extends ComponentPropsWithoutRef<typeof Input> {
  column: Column<any>;
}

export function DataTableSearch({ column, ...rest }: Props) {
  return (
    <Input
      placeholder="Pesquisar..."
      value={(column.getFilterValue() as string) ?? ""}
      onChange={(event) => column.setFilterValue(event.target.value)}
      className="h-8 w-[150px] lg:w-[250px]"
      {...rest}
    />
  );
}
