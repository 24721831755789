import { useSessionContext } from "@/hooks/use-session-context";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export function BaseLayout() {
  const { isLoggedIn, isLoading } = useSessionContext();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!isLoggedIn && !location.pathname.startsWith("/auth")) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoggedIn && location.pathname.startsWith("/auth")) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
