import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { companySchema } from "@shared/schemas/company.ts";
import type { z } from "zod";
import { cn } from "@/lib/utils.ts";
import type { ComponentPropsWithoutRef } from "react";
import { useCreateCompanyStore } from "@/pages/companies/create/store/useCreateCompanyStore.ts";
import { Check, Loader2, X } from "lucide-react";
import { formatCNPJ, onlyNumbers } from "@shared/format.ts";
import type { ExternalCompany } from "@/hooks/use-external-company.ts";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";

const basicInfoSchema = companySchema.omit({
  tributaryRegime: true,
  stateRegistration: true,
  municipalRegistration: true,
});

export function CreateCompanyBasic({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) {
  const { setBasicInfo, basicInfo, nextStep, setAddress } =
    useCreateCompanyStore((state) => ({
      setBasicInfo: state.setBasicInfo,
      setAddress: state.setAddress,
      basicInfo: state.basicInfo,
      nextStep: state.nextStep,
    }));

  const form = useForm<z.infer<typeof basicInfoSchema>>({
    resolver: zodResolver(basicInfoSchema),
    defaultValues: basicInfo,
    mode: "onBlur",
  });

  const { mutate, status } = useMutation({
    mutationKey: [`/external/company`],
    mutationFn: async (data: { document: string }) =>
      fetchApi
        .get(`/external/company/${data.document}`)
        .then((res) => res.data.data as ExternalCompany),
    onSuccess: (externalCompany) => {
      toast.success("Dados da empresa foram carregados com sucesso.");

      form.setValue("name", externalCompany.name);
      form.setValue("fantasyName", externalCompany.fantasyName);

      setBasicInfo({
        ...basicInfo,
        name: externalCompany.name,
        fantasyName: externalCompany.fantasyName,
        tributaryRegime: externalCompany.regime,
      });

      setAddress({
        street: externalCompany.address.street,
        number: externalCompany.address.number,
        district: externalCompany.address.district,
        city: externalCompany.address.cityName,
        state: externalCompany.address.state,
        zipCode: externalCompany.address.zipCode,
        complement: externalCompany.address.complement,
        cityCode: externalCompany.address.cityCode,
      });
    },
    onError: () => {},
  });

  const submit = async (values: z.infer<typeof basicInfoSchema>) => {
    setBasicInfo({
      ...basicInfo,
      ...values,
    });
    nextStep();
  };

  const Icon = (() => {
    if (status === "pending") {
      return <Loader2 className="w-6 h-6 animate-spin" />;
    }

    if (status === "success") {
      return <Check />;
    }

    if (status === "error") {
      return <X />;
    }
  })();

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <div className={cn("grid gap-6", className)} {...props}>
          <FormField
            name="document"
            render={({ field: { value, onChange, ...rest } }) => (
              <FormItem>
                <FormLabel className="inline-flex gap-2 items-center">
                  CNPJ
                  {Icon}
                </FormLabel>
                <FormControl>
                  <Input
                    {...rest}
                    value={formatCNPJ(value)}
                    onChange={(e) => {
                      const companyDocument = onlyNumbers(e.target.value);
                      onChange(companyDocument);

                      if (companyDocument.length !== 14) {
                        return;
                      }

                      mutate({ document: companyDocument });
                    }}
                    placeholder="CNPJ"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nome da Empresa</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Nome da Empresa" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="fantasyName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nome Fantasia</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Nome Fantasia" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Telefone</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Telefone" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {children}
      </form>
    </Form>
  );
}
