import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  NfeDocumentDestination,
  NfeDocumentPaymentType,
  NfeDocumentType,
  NfeOperationType,
} from "@shared/schemas/invoices/nfe/shared/general.ts";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Button } from "@/components/ui/button.tsx";
import { DatePicker } from "@/components/ui/date-picker.tsx";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { productInvoiceSchema } from "@shared/schemas/invoices/product.ts";
import { useNfeStore } from "@/pages/invoices/product/store/useNfeStore.ts";
import { toast } from "sonner";

const basicSchema = productInvoiceSchema.shape.invoice.pick({
  issuedAt: true,
  documentType: true,
  issueReason: true,
  endConsumer: true,
  destination: true,
  paymentType: true,
  operationNature: true,
});

type BasicNfe = z.infer<typeof basicSchema>;

export function NfeInvoiceBasic() {
  const { invoice, setInvoice } = useNfeStore((state) => ({
    invoice: state.nfeData.invoice,
    setInvoice: state.setInvoiceSchema,
  }));

  const form = useForm<BasicNfe>({
    resolver: zodResolver(productInvoiceSchema.shape.invoice),
    defaultValues: {
      ...invoice,
    },
  });

  const submit = form.handleSubmit((data) => {
    setInvoice({
      ...data,
      issuedAt: data.issuedAt,
    });
    toast.success("As informações básicas foram salvas com sucesso");
  });

  return (
    <>
      <Form {...form}>
        <form onSubmit={submit}>
          <Card>
            <CardHeader>
              <CardTitle>Informações básicas</CardTitle>
              <CardDescription>
                Preencha as informações básicas para seguir com a emissão da
                nota fiscal
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-5">
              <div className="grid grid-cols-6 gap-3">
                {/* Campos restritos gerados automaticamente */}
                {/*<span>Modelo</span>*/} {/*<span>Série</span>*/}
                {/*<span>Número</span>*/}
                <FormField
                  name="issuedAt"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Data de emissão</FormLabel>
                      <FormControl>
                        <DatePicker
                          setDate={(date) =>
                            field.onChange(date?.toISOString())
                          }
                          date={new Date(field.value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="documentType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tipo do documento</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(Number.parseInt(value));
                          }}
                          defaultValue={field.value.toString()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Selecione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem
                              value={NfeDocumentType.NOTA_FISCAL_SAIDA.toString()}
                            >
                              NF de Saída
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentType.NOTA_FISCAL_ENTRADA.toString()}
                            >
                              NF de Entrada
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="issueReason"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Finalidade de emissão</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(Number.parseInt(value));
                          }}
                          defaultValue={field.value.toString()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Selecione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem
                              value={NfeOperationType.NORMAL.toString()}
                            >
                              Normal
                            </SelectItem>
                            <SelectItem
                              value={NfeOperationType.COMPLEMENTAR.toString()}
                            >
                              Complementar
                            </SelectItem>
                            <SelectItem
                              value={NfeOperationType.NOTA_DE_AJUSTE.toString()}
                            >
                              Ajuste
                            </SelectItem>
                            <SelectItem
                              value={NfeOperationType.DEVOLUCAO.toString()}
                            >
                              Devolução
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="endConsumer"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Consumidor final</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value === "true");
                          }}
                          defaultValue={field.value.toString()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Selecione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={true.toString()}>Sim</SelectItem>
                            <SelectItem value={false.toString()}>
                              Não
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="destination"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Destino da operação</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(Number.parseInt(value));
                          }}
                          defaultValue={field.value.toString()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Selecione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem
                              value={NfeDocumentDestination.OPERACAO_INTERNA.toString()}
                            >
                              Interna
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentDestination.OPERACAO_INTERESTADUAL.toString()}
                            >
                              Interestadual
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentDestination.OPERACAO_COM_EXTERIOR.toString()}
                            >
                              Exterior
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Presença do comprador</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(Number.parseInt(value));
                          }}
                          defaultValue={field.value.toString()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Selecione" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem
                              value={NfeDocumentPaymentType.NAO_SE_APLICA.toString()}
                            >
                              Não se aplica
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentPaymentType.OPERACAO_PRESENCIAL.toString()}
                            >
                              Presencial
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentPaymentType.OPERACAO_NAO_PRESENCIAL_OUTROS.toString()}
                            >
                              Não presencial
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentPaymentType.OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO.toString()}
                            >
                              Teleatendimento
                            </SelectItem>
                            <SelectItem
                              value={NfeDocumentPaymentType.OPERACAO_NAO_PRESENCIAL_INTERNET.toString()}
                            >
                              Internet
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="operationNature"
                  render={({ field }) => (
                    <FormItem className="col-span-6">
                      <FormLabel>Natureza da operação</FormLabel>
                      <FormControl>
                        <Input {...field} maxLength={60} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-end mt-4">
                <Button variant="outline">Salvar</Button>
              </div>
            </CardContent>
          </Card>
        </form>
      </Form>
    </>
  );
}
