import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";

const schema = z.object({
  csvFile: z.custom<File>().refine((value) => value.name.endsWith(".csv"), {
    message: "Por favor, adicione um arquivo CSV",
  }),
});

type Nfe = z.infer<typeof schema>;

export function ImportNfe() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["/invoices/nfe/import"],
    mutationFn: async (data: Nfe) => {
      const formData = new FormData();
      formData.append("file", data.csvFile);

      return fetchApi("/invoices/nfe/import", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
    },
  });

  const form = useForm<Nfe>({
    resolver: zodResolver(schema),
  });

  const submit = async (nfe: Nfe) => {
    console.log(nfe);
    await mutateAsync(nfe);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Importar arquivo CSV para emissão de NF-e</CardTitle>
          <CardDescription>
            Importe um arquivo CSV com os dados da NF-e para emissão em lote.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-3">
              <FormField
                control={form.control}
                name="csvFile"
                render={({ field: { onChange, value: _value, ...rest } }) => (
                  <FormItem>
                    <FormLabel>Escolha o arquivo</FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        {...rest}
                        accept=".csv"
                        onChange={async (e) => {
                          const file = e.target.files?.[0];

                          if (!file) {
                            onChange("");
                            return;
                          }

                          onChange(file);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" disabled={isPending}>
                Importar arquivo
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
