export enum NfeDocumentType {
  NOTA_FISCAL_ENTRADA = 0,
  NOTA_FISCAL_SAIDA = 1,
}

export enum NfeDocumentDestination {
  OPERACAO_INTERNA = 1,
  OPERACAO_INTERESTADUAL = 2,
  OPERACAO_COM_EXTERIOR = 3,
}

export enum NfeOperationType {
  NORMAL = 1,
  COMPLEMENTAR = 2,
  NOTA_DE_AJUSTE = 3,
  DEVOLUCAO = 4,
}

export enum NfeDocumentPaymentType {
  NAO_SE_APLICA = 0,
  OPERACAO_PRESENCIAL = 1,
  OPERACAO_NAO_PRESENCIAL_INTERNET = 2,
  OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO = 3,
  NFC_E_COM_ENTREGA_EM_DOMICILIO = 4,
  OPERACAO_NAO_PRESENCIAL_OUTROS = 9,
}

export const getNfeDestination = (destination: number) => {
  switch (destination) {
    case NfeDocumentDestination.OPERACAO_INTERNA:
      return "Operação Interna";
    case NfeDocumentDestination.OPERACAO_INTERESTADUAL:
      return "Operação Interestadual";
    case NfeDocumentDestination.OPERACAO_COM_EXTERIOR:
      return "Operação com Exterior";
    default:
      return "N/A";
  }
};
