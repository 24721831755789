import { z } from "zod";
import { onlyNumbers } from "../format";
import { addressSchema } from "./common";

export enum RegimeTributary {
  SIMPLES_NACIONAL = 1,
  SIMPLES_NACIONAL_EXCESSO = 2,
  REGIME_NORMAL = 3,
}

export const getTributaryRegimeName = (regime?: number | null) => {
  switch (regime) {
    case RegimeTributary.SIMPLES_NACIONAL:
      return "Simples Nacional";
    case RegimeTributary.SIMPLES_NACIONAL_EXCESSO:
      return "Simples Nacional - Excesso de Sublimite de Receita Bruta";
    case RegimeTributary.REGIME_NORMAL:
      return "Regime Normal";
    default:
      return "Não informado";
  }
};

export const companySchema = z.object({
  name: z
    .string({
      required_error: "O nome da empresa é obrigatório",
    })
    .min(1, "O nome da empresa é obrigatório"),
  fantasyName: z
    .string({
      required_error: "O nome fantasia é obrigatório",
    })
    .min(1, "O nome fantasia é obrigatório"),
  email: z
    .string({
      required_error: "O email é obrigatório",
    })
    .email("Email inválido"),
  phone: z
    .string({
      required_error: "O telefone é obrigatório",
    })
    .regex(/^\d{10,11}$/, "Telefone inválido"),
  document: z
    .string({
      required_error: "O CNPJ é obrigatório",
    })
    .refine((value) => {
      return onlyNumbers(value).length === 14;
    }, "CNPJ inválido"),
  tributaryRegime: z.nativeEnum(RegimeTributary).nullish(),
  municipalRegistration: z.string().nullish(),
  stateRegistration: z.string().nullish(),
  services: z.array(z.string()).default([]),
});

export const companyWithAddressSchema = companySchema.extend({
  address: addressSchema,
});

export const companiesSchema = companySchema
  .pick({
    document: true,
    name: true,
  })
  .extend({
    id: z.string(),
  });

export type CompanyWithAddress = z.infer<typeof companyWithAddressSchema>;
export type Companies = z.infer<typeof companiesSchema>;
