import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  AlertTriangleIcon,
  CheckCheck,
  FileCode,
  FileJson,
  FileText,
  X,
} from "lucide-react";
import { ComponentWithTooltip } from "@/components/component-with-tooltip.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { ParagraphInfo } from "@/components/paragraph-info.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { ProductHoverCard } from "@/modals/invoice/components/product-hover-card.tsx";
import type {
  SelectProductInvoice,
  SelectProductInvoiceDetails,
} from "@shared/types/invoices/product.ts";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useQuery } from "@tanstack/react-query";
import { pushModal } from "@/modals";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { formatCurrency } from "@/lib/currency.ts";
import { getNfeDestination } from "@shared/schemas/invoices/nfe/shared/general.ts";

interface Props {
  id: string;
}

type ApprovalType = {
  userId: string;
  name: string;
  status: string;
  reason: string;
  updatedAt: string;
  createdAt: string;
};

const MAX_PRODUCT_ROWS = 8;
const BASE_HEIGHT = 150;
const ROW_HEIGHT = 30;
const LARGE_TABLE_HEIGHT = 500;

const calculateTableHeight = (productCount: number): number => {
  return productCount > MAX_PRODUCT_ROWS
    ? LARGE_TABLE_HEIGHT
    : productCount * ROW_HEIGHT + BASE_HEIGHT;
};

export default function ViewReceivedProductInvoice({ id }: Props) {
  const { data, isLoading } = useQuery<
    SelectProductInvoice & {
      issuedAt: string;
      details: SelectProductInvoiceDetails | null;
      externalId: string;
      approval: ApprovalType;
      vendor: {
        name: string;
        document: string;
      };
      company: {
        name: string;
        document: string;
      };
    }
  >({
    queryKey: [`/invoices/received/product/${id}`],
  });

  if (!data || isLoading) {
    return null;
  }

  const issuedAt = data.issuedAt
    ? new Date(data.issuedAt).toLocaleDateString()
    : "";
  const invoiceType = data.invoiceType === "sale" ? "SAÍDA" : "ENTRADA";

  const openApprovalModal = (state: "approved" | "rejected") => {
    pushModal("InvoiceManagerDialog", {
      id,
      state,
    });
  };

  return (
    <>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className={"max-w-5xl max-h-[95vh] overflow-y-auto"}
      >
        <DialogHeader className={"font-medium text-lg"}>
          <DialogTitle>Visualizando Nota Fiscal recebida (NF-e)</DialogTitle>
          <DialogDescription>
            <p className={"text-xs"}>Chave de acesso: {data.externalId}</p>
            <div className={"flex justify-between items-center"}>
              <p className={"text-xs"}>Data de emissão: {issuedAt}</p>
              {!!data.details && (
                <>
                  <p className={"text-xs"}>
                    Tipo: {invoiceType}, Número da Nota:{" "}
                    {data.details.invoiceNumber}, Série:{" "}
                    {data.details.invoiceSeries}
                  </p>
                </>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className={"space-y-4"}>
          {!!data.approval && (
            <>
              <Alert
                className={`${data.approval.status === "approved" ? "bg-green-800/20 border-green-800/40" : "bg-red-800/20 border-red-800/40"}`}
              >
                <AlertTriangleIcon
                  color={`${data.approval.status === "approved" ? "green" : "red"}`}
                />
                <AlertTitle
                  className={`${data.approval.status === "approved" ? "text-green-800" : "text-red-800"}`}
                >
                  {data.approval.status === "approved"
                    ? `A nota foi aprovada por ${data.approval.name}`
                    : `A nota foi recusada por ${data.approval.name}`}
                </AlertTitle>
                {!!data.approval.reason && (
                  <AlertDescription
                    className={`${data.approval.status === "approved" ? "text-green-800" : "text-red-800"}`}
                  >
                    {data.approval.reason}
                  </AlertDescription>
                )}
              </Alert>
            </>
          )}

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Informações
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                <div>
                  <p className={"font-semibold"}>Emitente</p>
                  <ParagraphInfo name={"CNPJ"} value={data.vendor.document} />
                  <ParagraphInfo
                    name={"Razão Social"}
                    value={data.vendor.name}
                  />
                </div>
                <div>
                  <p className={"font-semibold"}>Destinatário</p>
                  <ParagraphInfo name={"CNPJ"} value={data.company.document} />
                  <ParagraphInfo
                    name={"Razão Social"}
                    value={data.company.name}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          {!!data.details && (
            <Card>
              <CardContent>
                <div className={"my-3 text-lg font-medium tracking-wide"}>
                  Detalhes
                </div>
                <div>
                  <ParagraphInfo
                    name={"Natureza da Operação"}
                    value={data.details?.operationNature}
                  />
                  <ParagraphInfo
                    name={"Destino da Operação"}
                    value={
                      data.details?.endConsumer
                        ? "Consumidor final"
                        : "Terceiro"
                    }
                  />
                  <ParagraphInfo
                    name={"Local do destino"}
                    value={getNfeDestination(data.details?.destination)}
                  />
                </div>
              </CardContent>
            </Card>
          )}

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Produtos e Valores
              </div>
              <div>
                {/*//TODO: Tratar notas canceladas*/}
                {!data.details && (
                  <div className={"text-lg"}>
                    <p>
                      Estamos processando os dados da NF-e, em breve você será
                      capaz de visualizar os detalhes da NF-e.
                    </p>
                  </div>
                )}
                {!!data.details && (
                  <div className={"grid grid-cols-2"}>
                    <ParagraphInfo
                      name={"Valor total da nota fiscal"}
                      value={formatCurrency(data.details.totals.total, "BRL")}
                    />
                    <ParagraphInfo
                      name={"Frete"}
                      value={formatCurrency(data.details.totals.freight, "BRL")}
                    />
                    <ParagraphInfo
                      name={"Desconto"}
                      value={formatCurrency(
                        data.details.totals.discount,
                        "BRL"
                      )}
                    />
                    <ParagraphInfo
                      name={"Seguro"}
                      value={formatCurrency(
                        data.details.totals.insurance,
                        "BRL"
                      )}
                    />
                  </div>
                )}
                {!!data.details && (
                  <ScrollArea
                    style={{
                      height: calculateTableHeight(data.details.items.length),
                    }}
                  >
                    <Table>
                      <TableHeader className={"bg-background z-50"}>
                        <TableRow>
                          <TableHead className={"w-[700px]"}>
                            Descrição
                          </TableHead>
                          <TableHead className={"text-right"}>
                            Valor Total
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {data.details.items.map((product, index) => (
                          <TableRow
                            key={`${index}_${product.productCode}`}
                            className={"odd:bg-muted/20"}
                          >
                            <TableCell>
                              <ProductHoverCard
                                align={"start"}
                                side={"top"}
                                sideOffset={15}
                                data={product}
                              >
                                <span>{product.description}</span>
                              </ProductHoverCard>
                            </TableCell>
                            <TableCell className={"text-right"}>
                              {formatCurrency(product?.grossValue || 0, "BRL")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollArea>
                )}
              </div>
            </CardContent>
          </Card>

          <div className={"flex items-center justify-between"}>
            <div className={"flex gap-2"}>
              <ComponentWithTooltip
                tooltip={"Baixar como PDF (DANFE)"}
                align={"start"}
              >
                <Button size={"icon"} variant={"outline"}>
                  <FileText />
                </Button>
              </ComponentWithTooltip>

              <ComponentWithTooltip
                tooltip={"Baixar como JSON"}
                align={"start"}
              >
                <Button size={"icon"} variant={"outline"}>
                  <FileJson />
                </Button>
              </ComponentWithTooltip>

              <ComponentWithTooltip
                tooltip={"Baixar como XML"}
                align={"start"}
                side={"right"}
              >
                <Button size={"icon"} variant={"outline"}>
                  <FileCode />
                </Button>
              </ComponentWithTooltip>
            </div>
            <div className={"flex gap-2"}>
              <ComponentWithTooltip
                align={"center"}
                side={"left"}
                tooltip={
                  "A recusa da nota irá gerar um manifesto de Desconhecimento da Operação. Será necessário justificar."
                }
              >
                <Button
                  className={"bg-destructive hover:bg-destructive/90"}
                  onClick={() => openApprovalModal("rejected")}
                >
                  <X className={"size-4 mr-2"} />
                  Recusar
                </Button>
              </ComponentWithTooltip>
              <Button
                className={"bg-primary hover:bg-primary/90"}
                onClick={() => openApprovalModal("approved")}
              >
                <CheckCheck className={"size-4 mr-2"} />
                Aceitar
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
}
