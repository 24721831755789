import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { InfoIcon, Loader2, PlusCircle, User, X } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Popover, PopoverContent } from "@/components/ui/popover.tsx";
import { cn, groupBy } from "@/lib/utils.ts";
import { useMemo, useState } from "react";
import { Autocomplete } from "@/components/autocomplete.tsx";
import { PopoverAnchor } from "@radix-ui/react-popover";
import { useUser } from "@/hooks/use-user.ts";
import { Button } from "@/components/ui/button.tsx";
import { useTenantUsers } from "@/hooks/use-tenant-users.ts";
import { toast } from "sonner";
import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import { ProcessedDataTable } from "@/pages/invoices/import/components/nfse/components/processed-data-table.tsx";
import { ComponentWithTooltip } from "@/components/component-with-tooltip.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";

interface NfseDataTableProps {
  fileName: string;
  data: {
    status: "success" | "error";
    invoice: CreateServiceInvoice;
    errors?: string[];
  }[];
}

const batch = z.object({
  name: z.string().min(1, "O nome do lote é obrigatório"),
  owners: z.array(z.string()).min(1, "É obrigatório pelo menos um responsável"),
});

type Batch = z.infer<typeof batch>;
type DataStatus = "success" | "error";

export function ProcessedNfseFile({ fileName, data }: NfseDataTableProps) {
  const { data: userData } = useUser();
  const { data: tenantData } = useTenantUsers();
  const navigate = useNavigate();

  const { mutate: createBatch, isPending } = useMutation({
    mutationFn: async ({
      batch,
      invoices,
    }: {
      batch: Batch;
      invoices: CreateServiceInvoice[];
    }) =>
      fetchApi.post("/invoices/sent/services/batch", {
        name: batch.name,
        owners: batch.owners,
        invoices,
      }),
    onSuccess: () => {
      toast.success(
        "Lote criado com sucesso. As informações serão processadas, você pode acompanhar o status do lote na página de lotes.",
        {
          duration: 10000,
        }
      );
      navigate("/invoices/batches");
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 400) {
          toast.error(
            `Ocorreu um erro ao criar o lote. Verifique se as empresas emissoras estão cadastradas no sistema.`
          );
        } else if (status === 500) {
          toast.error(
            "Ocorreu um erro interno ao criar o lote. Por favor, tente novamente."
          );
        }

        return;
      }

      toast.error(
        "Ocorreu um erro ao criar o lote. Por favor, tente novamente."
      );
    },
  });

  const [popoverOwners, setPopoverOwners] = useState(false);

  const form = useForm<Batch>({
    resolver: zodResolver(batch),
    defaultValues: {
      owners: [userData?.id ?? ""],
      name: fileName,
    },
  });

  const formOwners = form.watch("owners");

  const owners = useMemo(() => {
    return formOwners.map((id) => {
      return {
        name: tenantData?.users.find((r) => r.id === id)?.name ?? "",
        id,
      };
    });
  }, [formOwners, tenantData]);

  const total = data.length;

  const { success = [], error = [] } = groupBy(
    data,
    (row) => row.status as DataStatus
  );

  const submit = async (batch: Batch) => {
    if (error.length > 0) {
      toast.warning(
        'Por favor, corrija os erros apontados na coluna "Status" para poder continuar com a sua solicitação.',
        {
          duration: 5000,
          position: "bottom-center",
        }
      );
      return;
    }

    const invoices = data.map((row) => {
      return {
        ...row.invoice,
      };
    });

    createBatch({ batch, invoices });
  };

  const filterAvailableOwners = () => {
    if (!tenantData) return [];

    return tenantData.users.filter((user) => {
      return !owners.find((owner) => owner.id === user.id);
    });
  };

  const deleteOwner = (index: number) => {
    if (index === 0) return;
    form.setValue(
      "owners",
      formOwners.filter((_, i) => i !== index)
    );
  };

  const addOwner = (value: string) => {
    const owner = tenantData?.users.find((r) => r.id === value);
    setPopoverOwners(false);

    if (!owner) {
      toast.error(
        "Não foi possível adicionar o responsável. Verifique se o usuário existe."
      );
      return;
    }

    if (owners.find((r) => r.id === owner.id)) {
      toast.warning("O responsável já está adicionado no lote.");
      return;
    }

    form.setValue(
      "owners",
      [...owners, owner].map((o) => o.id)
    );
  };

  const informativeText = () => {
    return (
      <div className={"flex items-center justify-end gap-x-2 pb-1"}>
        {error.length > 0 && (
          <ComponentWithTooltip
            tooltip={
              "Verifique os registros inválidos, coloque o ponteiro do mouse sobre o ícone para ver os detalhes."
            }
          >
            <InfoIcon
              className={"hover:cursor-pointer size-4 text-muted-foreground"}
            />
          </ComponentWithTooltip>
        )}
        <span className={"text-xs text-muted-foreground"}>
          Fo{total !== 1 ? "ram" : "i"} processado{total === 1 ? "" : "s"}{" "}
          {total} registro{total === 1 ? "" : "s"}, sendo {success.length}{" "}
          {success.length === 1 ? "registro" : "registros"} válido
          {success.length === 1 ? "" : "s"} e {error.length}{" "}
          {error.length === 1 ? "registro" : "registros"} inválido
          {error.length === 1 ? "" : "s"}
        </span>
      </div>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Leitura do arquivo finalizada ({fileName})</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"flex flex-col gap-y-2"}>
          <h3 className={"font-medium text-lg"}>Configurações do lote</h3>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-3">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className={"max-w-lg"}>
                    <FormLabel required>Nome do lote</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder={fileName} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="owners"
                render={() => (
                  <FormItem className={"w-full"}>
                    <div>
                      <FormLabel required>Responsáveis pelo lote</FormLabel>
                    </div>
                    <FormControl>
                      <div className={"flex flex-wrap gap-2 items-center"}>
                        {owners.map(({ name, id }, index) => (
                          <Badge
                            key={id}
                            variant={index === 0 ? "secondary" : "outline"}
                            className={cn(
                              "select-none",
                              index !== 0 && "hover:cursor-pointer"
                            )}
                            onClick={() => deleteOwner(index)}
                          >
                            <span>{name}</span>
                            {index !== 0 && (
                              <X className={"ml-2 size-3 text-destructive"} />
                            )}
                          </Badge>
                        ))}
                        <Popover
                          open={popoverOwners}
                          onOpenChange={setPopoverOwners}
                        >
                          <PopoverAnchor asChild>
                            <PlusCircle
                              onClick={() => setPopoverOwners(true)}
                              className={
                                "size-4 hover:cursor-pointer select-none"
                              }
                            />
                          </PopoverAnchor>
                          <PopoverContent
                            onOpenAutoFocus={(e) => e.preventDefault()}
                            align={"center"}
                            side={"top"}
                            sideOffset={10}
                            className={"flex flex-col gap-y-2"}
                          >
                            <div
                              className={
                                "text-muted-foreground flex items-center gap-x-1"
                              }
                            >
                              <User className={"size-3"} />
                              <span className={"text-sm"}>
                                Adicionar responsável
                              </span>
                            </div>
                            <Autocomplete
                              options={filterAvailableOwners().map((r) => {
                                return {
                                  label: r.name ?? r.id,
                                  value: r.id,
                                };
                              })}
                              emptyMessage={"Nenhum resultado"}
                              onValueChange={({ value }) => {
                                addOwner(value);
                              }}
                              placeholder={"Clique aqui"}
                            />
                          </PopoverContent>
                        </Popover>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div>
                {informativeText()}
                <ProcessedDataTable data={data} />
              </div>
              <div className={"flex items-center justify-end"}>
                <Button variant={"default"} disabled={isPending}>
                  {isPending && <Loader2 className={"animate-spin"} />}
                  Processar lote
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
}
