import { useQuery } from "@tanstack/react-query";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { companyColumns } from "@/pages/companies/components/company-columns.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Link } from "react-router-dom";
import { PackagePlus } from "lucide-react";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";

export function CompaniesView() {
  const { data } = useQuery<{
    companies: CompanyWithAddress[];
  }>({
    queryKey: ["companies"],
    refetchInterval: 60000,
  });

  const table = useGenericTable({
    columns: companyColumns,
    data: data?.companies,
  });

  return (
    <div className="space-y-4">
      <Button asChild={true}>
        <Link to="/companies/create">
          <PackagePlus size={20} className="mr-2" />
          Cadastrar nova empresa
        </Link>
      </Button>
      <div className="space-y-4 mt-4">
        <div className="rounded-md border">
          <DataTable table={table} />
        </div>
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
