import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import type { UseFormReturn } from "react-hook-form";
import type { z } from "zod";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";

export function IssuerForm({
  form,
  companies,
}: {
  form: UseFormReturn<z.infer<typeof serviceInvoiceSchema>>;
  companies?: CompanyWithAddress[];
}) {
  const selectedCompany = () => {
    const company = companies?.find(
      (company) => company.document === form.getValues("issuer.document")
    );
    return `${company?.document} - ${company?.name}`;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Prestador do serviço</CardTitle>
      </CardHeader>
      <CardContent>
        <FormField
          control={form.control}
          name="issuer.document"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>CNPJ</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "w-full justify-between",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value ? selectedCompany() : "Selecione a empresa"}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] md:w-[700px] p-0 max-w-[500px] overflow-y-auto">
                  <Command>
                    <CommandInput placeholder="Procure a empresa..." />
                    <CommandEmpty>Nenhuma empresa encontrada.</CommandEmpty>
                    <CommandGroup>
                      {companies?.map((company) => (
                        <CommandItem
                          key={company.document}
                          onSelect={() => {
                            field.onChange(company.document);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              company.document === field.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {company.document} - {company.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
}
