export const formatCurrency = (value: number | string, currency: string) => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency,
  }).format(value);
};
