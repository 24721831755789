import type { ComponentPropsWithoutRef } from "react";
import { useCreateCompanyStore } from "@/pages/companies/create/store/useCreateCompanyStore.ts";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { BriefcaseIcon, Check, X } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMutation } from "@tanstack/react-query";
import type {
  CompanyWithAddress} from "@shared/schemas/company.ts";
import {
  getTributaryRegimeName,
} from "@shared/schemas/company.ts";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { formatCNPJ } from "@shared/format.ts";

export function CreateCompanyReview({
  children,
  ...rest
}: ComponentPropsWithoutRef<"div">) {
  const company = useCreateCompanyStore((state) => ({
    ...state.basicInfo,
    address: state.address,
    reset: state.reset,
  }));

  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    mutationKey: ["createCompany", company],
    mutationFn: async (company: CompanyWithAddress) => {
      return fetchApi.post("/companies", company);
    },
  });

  const TaxValidation = () => {
    return company.tributaryRegime && company.stateRegistration ? (
      <Check size={12} className="inline-block ml-1 text-green-600/80" />
    ) : (
      <X size={12} className="inline-block ml-1 text-red-600/80" />
    );
  };

  const AddressValidation = () => {
    return company.address ? (
      <Check size={12} className="inline-block ml-1 text-green-600/80" />
    ) : (
      <X size={12} className="inline-block ml-1 text-red-600/80" />
    );
  };

  const ContactValidation = () => {
    return company.email && company.phone ? (
      <Check size={12} className="inline-block ml-1 text-green-600/80" />
    ) : (
      <X size={12} className="inline-block ml-1 text-red-600/80" />
    );
  };

  const handleSubmit = async () => {
    try {
      await mutateAsync(company);

      company.reset();
      navigate("/companies");
      toast.success("Empresa cadastrada com sucesso!");
    } catch (error) {
      toast.error("Erro ao cadastrar empresa");
    }
  };

  return (
    <>
      <div {...rest}>
        <div className="space-y-4">
          <Alert>
            <AlertTitle className="inline-flex items-center">
              <BriefcaseIcon className="mr-1" />
              Você está prestes a criar uma empresa com os seguintes dados
            </AlertTitle>
            <AlertDescription>
              Não se preocupe, você poderá editar essas informações a qualquer
              momento.
            </AlertDescription>
          </Alert>
          <Card>
            <CardHeader>
              <CardTitle className="font-bold">{company.name}</CardTitle>
              <h4 className="text-muted-foreground">{company.fantasyName}</h4>
              <CardDescription>
                CNPJ: {formatCNPJ(company.document)}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="fmb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                <div className="space-y-1">
                  <p className="text-sm font-semibold leading-none">
                    Informações de Registro Municipal e Estadual
                    <TaxValidation />
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Inscrição Estadual:{" "}
                    {company.stateRegistration || "Não informado"}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Regime Tributário:{" "}
                    {getTributaryRegimeName(company.tributaryRegime)}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Inscrição Municipal:{" "}
                    {company.municipalRegistration || "Não informado"}
                  </p>
                </div>
              </div>
              <div className="fmb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                <div className="space-y-1">
                  <p className="text-sm font-semibold leading-none">
                    Endereço da Empresa <AddressValidation />
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Logradouro: {company.address.street}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Número: {company.address.number}
                  </p>
                  {company.address.complement && (
                    <p className="text-sm text-muted-foreground">
                      Complemento: {company.address.complement}
                    </p>
                  )}
                  <p className="text-sm text-muted-foreground">
                    Bairro: {company.address.district}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Cidade: {company.address.city}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Estado: {company.address.state}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    CEP: {company.address.zipCode}
                  </p>
                </div>
              </div>
              <div className="fmb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                <div className="space-y-1">
                  <p className="text-sm font-semibold leading-none">
                    Informações de Contato
                    <ContactValidation />
                  </p>
                  <p className="text-sm text-muted-foreground">
                    E-mail: {company.email}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Telefone: {company.phone}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      {children}
      <Button variant="pop" onClick={handleSubmit}>
        <span>Finalizar</span>
        <div className="ml-1 transition group-hover:translate-x-1">
          <Check />
        </div>
      </Button>
    </>
  );
}
