import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { Input } from "@/components/ui/input.tsx";
import type { UseFormReturn } from "react-hook-form";
import type { z } from "zod";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import type { ExternalCompany } from "@/hooks/use-external-company.ts";
import { DocumentInput } from "@/components/validators/document-input.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { onlyNumbers } from "@shared/format.ts";

export function RecipientForm({
  form,
}: {
  form: UseFormReturn<z.infer<typeof serviceInvoiceSchema>>;
}) {
  const { mutate } = useMutation({
    mutationKey: ["/external/company"],
    mutationFn: (data: { document: string }) =>
      fetchApi
        .get("/external/company/" + onlyNumbers(data.document))
        .then((res) => res.data.data as ExternalCompany),
    onSuccess: (data) => {
      form.setValue("recipient.companyName", data.name);
      form.setValue("recipient.address.cityCode", data.address.cityCode);
      form.setValue("recipient.address.street", data.address.street);
      form.setValue("recipient.address.complement", data.address.complement);
      form.setValue("recipient.address.number", data.address.number);
      form.setValue("recipient.address.district", data.address.district);
      form.setValue("recipient.address.zipCode", data.address.zipCode);
      form.setValue("recipient.address.state", data.address.state);
    },
  });

  const documentType = form.watch("recipient.documentType");

  return (
    <Card>
      <CardHeader>
        <CardTitle>Tomador do serviço</CardTitle>
        <CardContent>
          <div className="grid grid-cols-4 gap-4">
            <FormField
              name="recipient.documentType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tipo</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione o tipo do documento" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="CNPJ">CNPJ</SelectItem>
                      <SelectItem value="CPF">CPF</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.document"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>CNPJ</FormLabel>
                  <FormControl>
                    <DocumentInput
                      {...field}
                      format={documentType}
                      onChange={(value) => {
                        const companyDocument = onlyNumbers(value);
                        const isValidCNPJ =
                          documentType === "CNPJ" &&
                          companyDocument.length === 14;

                        if (isValidCNPJ) {
                          mutate({ document: companyDocument });
                        }

                        field.onChange(value);
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.companyName"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Razão Social</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.address.zipCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>CEP</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.address.street"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Logradouro</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.address.number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Número</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.address.complement"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Complemento</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.address.district"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bairro</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.address.state"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Estado (UF)</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="recipient.email"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </CardContent>
      </CardHeader>
    </Card>
  );
}
