import { groupBy } from "@/lib/utils.ts";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Label } from "@/components/ui/label.tsx";
import { format } from "date-fns";
import { Badge } from "@/components/ui/badge.tsx";
import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { BatchServiceInvoicesTable } from "@/pages/invoices/batches/[batchId]/components/batch-service-invoices-table.tsx";
import type { BatchCardData } from "@/pages/invoices/batches/components/batch-card.tsx";

type InvoiceStatuses =
  | "pending"
  | "processing"
  | "error"
  | "success"
  | "cancelled";

export function BatchServiceView({
  batchData,
  batchInvoices,
}: {
  batchData: Omit<BatchCardData, "invoices" | "status">;
  batchInvoices: {
    invoices: Array<{
      id: string;
      status: string;
      type: "product" | "service";
      issuer: {
        document: string;
        name: string;
      };
      recipient: {
        document: string;
        name: string;
      };
      createdAt: string;
    }>;
  };
}) {
  const emptyInvoices = batchInvoices.invoices.length === 0;
  const batchOwner = batchData.owners.find((owner) => owner.role === "creator");
  const batchMembers = batchData.owners.filter(
    (owner) => owner.role === "member"
  );

  const {
    pending = [],
    processing = [],
    error = [],
    success = [],
    cancelled = [],
  } = groupBy(
    batchInvoices.invoices,
    (invoice) => invoice.status as InvoiceStatuses
  );

  const getBatchStatus = () => {
    if (emptyInvoices) {
      return {
        variant: "outline" as const,
        message: "Lote não processado - Na fila",
      };
    }

    if (pending.length > 0 || processing.length > 0) {
      return {
        variant: "processing" as const,
        message: "Lote em processamento",
      };
    }

    const hasSuccess = success.length > 0;
    const hasError = error.length > 0;
    const hasCancelled = cancelled.length > 0;

    if (hasSuccess || hasError || hasCancelled) {
      if (hasError) {
        return {
          variant: "successWithError" as const,
          message: "Lote processado com erros",
        };
      }

      return {
        variant: "success" as const,
        message: "Lote processado com sucesso",
      };
    }

    return {
      variant: "destructive" as const,
      message: "Desconhecido",
    };
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          Visualizando detalhes do lote #{batchData.id} - {batchData.name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"flex flex-col gap-5"}>
          <div className={"flex flex-col gap-2"}>
            <Label htmlFor={"createdAt"} className={"font-medium"}>
              Data de criação
            </Label>
            <div className={"flex flex-row items-center gap-x-2"}>
              <span className={"text-sm"}>
                {format(new Date(batchData.createdAt), "PPP 'às' p")}
              </span>
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <Label htmlFor={"createdAt"} className={"font-medium"}>
              Última atualização
            </Label>
            <div className={"flex flex-row items-center gap-x-2"}>
              <span className={"text-sm"}>
                {format(new Date(batchData.updatedAt), "PPP 'às' p")}
              </span>
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <Label htmlFor={"createdAt"} className={"font-medium"}>
              Tipo
            </Label>
            <div className={"flex flex-row items-center gap-x-2"}>
              <span className={"text-sm"}>
                {batchData.type === "service"
                  ? "Nota fiscal de serviço"
                  : "Nota fiscal de produto"}
              </span>
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <Label htmlFor={"batchName"} className={"font-medium"}>
              Status
            </Label>
            <Badge variant={getBatchStatus().variant} className={"w-fit"}>
              {getBatchStatus().message}
            </Badge>
          </div>
          {!!batchOwner && (
            <div className={"flex flex-col gap-2"}>
              <Label htmlFor={"batchName"} className={"font-medium"}>
                Responsável pela criação
              </Label>
              <div className={"flex flex-row items-center gap-x-2"}>
                <Avatar>
                  <AvatarFallback>{batchOwner.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <span className={"text-sm"}>{batchOwner.name}</span>
              </div>
            </div>
          )}
          {batchMembers.length > 0 && (
            <div className={"flex flex-col gap-2"}>
              <Label htmlFor={"batchName"} className={"font-medium"}>
                Responsáveis
              </Label>
              <div className={"flex flex-col gap-y-2"}>
                {batchMembers.map((member, index) => (
                  <div
                    key={`member-${index}`}
                    className={"flex items-center gap-x-2"}
                  >
                    <Avatar>
                      <AvatarFallback>{member.name.charAt(0)}</AvatarFallback>
                    </Avatar>
                    <span className={"text-sm"}>{member.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {(processing.length > 0 || pending.length > 0) && (
            <div className={"flex flex-col gap-2"}>
              <Label htmlFor={"batchName"} className={"font-medium"}>
                Notas em processamento
              </Label>
              <BatchServiceInvoicesTable data={[...processing, ...pending]} />
            </div>
          )}
          {error.length > 0 && (
            <div className={"flex flex-col gap-2"}>
              <Label htmlFor={"batchName"} className={"font-medium"}>
                Notas com erro
              </Label>
              <BatchServiceInvoicesTable data={error} />
            </div>
          )}
          {success.length > 0 && (
            <div className={"flex flex-col gap-2"}>
              <Label htmlFor={"batchName"} className={"font-medium"}>
                Notas concluídas
              </Label>
              <BatchServiceInvoicesTable data={success} />
            </div>
          )}
          {cancelled.length > 0 && (
            <div className={"flex flex-col gap-2"}>
              <Label htmlFor={"batchName"} className={"font-medium"}>
                Notas canceladas
              </Label>
              <BatchServiceInvoicesTable data={cancelled} />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
