import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { inputVendorSchema } from "@shared/schemas/vendor.ts";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { useVendor } from "@/hooks/use-vendor.ts";
import { Input } from "@/components/ui/input.tsx";
import { LucideThumbsDown, LucideThumbsUp, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { fetchApi } from "@/lib/api.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cn } from "@/lib/utils.ts";
import { toast } from "sonner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { pushModal } from "@/modals";

export function EditVendor() {
  const { document } = useParams();
  const { data, isError } = useVendor(document);
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof inputVendorSchema>>({
    resolver: zodResolver(inputVendorSchema),
    defaultValues: data,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [`/vendors/${document}`],
    mutationFn: (data: z.infer<typeof inputVendorSchema>) =>
      fetchApi(`/vendors/${document}`, {
        method: "PATCH",
        data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/vendors/${document}`],
      });
      toast.success("Dados atualizados com sucesso!");
    },
  });

  if (isError || !data) {
    return (
      <div className="flex justify-center items-center h-64">
        <p>Erro ao carregar os dados</p>
      </div>
    );
  }

  const VendorStatus = () => {
    const vendorActive = data.status === "active";
    const Icon = vendorActive ? LucideThumbsUp : LucideThumbsDown;

    return (
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type={"button"}
              variant="ghost"
              size="icon"
              onClick={() =>
                pushModal("EditVendorStatusConfirmationDialog", {
                  data,
                  vendorActive,
                })
              }
              className={cn(
                vendorActive
                  ? "bg-green-600 hover:bg-green-700"
                  : "bg-red-600 hover:bg-red-700"
              )}
            >
              <Icon className="size-6 text-secondary" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              O fornecedor está{" "}
              <span className="font-bold">
                {vendorActive ? "ativo" : "inativo"}
              </span>
              .
            </p>
            <p>Clique para mudar o status do fornecedor.</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  return (
    <>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((val) => mutate(val))}>
            <div className={"flex items-center justify-between"}>
              <p className="text-lg font-semibold">Dados Cadastrais</p>
              <VendorStatus />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-y-5 gap-x-3">
              <FormField
                name="name"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Razão Social</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="fantasyName"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Nome Fantasia</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                name="stateRegistration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Inscrição Estadual</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="municipalRegistration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Inscrição Municipal</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <p className="font-semibold text-lg my-6">Informações de Contato</p>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-y-5 gap-x-3">
              <FormField
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Telefone</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <p className="font-semibold text-lg my-6">Endereço</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5 gap-x-3">
              <FormField
                name="address.street"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Logradouro</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Número</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.district"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Bairro</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Município</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.state"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Estado</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="address.zipCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>CEP</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className={"mt-12 flex justify-end"}>
              <Button variant={"default"} disabled={isPending}>
                <RefreshCw
                  className={cn({ "animate-spin": isPending }, "mr-2 size-4")}
                />
                Atualizar
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
}
