import { ConfigureCertificate } from "@/pages/companies/[document]/components/certificate-add.tsx";
import { CertificateEdit } from "@/pages/companies/[document]/components/certificate-edit.tsx";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CredentialsAdd } from "@/pages/companies/[document]/components/credentials-add.tsx";
import { Card } from "@/components/ui/card.tsx";
import { CredentialsEdit } from "@/pages/companies/[document]/components/credentials-edit.tsx";

export function FormSettings() {
  const { document } = useParams();

  const { data: credentials } = useQuery<{
    hasCertificate: boolean;
    hasCredentials: boolean;
    certificate?: {
      name: string;
      document: string;
      expiresAt: string;
    };
  }>({
    queryKey: [`/companies/${document}/credentials`],
    enabled: !!document,
  });

  if (!credentials || !document) {
    return null;
  }

  return (
    <>
      <Card className="p-4">
        {credentials.hasCertificate ? (
          <CertificateEdit />
        ) : (
          <ConfigureCertificate />
        )}
      </Card>
      <Card className="p-4">
        {credentials.hasCredentials ? <CredentialsEdit /> : <CredentialsAdd />}
      </Card>
    </>
  );
}
