export function ParagraphInfo({
  name,
  value,
}: {
  name: string;
  value?: string | number | null;
}) {
  return (
    <div className={"flex gap-x-1"}>
      <p className={"text-sm font-medium"}>
        {name}:{" "}
        <span className={"font-normal"}>
          {" "}
          {value ?? "Erro ao coletar dados"}
        </span>
      </p>
    </div>
  );
}
