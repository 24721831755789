import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AlertTriangle, LogOutIcon, RefreshCw } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { ComponentWithTooltip } from "@/components/component-with-tooltip.tsx";
import { pushModal } from "@/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "@/hooks/use-session-context.ts";

export function UserNav() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { session, isLoading } = useSessionContext();

  const { mutateAsync: mutateLogout } = useMutation({
    mutationKey: ["/auth/logout"],
    mutationFn: async () => fetchApi.post("/auth/logout"),
  });

  if (isLoading) return <Skeleton className="h-8 w-8 rounded-full" />;

  if (!session)
    return (
      <ComponentWithTooltip tooltip="Não foi possível carregar os dados do usuário.">
        <AlertTriangle className="size-6 text-destructive" />
      </ComponentWithTooltip>
    );

  const logout = () => {
    pushModal("ConfirmationModal", {
      title: "Deseja encerrar a sessão e sair do sistema?",
      confirmation: async () => {
        await mutateLogout();
        await queryClient.invalidateQueries({
          queryKey: ["users/me"],
        });
        navigate("/auth/login");
      },
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarFallback>
              {session.name ? (
                (
                  session.name?.charAt(0) || session.email.charAt(0)
                ).toUpperCase()
              ) : (
                <Skeleton className="h-8 w-8 rounded-full" />
              )}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {session.name || "Usuário"}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {session.email}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {/*Store the actual tenancy in the user's profile*/}
              Grupo atual:{" "}
              <span className={"font-semibold"}>{session.tenantId}</span>
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {/*Send the actual tenancy to the tenancy change modal*/}
          <DropdownMenuItem
            onClick={() =>
              pushModal("ChangeTenancyModal", {
                actualTenancy: session.tenantId,
              })
            }
          >
            <RefreshCw className={"size-4 mr-1"} />
            Alterar grupo
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>
          <LogOutIcon className="size-4 mr-1" />
          Encerrar sessão
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
