import { z } from "zod";
import { onlyNumbers } from "../../format";
import { currencySchema, percentageSchema } from "../common";

const issuerSchema = z.object({
  document: z.string().min(1, "CNPJ/CPF é obrigatório").transform(onlyNumbers),
  operationNature: z
    .string()
    .nullish()
    .transform((v) => v ?? "1"),
  // municipalRegistration: z.string().min(1, "Inscrição estadual é obrigatória"),
  // cityCode: z.string().optional(),
  // tributaryRegime: z.nativeEnum(RegimeTributary).nullish(),
});

const recipientSchema = z.object({
  document: z.string().min(1, "CNPJ/CPF é obrigatório").transform(onlyNumbers),
  documentType: z.enum(["CPF", "CNPJ"]),
  companyName: z.string(),
  email: z.string().nullish(),
  address: z.object({
    street: z.string().min(1, "Logradouro é obrigatório"),
    number: z.string().min(1, "Número é obrigatório"),
    complement: z.string(),
    district: z.string().min(1, "Bairro é obrigatório"),
    state: z.string().min(1, "Estado é obrigatório"),
    zipCode: z.string().min(1, "CEP é obrigatório"),
    cityCode: z.string().min(1, "Código do município é obrigatório"),
  }),
});

const serviceItemSchema = z.object({
  description: z.string().min(1, "Descrição é obrigatória"),
  item: z.string().min(1, "Código do item é obrigatório"),
  issRetained: z.boolean().default(false),
  municipalTaxCode: z.string().optional(),
  aliquot: percentageSchema,
  value: currencySchema.pipe(z.coerce.number().positive()),
});

const totalSchema = z.object({
  pis: z.string().default("0"),
  cofins: z.string().default("0"),
  inss: z.string().default("0"),
  ir: z.string().default("0"),
  csll: z.string().default("0"),
  deductions: z.string().default("0"),
});

export const serviceInvoiceSchema = z.object({
  issuer: issuerSchema,
  recipient: recipientSchema,
  items: z.array(serviceItemSchema),
  totals: totalSchema.default({
    pis: "0",
    cofins: "0",
    inss: "0",
    ir: "0",
    csll: "0",
    deductions: "0",
  }),
  issuedAt: z.string(),
});
