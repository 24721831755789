import { Input } from "@/components/ui/input.tsx";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { InfoIcon } from "lucide-react";
import { cn } from "@/lib/utils.ts";

interface InputWithTooltipProps extends React.ComponentProps<typeof Input> {
  label: string;
  children: React.ReactNode;
  name: string;
}

export function InputWithTooltip({
  label,
  children,
  name,
  ...rest
}: InputWithTooltipProps) {
  return (
    <FormField
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex items-center">
            {label}
            {children}
          </FormLabel>
          <FormControl>
            <Input {...field} {...rest} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function InputTooltip({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={180}>
        <TooltipTrigger asChild>
          <InfoIcon className="ml-1" size={12} />
        </TooltipTrigger>
        <TooltipContent className={cn("max-w-64")}>
          <div className="font-bold text-sm">{title}</div>
          <div className="text-muted-foreground text-xs">{content}</div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
