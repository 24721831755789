import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Loader2, Lock } from "lucide-react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CredentialsRemove } from "@/pages/companies/[document]/components/credentials-remove.tsx";

export function CredentialsEdit() {
  const { document } = useParams();

  const { data: credentials, isLoading } = useQuery<{
    hasCredentials: boolean;
  }>({
    queryKey: [`/companies/${document}/credentials`],
    enabled: !!document,
  });

  return (
    <div>
      <p className="font-semibold text-lg">
        Informações sobre o seu usuário da prefeitura
      </p>
      <p className="text-sm text-muted-foreground mt-2">
        Sua empresa{" "}
        <span className="font-bold">já possui um usuário da prefeitura</span>{" "}
        configurado em nosso sistema, você pode revogar as credenciais a
        qualquer momento.
      </p>
      <p className="text-xs font-light mt-4">
        <Lock
          size={15}
          className="inline-block mr-1 cover-fill text-green-600/80"
        />
        Suas credenciais estão salvas de forma segura e criptografada em nossos
        servidores.
      </p>
      <div className="mt-4 grid grid-cols-3 gap-x-3 gap-y-5">
        {isLoading && <Loader2 className="animate-spin h-6 w-6" />}
        {credentials?.hasCredentials && (
          <>
            <div>
              <Label>Usuário</Label>
              <Input value="**********" disabled />
            </div>
            <div>
              <Label>Senha</Label>
              <Input value="**********" disabled />
            </div>
          </>
        )}
      </div>
      <div className="mt-6">
        <CredentialsRemove />
      </div>
    </div>
  );
}
