import { Separator } from "@/components/ui/separator.tsx";

export function VendorHistory() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Histórico de atividades</h3>
        <p className="text-sm text-muted-foreground">
          Abaixo você pode ver o histórico de atividades do fornecedor
        </p>
      </div>
      <Separator />
    </div>
  );
}
