import { useQuery } from "@tanstack/react-query";

type ExternalCity<T> = {
  city: string;
  cityCode: string;
  state: string;
  stateCode: string;
} & (T extends true ? { serviceList: ServiceList[] } : never);

interface ServiceList {
  code: string;
  description: string;
  tax: string;
}

type CityParams<T extends boolean> = (
  | {
      cityCode: string;
    }
  | {
      cityName: string;
      stateCode: string;
    }
) & { includeServiceList: T };

export function useExternalCity<T extends boolean>(options: CityParams<T>) {
  const params = new URLSearchParams();

  if ("cityCode" in options) {
    params.append("cityCode", options.cityCode);
  } else if ("cityName" in options) {
    params.append("cityName", options.cityName);
    params.append("stateCode", options.stateCode);
  }

  if (options.includeServiceList) {
    params.append("include", "service_list");
  }

  return useQuery<ExternalCity<T>>({
    queryKey: [`/external/cities?${params.toString()}`],
    enabled:
      ("cityCode" in options && !!options.cityCode) ||
      ("cityName" in options && !!options.cityName),
  });
}
