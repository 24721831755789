import { Sheet, SheetTrigger, SheetContent } from "@/components/ui/sheet.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Home,
  PanelLeft,
  LineChart,
  Building2,
  Handshake,
  Files,
  UserPlus,
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils.ts";
import { useEffect, useState } from "react";

function NavButton({
  children,
  path,
  active,
}: {
  children: React.ReactNode;
  path: string;
  active?: boolean;
}) {
  return (
    <Link
      to={path}
      className={cn(
        "flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground",
        active && "text-foreground"
      )}
    >
      {children}
    </Link>
  );
}

export function MobileNavbar() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location.pathname]);

  return (
    <header className="flex md:hidden top-0 z-30 h-14 justify-between items-center gap-4 border-b bg-background px-4 md:static md:h-auto md:border-0 md:bg-transparent md:px-6">
      <Sheet open={isSidebarOpen} onOpenChange={setIsSidebarOpen}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="md:hidden">
            <PanelLeft className="size-6" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="sm:max-w-xs">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              to="#"
              className="group flex shrink-0 items-center justify-start gap-2 rounded-full text-lg font-semibold"
            >
              <picture>
                <source
                  srcSet="/logo/twokei_black.png"
                  media="(prefers-color-scheme: dark)"
                />
                <img
                  src="/logo/twokei_white.png"
                  alt="Twokei Logo"
                  className="h-8"
                />
              </picture>
            </Link>
            <NavButton path="/" active={location.pathname === "/"}>
              <Home className="h-5 w-5" />
              Dashboard
            </NavButton>
            <NavButton
              path="/companies"
              active={location.pathname.startsWith("/companies")}
            >
              <Building2 className="h-5 w-5" />
              Empresas
            </NavButton>
            <NavButton
              path="/vendors"
              active={location.pathname.startsWith("/vendors")}
            >
              <Handshake className="h-5 w-5" />
              Fornecedores
            </NavButton>
            <NavButton
              path="/invoices/sent"
              active={location.pathname.startsWith("/invoices/sent")}
            >
              <Files className="h-5 w-5" />
              Notas Enviadas
            </NavButton>
            <NavButton
              path="/invoices/received"
              active={location.pathname.startsWith("/invoices/received")}
            >
              <LineChart className="h-5 w-5" />
              Notas Recebidas
            </NavButton>
            <NavButton
              path="/users"
              active={location.pathname.startsWith("/users")}
            >
              <UserPlus className="h-5 w-5" />
              Usuários
            </NavButton>
          </nav>
        </SheetContent>
      </Sheet>
    </header>
  );
}
