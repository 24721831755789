import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

export function useSessionContext() {
  const location = useLocation();

  const { data, isLoading, error } = useQuery<{
    id: string;
    name: string;
    email: string;
    tenantId: string;
  }>({
    queryKey: ["users/me"],
    refetchOnWindowFocus: () => {
      return !location.pathname.startsWith("/auth");
    },
    staleTime: 60000,
  });

  const isLoggedIn = !error && !!data;

  return {
    session: data,
    isLoading,
    isLoggedIn,
  };
}
