//prettier-ignore
export const icmsTaxes = [
    { id: 0, label: 'Tributada integralmente' },
    { id: 10, label: 'Tributada e com cobrança do ICMS por substituição tributária' },
    { id: 20, label: 'Tributada com redução de base de cálculo' },
    { id: 30, label: 'Isenta ou não tributada e com cobrança do ICMS por substituição tributária' },
    { id: 40, label: 'Isenta' },
    { id: 41, label: 'Não tributada' },
    { id: 50, label: 'Suspensão' },
    { id: 51, label: 'Diferimento' },
    { id: 60, label: 'Cobrado anteriormente por substituição tributária' },
    { id: 70, label: 'Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária' },
    { id: 90, label: 'Outras (regime Normal)' },
    { id: 101, label: 'Tributada pelo Simples Nacional com permissão de crédito' },
    { id: 102, label: 'Tributada pelo Simples Nacional sem permissão de crédito' },
    { id: 103, label: 'Isenção do ICMS no Simples Nacional para faixa de receita bruta' },
    { id: 201, label: 'Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária' },
    { id: 202, label: 'Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária' },
    { id: 203, label: 'Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária' },
    { id: 300, label: 'Imune' },
    { id: 400, label: 'Não tributada pelo Simples Nacional' },
    { id: 500, label: 'ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação' },
    { id: 900, label: 'Outras (regime Simples Nacional)' }
];

//prettier-ignore
export const pisTaxes = [
    { id: 1, label: 'Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo)' },
    { id: 2, label: 'Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)' },
    { id: 3, label: 'Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto' },
    { id: 4, label: 'Operação tributável: tributação monofásica (alíquota zero)' },
    { id: 5, label: 'Operação tributável: substituição tributária' },
    { id: 6, label: 'Operação tributável: alíquota zero' },
    { id: 7, label: 'Operação isenta da contribuição' },
    { id: 8, label: 'Operação sem incidência da contribuição' },
    { id: 9, label: 'Operação com suspensão da contribuição' },
    { id: 49, label: 'Outras operações de saída' },
    { id: 50, label: 'Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno' },
    { id: 51, label: 'Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno' },
    { id: 52, label: 'Operação com direito a crédito: vinculada exclusivamente a receita de exportação' },
    { id: 53, label: 'Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno' },
    { id: 54, label: 'Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação' },
    { id: 55, label: 'Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exprtação' },
    { id: 56, label: 'Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação' },
    { id: 60, label: 'Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno' },
    { id: 61, label: 'Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno' },
    { id: 62, label: 'Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação' },
    { id: 63, label: 'Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno' },
    { id: 64, label: 'Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação' },
    { id: 65, label: 'Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação' },
    { id: 66, label: 'Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação' },
    { id: 67, label: 'Crédito presumido: outras operações' },
    { id: 70, label: 'Operação de aquisição sem direito a crédito' },
    { id: 71, label: 'Operação de aquisição com isenção' },
    { id: 72, label: 'Operação de aquisição com suspensão' },
    { id: 73, label: 'Operação de aquisição a alíquota zero' },
    { id: 74, label: 'Operação de aquisição sem incidência da contribuição' },
    { id: 75, label: 'Operação de aquisição por substituição tributária' },
    { id: 98, label: 'Outras operações de entrada' },
    { id: 99, label: 'Outras operações' }
];

//prettier-ignore
export const icmsOrigem = [
    { id: 0, label: 'Nacional' },
    { id: 1, label: 'Estrangeira (importação direta)' },
    { id: 2, label: 'Estrangeira (adquirida no mercado interno)' },
    { id: 3, label: 'Nacional com mais de 40% de conteúdo estrangeiro' },
    { id: 4, label: 'Nacional produzida através de processos produtivos básicos' },
    { id: 5, label: 'Nacional com menos de 40% de conteúdo estrangeiro' },
    { id: 6, label: 'Estrangeira (importação direta) sem produto nacional similar' },
    { id: 7, label: 'Estrangeira (adquirida no mercado interno) sem produto nacional similar' }
];
