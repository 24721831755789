import { Separator } from "@/components/ui/separator.tsx";
import { TaxesComponent } from "@/pages/companies/[document]/components/taxes-component.tsx";

export function CompanyTaxesEdit() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Tributação</h3>
        <p className="text-sm text-muted-foreground">
          Abaixo você pode editar as informações de tributação da sua empresa no
          sistema
        </p>
      </div>
      <Separator />
      <TaxesComponent />
    </div>
  );
}
