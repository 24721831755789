import { useParams } from "react-router-dom";
import { BatchBack } from "@/pages/invoices/batches/[batchId]/components/batch-back.tsx";
import { useQuery } from "@tanstack/react-query";
import type { BatchCardData } from "@/pages/invoices/batches/components/batch-card.tsx";
import { Loader2 } from "lucide-react";
import { BatchServiceView } from "@/pages/invoices/batches/[batchId]/components/batch-service-view.tsx";

export function BatchView() {
  const params = useParams<{ batchId: string }>();

  const { data: batchData, isLoading: isLoadingBatch } = useQuery<
    Omit<BatchCardData, "invoices" | "status">
  >({
    queryKey: [`/invoices/batches/${params.batchId}`],
  });

  const { data: batchInvoices, isLoading: isLoadingInvoices } = useQuery<{
    invoices: Array<{
      id: string;
      status: string;
      type: "product" | "service";
      issuer: {
        document: string;
        name: string;
      };
      recipient: {
        document: string;
        name: string;
      };
      createdAt: string;
    }>;
  }>({
    queryKey: [
      `/invoices?batchId=${params.batchId}&type=sent&invoiceType=${batchData?.type}`,
    ],
    refetchInterval: 15000,
    enabled: !isLoadingBatch,
  });

  if (isLoadingBatch || isLoadingInvoices || !batchData || !batchInvoices) {
    return (
      <div className={"flex items-center justify-center h-[200px] w-full"}>
        <div className={"flex flex-row items-center gap-x-2"}>
          <Loader2 className={"size-5 animate-spin"} />
          <span className={"text-sm text-muted-foreground"}>
            Carregando dados do lote...
          </span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <BatchBack />
      {batchData.type === "service" && (
        <BatchServiceView batchData={batchData} batchInvoices={batchInvoices} />
      )}
    </div>
  );
}
