import { z } from "zod";

export const nfeTaxes = z
  .object({
    icms: z.object({
      cst: z.string(),
      orig: z.string(),
      modBC: z.string(),
      pRedBC: z.string(),
      vBC: z.string(),
      pICMS: z.string(),
      vICMS: z.string(),
    }),
    cofins: z.object({
      cst: z.string(),
      vBC: z.string(),
      pCOFINS: z.string(),
      vCOFINS: z.string(),
    }),
    pis: z.object({
      cst: z.string(),
      vBC: z.string(),
      pPIS: z.string(),
      vPIS: z.string(),
    }),
    invoice: z.object({
      serie: z.string(),
      nextNumber: z.string(),
    }),
  })
  .partial();

export type NfeTaxes = z.infer<typeof nfeTaxes>;
