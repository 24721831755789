import { NumericFormat } from "react-number-format";
import { Input } from "@/components/ui/input.tsx";
import type { ComponentPropsWithoutRef } from "react";

interface Props
  extends Omit<
    ComponentPropsWithoutRef<typeof NumericFormat>,
    "value" | "onChange" | "customInput"
  > {
  value: string | number | null;
  onChange: (value: string) => void;
}

export function CurrencyInput({ value, onChange, ...props }: Props) {
  return (
    <NumericFormat
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowedDecimalSeparators={[","]}
      prefix="R$ "
      suffix=""
      allowNegative={false}
      value={value}
      onValueChange={(value) => onChange(value.value)}
      customInput={Input}
      allowLeadingZeros={false}
      placeholder="R$ 0,00"
      {...props}
    />
  );
}

export function PercentageInput({ value, onChange, ...props }: Props) {
  return (
    <NumericFormat
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowedDecimalSeparators={[","]}
      prefix=""
      suffix=" %"
      allowNegative={false}
      value={value}
      onValueChange={(value) => onChange(value.value)}
      customInput={Input}
      allowLeadingZeros={false}
      placeholder="0%"
      isAllowed={(formatValues) => {
        if (!formatValues.value) {
          return true;
        }

        const asNumber = Number(formatValues.value);
        return asNumber >= 0 && asNumber <= 100;
      }}
      {...props}
    />
  );
}
