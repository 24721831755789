import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";

type UpdateCredentialsMutation =
  | {
      username: string;
      password: string;
    }
  | {
      certificate: string;
      certificatePassword: string;
    };

type InitType = {
  document?: string;
  type: "certificate" | "credentials";
};

export function useDeleteCredentialsMutation({ document, type }: InitType) {
  const queryClient = useQueryClient();
  const mutationKey = `/companies/${document}/credentials/${type}`;

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: () =>
      fetchApi<{ status: "success" | "error" }>(mutationKey, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/credentials`],
      });
    },
  });
}

export function useUpdateCredentialsMutation({ document, type }: InitType) {
  const queryClient = useQueryClient();
  const mutationKey = `/companies/${document}/credentials/${type}`;

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: (fields: UpdateCredentialsMutation) =>
      fetchApi<{ status: "success" | "error" }>(mutationKey, {
        method: "POST",
        data: {
          ...fields,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/credentials`],
      });
    },
  });
}
