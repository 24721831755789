import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { fetchApi } from "@/lib/api.ts";
import { useForm } from "react-hook-form";

interface EditProps {
  userId: string | null;
  onOpenChange: (open: boolean) => void;
}

const fields = [
  {
    id: "name",
    label: "Nome",
  },
  {
    id: "email",
    label: "Email",
  },
] as const;

export interface UserData {
  id: string;
  name?: string;
  email: string;
  status: string;
  companies: {
    id: string;
    name: string;
    permissions: string[];
  }[];
}

export function ViewUserDetails({ userId, onOpenChange }: EditProps) {
  const { data: user } = useQuery<UserData>({
    queryKey: [`/users/${userId}`],
    enabled: !!userId,
  });

  const { mutate } = useMutation({
    mutationKey: [`/users/${userId}`],
    mutationFn: (data: Partial<UserData>) =>
      fetchApi(`/users/${userId}`, {
        method: "PUT",
        data,
      }),
  });

  const form = useForm({
    defaultValues: user,
  });

  if (!user) {
    return null;
  }

  return (
    <Sheet open={!!userId} onOpenChange={onOpenChange}>
      <SheetContent>
        <form onSubmit={form.handleSubmit((data) => mutate(data))}>
          <SheetHeader>
            <SheetTitle className="flex items-center gap-2">
              <Avatar>
                <AvatarFallback>
                  {user.name?.[0].toUpperCase() ?? user.email[0].toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <span>{user.name}</span>
            </SheetTitle>
          </SheetHeader>
          <Separator className="my-4" />
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Informações pessoais</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
              {fields.map((field) => (
                <div key={field.id}>
                  <Label htmlFor={field.id}>{field.label}</Label>
                  <Input
                    id={field.id}
                    type="text"
                    placeholder={field.label}
                    defaultValue={user[field.id]}
                    {...form.register(field.id)}
                  />
                </div>
              ))}
            </CardContent>
          </Card>
          <Separator className="my-4" />
          {/*<Card>*/}
          {/*  <CardHeader>*/}
          {/*    <CardTitle className="text-lg">Permissões</CardTitle>*/}
          {/*    <CardDescription>*/}
          {/*      Escolha quais empresas o usuário pode acessar, editar ou*/}
          {/*      excluir.*/}
          {/*    </CardDescription>*/}
          {/*  </CardHeader>*/}
          {/*  <CardContent>*/}
          {/*    <div className="flex items-center gap-2">*/}
          {/*      <Input type="text" placeholder="Pesquisar empresa" />*/}
          {/*      <Button title="Adicionar empresa" variant="outline" size="icon">*/}
          {/*        <PackagePlusIcon />*/}
          {/*      </Button>*/}
          {/*    </div>*/}

          {/*    <div className="grid grid-cols-1 gap-4 mt-4">*/}
          {/*      {user.companies.map((company) => (*/}
          {/*        <EditPermissionsDialog*/}
          {/*          user={{*/}
          {/*            name: user.name,*/}
          {/*            permissions:*/}
          {/*              user.companies.find(*/}
          {/*                (company) => company.id === user.companies[0].id*/}
          {/*              )?.permissions || [],*/}
          {/*          }}*/}
          {/*          company={company}*/}
          {/*        >*/}
          {/*          <Button*/}
          {/*            key={company.id}*/}
          {/*            variant="ghost"*/}
          {/*            className="flex items-center gap-4 w-full py-6 px-0"*/}
          {/*          >*/}
          {/*            <Avatar>*/}
          {/*              <AvatarFallback>*/}
          {/*                {company.name[0].toUpperCase()}*/}
          {/*              </AvatarFallback>*/}
          {/*            </Avatar>*/}
          {/*            <div className="flex-1 text-start overflow-hidden">*/}
          {/*              <span className="font-bold">{company.name}</span>*/}
          {/*              <p className="text-sm">00.000.000/0000-00 </p>*/}
          {/*            </div>*/}
          {/*          </Button>*/}
          {/*        </EditPermissionsDialog>*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}
          <Separator className="my-4" />
          <div className="flex gap-2 w-full">
            <Button className="w-full">Salvar alterações</Button>
            <Button
              className="w-full"
              type="button"
              variant="outline"
              onClick={(e) => {
                e.preventDefault();
                onOpenChange(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}
