import { z } from "zod";
import { addressSchema } from "./common";

export const inputVendorSchema = z
  .object({
    name: z
      .string({
        required_error: "O nome é obrigatório",
      })
      .min(1, "Nome obrigatório"),
    fantasyName: z.string().nullish(),
    document: z
      .string({
        required_error: "O CNPJ é obrigatório",
      })
      .min(1, "O CNPJ é obrigatório"),
    phone: z.string().nullish(),
    email: z.string().email("Email inválido").nullish(),
    status: z.string().nullish(),
    municipalRegistration: z
      .string()
      .min(1, "Inscrição municipal é obrigatória")
      .nullish(),
    stateRegistration: z
      .string()
      .min(1, "Inscrição estadual é obrigatória")
      .nullish(),
  })
  .extend({
    address: addressSchema.nullish(),
  });

const vendorsSchema = inputVendorSchema
  .pick({
    document: true,
    name: true,
  })
  .extend({
    id: z.string(),
  });

export type Vendor = z.infer<typeof inputVendorSchema>;
export type Vendors = z.infer<typeof vendorsSchema>;
