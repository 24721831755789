import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog.tsx";
import { useDeleteCredentialsMutation } from "@/pages/companies/[document]/actions/useCredentialsMutation.ts";
import { toast } from "sonner";
import { Button } from "@/components/ui/button.tsx";
import { useState } from "react";
import { useParams } from "react-router-dom";

export function CredentialsRemove() {
  const [open, setOpen] = useState(false);

  const { document } = useParams();

  const { mutateAsync: deleteCredentials, isPending } =
    useDeleteCredentialsMutation({
      document,
      type: "credentials",
    });

  const handleRemoveCertificate = async () => {
    const response = await deleteCredentials();

    if (response.data.status === "success") {
      toast.success("Credenciais removidas com sucesso");
      setOpen(false);
    } else {
      toast.error("Erro ao remover credenciais, contate o suporte.");
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild={true}>
        <Button variant="destructive">Remover credenciais</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Você tem certeza?</AlertDialogTitle>
          <AlertDialogDescription>
            <span className="text-red-600 font-bold block mb-2">
              Esta ação é irreversível.
            </span>
            Ao remover as credenciais, esta empresa não poderá emitir notas
            fiscais em prefeituras que dependem de usuário/senha.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancelar</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={handleRemoveCertificate}
            disabled={isPending}
          >
            {isPending ? "Apagando..." : "Apagar"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
