import { Separator } from "@/components/ui/separator.tsx";
import { FormServices } from "@/pages/companies/[document]/components/form-services.tsx";

export function CompanyServicesEdit() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Serviços</h3>
        <p className="text-sm text-muted-foreground">
          Abaixo você pode editar os serviços que sua empresa pode acessar no
          sistema
        </p>
      </div>
      <Separator />
      <FormServices />
    </div>
  );
}
