import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { columnsSent } from "@/pages/invoices/components/columns-sent.tsx";

export function BatchServiceInvoicesTable({
  data,
}: {
  data: Array<{
    id: string;
    status: string;
    type: "product" | "service";
    issuer: {
      document: string;
      name: string;
    };
    recipient: {
      document: string;
      name: string;
    };
    createdAt: string;
  }>;
}) {
  const table = useGenericTable({
    columns: columnsSent,
    data: data,
  });

  return (
    <div className="space-y-4 mt-4">
      <div className="rounded-md border">
        <DataTable table={table} />
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
