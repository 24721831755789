import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Check, ChevronsUpDown, Settings } from "lucide-react";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  itemSchema,
  type ProductInvoiceItem,
} from "@shared/schemas/invoices/product.ts";
import { useNfeStore } from "@/pages/invoices/product/store/useNfeStore.ts";
import { icmsOrigem, icmsTaxes, pisTaxes } from "@shared/constants/taxes.ts";
import {
  IcmsOrigem,
  ICMSTaxes,
  PISCOFINSTaxes,
} from "@shared/schemas/invoices/nfe/shared/items.ts";
import { cn } from "@/lib/utils.ts";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import { CurrencyInput } from "@/components/validators/currency-input.tsx";
import { popModal } from "@/modals";

export default function ProductItemCreate() {
  const { addProduct, nfeStore } = useNfeStore((state) => ({
    nfeStore: state.nfeData,
    addProduct: state.addItemSchema,
  }));

  const addForm = useForm<ProductInvoiceItem>({
    resolver: zodResolver(itemSchema.omit({ itemCode: true })),
    defaultValues: {
      productCode: "",
      description: "",
      cfop: "",
      ncmCode: "",
      includeInTotal: false,
      commercialUnit: "",
      commercialQuantity: 0,
      commercialUnitValue: 0,
      grossValue: 0,
      taxableUnit: "",
      taxableQuantity: 0,
      taxableUnitValue: 0,
      taxableValue: 0,
      icmsOrigin: IcmsOrigem.NACIONAL,
      icmsTaxSituation: ICMSTaxes.ISENTA,
      pisTaxSituation: PISCOFINSTaxes.OPERACAO_ISENTA_CONTRIBUICAO,
      cofinsTaxSituation: PISCOFINSTaxes.OPERACAO_ISENTA_CONTRIBUICAO,
    },
  });

  const grossValue =
    addForm.watch("commercialQuantity") * addForm.watch("commercialUnitValue");
  const taxableValue =
    addForm.watch("taxableQuantity") * addForm.watch("taxableUnitValue");

  const submitAddItem = addForm.handleSubmit((data) => {
    addProduct({
      ...data,
      itemCode: (nfeStore.items.length + 1).toString(),
    });
    toast.success(`Produto ${data.description} adicionado com sucesso!`);
    popModal("ProductItemCreate");
  });

  const formatSelectedValue = (
    value: number,
    item: { id: number; label: string }[]
  ) => {
    const itemFound = item.find((item) => item.id === value);

    if (!itemFound) {
      return "Selecione...";
    }

    const result = `${itemFound.id} - ${itemFound.label}`;
    return result.length > 15 ? `${result.slice(0, 15)}...` : result;
  };

  console.log(addForm.formState.errors);

  return (
    <DialogContent
      className="max-w-5xl overflow-y-auto max-h-[800px]"
      onOpenAutoFocus={(e) => e.preventDefault()}
    >
      <DialogHeader>
        <DialogTitle>Adicionar um Novo Produto</DialogTitle>
        <DialogDescription>
          Preencha os campos abaixo para adicionar um novo produto à nota
          fiscal, o produto será salvo no banco de dados para uso futuro. Para
          realizar a busca de um produto já existente, utilize o campo de busca
          e selecione o produto desejado.
        </DialogDescription>
      </DialogHeader>
      <Form {...addForm}>
        <form onSubmit={submitAddItem} className="space-y-4">
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-4 mt-5">
              Produto
              <Separator />
            </div>
            <FormField
              name="productCode"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Código do Produto</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="description"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Descrição</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="cfop"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>CFOP</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="ncmCode"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>NCM</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="includeInTotal"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Valor total da nota fiscal</FormLabel>
                  <div className="flex items-center space-x-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Incluir o valor do produto?</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="commercialUnit"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Unidade de Medida Comercial</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="commercialQuantity"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Quantidade Comercial</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="commercialUnitValue"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Preço Unitário Comercial</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"R$ 0,00"}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="grossValue"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Valor Bruto Comercial</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      disabled={true}
                      value={grossValue}
                      onChange={() => field.onChange(grossValue)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              name="taxableUnit"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Unidade de Medida Tributável</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="taxableQuantity"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Quantidade Tributável</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="taxableUnitValue"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Preço Unitário Tributável</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={"R$ 0,00"}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="taxableValue"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Valor Bruto Tributável</FormLabel>
                  <FormControl>
                    <CurrencyInput
                      disabled={true}
                      value={taxableValue}
                      onChange={() => field.onChange(taxableValue)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="col-span-4 mt-5">
              Tributos
              <Separator />
            </div>

            <FormField
              name="icmsOrigin"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Origem do ICMS</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Popover modal={true}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn("w-full justify-between")}
                            >
                              {formatSelectedValue(field.value, icmsOrigem)}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-[500px] w-full p-0">
                          <Command>
                            <CommandInput placeholder="Procure pelo código ou nome..." />
                            <CommandList>
                              <CommandEmpty>Nenhum resultado.</CommandEmpty>
                              <CommandGroup>
                                {icmsOrigem.map((item) => (
                                  <CommandItem
                                    key={item.id}
                                    onSelect={() => {
                                      addForm.setValue("icmsOrigin", item.id);
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        item.id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {item.id} - {item.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <Button variant="ghost" size="sm" disabled={true}>
                        <Settings size={20} className="cursor-pointer" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="icmsTaxSituation"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Situação Tributária do ICMS</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Popover modal={true}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn("w-full justify-between")}
                            >
                              {formatSelectedValue(field.value, icmsTaxes)}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-[500px] w-full p-0">
                          <Command>
                            <CommandInput placeholder="Procure pelo código ou nome..." />
                            <CommandList>
                              <CommandEmpty>Nenhum resultado.</CommandEmpty>
                              <CommandGroup>
                                {icmsTaxes.map((item) => (
                                  <CommandItem
                                    key={item.id}
                                    onSelect={() => {
                                      addForm.setValue(
                                        "icmsTaxSituation",
                                        item.id
                                      );
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        item.id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {item.id} - {item.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <Button variant="ghost" size="sm" disabled={true}>
                        <Settings size={20} className="cursor-pointer" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="pisTaxSituation"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Situação Tributária do PIS</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Popover modal={true}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn("w-full justify-between")}
                            >
                              {formatSelectedValue(field.value, pisTaxes)}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-[500px] w-full p-0">
                          <Command>
                            <CommandInput placeholder="Procure pelo código ou nome..." />
                            <CommandList>
                              <CommandEmpty>Nenhum resultado.</CommandEmpty>
                              <CommandGroup>
                                {pisTaxes.map((item) => (
                                  <CommandItem
                                    key={item.id}
                                    onSelect={() => {
                                      addForm.setValue(
                                        "pisTaxSituation",
                                        item.id
                                      );
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        item.id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {item.id} - {item.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <Button variant="ghost" size="sm" disabled={true}>
                        <Settings size={20} className="cursor-pointer" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="cofinsTaxSituation"
              render={({ field }) => (
                <FormItem className="col-span-1">
                  <FormLabel>Situação Tributária do COFINS</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Popover modal={true}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn("w-full justify-between")}
                            >
                              {formatSelectedValue(field.value, pisTaxes)}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-[500px] w-full p-0">
                          <Command>
                            <CommandInput placeholder="Procure pelo código ou nome..." />
                            <CommandList>
                              <CommandEmpty>Nenhum resultado.</CommandEmpty>
                              <CommandGroup>
                                {pisTaxes.map((item) => (
                                  <CommandItem
                                    key={item.id}
                                    onSelect={() => {
                                      addForm.setValue(
                                        "cofinsTaxSituation",
                                        item.id
                                      );
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        item.id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {item.id} - {item.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <Button variant="ghost" size="sm" disabled={true}>
                        <Settings size={20} className="cursor-pointer" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button>Adicionar</Button>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
}
