import { z } from "zod";

export const nfseTaxes = z
  .object({
    irpj: z.string(),
    csll: z.string(),
    cofins: z.string(),
    pis: z.string(),
    inss: z.string(),
    iss: z.string(),
    others: z.string(),
  })
  .partial();

export type NfseTaxes = z.infer<typeof nfseTaxes>;
