import { create } from "zustand";
import type { companySchema} from "@shared/schemas/company.ts";
import { RegimeTributary } from "@shared/schemas/company.ts";
import type { z } from "zod";
import type { addressSchema } from "@shared/schemas/common.ts";

type State = {
  basicInfo: z.infer<typeof companySchema>;
  address: z.infer<typeof addressSchema>;
  step: "basicInfo" | "tributes" | "address" | "finish";
};

type Action = {
  setBasicInfo: (basicInfo: z.infer<typeof companySchema>) => void;
  setAddress: (address: z.infer<typeof addressSchema>) => void;
  setStep: (step: State["step"]) => void;
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
};

const useCreateCompanyStore = create<State & Action>((set) => ({
  basicInfo: {
    name: "",
    fantasyName: "",
    email: "",
    phone: "",
    document: "",
    services: [],
    municipalRegistration: "",
    stateRegistration: "",
    tributaryRegime: RegimeTributary.REGIME_NORMAL,
  },
  address: {
    street: "",
    number: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    complement: "",
    cityCode: "",
  },
  step: "basicInfo",
  setBasicInfo: (basicInfo) => set({ basicInfo }),
  setAddress: (address) => set({ address }),
  setStep: (step) => set({ step }),
  nextStep: () =>
    set((state) => {
      if (state.step === "basicInfo") {
        return { step: "tributes" };
      }
      if (state.step === "tributes") {
        return { step: "address" };
      }
      if (state.step === "address") {
        return { step: "finish" };
      }

      return state;
    }),
  prevStep: () =>
    set((state) => {
      if (state.step === "tributes") {
        return { step: "basicInfo" };
      }
      if (state.step === "address") {
        return { step: "tributes" };
      }
      if (state.step === "finish") {
        return { step: "address" };
      }

      return state;
    }),
  reset: () =>
    set({
      basicInfo: {
        name: "",
        fantasyName: "",
        email: "",
        phone: "",
        document: "",
        municipalRegistration: "",
        services: [],
        stateRegistration: "",
        tributaryRegime: RegimeTributary.REGIME_NORMAL,
      },
      address: {
        street: "",
        number: "",
        district: "",
        city: "",
        state: "",
        zipCode: "",
        complement: "",
        cityCode: "",
      },
      step: "basicInfo",
    }),
}));

export { useCreateCompanyStore };
