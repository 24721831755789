import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { AlertTriangle, Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { popModal } from "@/modals";
import { fetchApi } from "@/lib/api.ts";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

interface Props {
  id: string;
}

const formSchema = z.object({
  reason: z
    .string()
    .min(15, "Justificativa é obrigatória e deve ter no mínimo 15 caracteres")
    .max(255, "A justificativa excedeu o limite de 255 caracteres"),
});

export default function CancelSentProductInvoice({ id }: Props) {
  const { mutate, isPending } = useMutation({
    mutationKey: [`/invoices/sent/product/cancel`],
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      return fetchApi(`/invoices/sent/products/${id}`, {
        method: "DELETE",
        data,
      });
    },
    onSuccess: () => {
      popModal("CancelSentProductInvoice");
      toast.success("Recebemos o pedido de cancelamento da nota fiscal.");
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: "",
    },
  });

  const submit = form.handleSubmit((data) => {
    mutate(data);
  });

  return (
    <>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className={"text-xl"}>
            Cancelamento de Nota Fiscal Emitida
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <AlertTriangle
            className={"size-4 text-destructive inline-flex mr-2"}
          />
          Você tem certeza que deseja cancelar esta nota?
        </DialogDescription>
        <Form {...form}>
          <form onSubmit={submit}>
            <FormField
              name="reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Justificativa</FormLabel>
                  <FormControl>
                    <Textarea {...field} className={"resize-none"} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className={"flex justify-end mt-4 space-x-2"}>
              <Button
                onClick={() => popModal("CancelSentProductInvoice")}
                type={"button"}
                variant={"secondary"}
                disabled={isPending}
              >
                Voltar
              </Button>
              <Button variant={"destructive"} disabled={isPending}>
                {isPending && <Loader2 className={"animate-spin mr-2"} />}
                Transmitir
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </>
  );
}
