import { Button } from "@/components/ui/button.tsx";
import type { inputVendorSchema } from "@shared/schemas/vendor.ts";
import type { z } from "zod";
import { Info, Loader2 } from "lucide-react";
import { popModal } from "@/modals";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";

interface Props {
  data: z.infer<typeof inputVendorSchema> | undefined;
  vendorActive: boolean;
}

export default function EditVendorStatusConfirmationDialog({
  data,
  vendorActive,
}: Props) {
  const queryClient = useQueryClient();

  const { mutate: deleteMutate, isPending: deleteIsPending } = useMutation({
    mutationKey: [`/vendors/${data?.document}`],
    mutationFn: (data: z.infer<typeof inputVendorSchema>) =>
      fetchApi(`/vendors/${data.document}`, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/vendors/${data?.document}`],
      });
      toast.success("Fornecedor marcado como inativo com sucesso!");
      popModal("EditVendorStatusConfirmationDialog");
    },
    onError: () => {
      popModal("EditVendorStatusConfirmationDialog");
    },
  });

  const { mutate: activeMutation, isPending: activeIsPending } = useMutation({
    mutationKey: [`/vendors/${data?.document}`],
    mutationFn: (data: z.infer<typeof inputVendorSchema>) =>
      fetchApi(`/vendors/${data.document}`, {
        method: "PATCH",
        data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/vendors/${data?.document}`],
      });
      toast.success("Fornecedor marcado como ativo com sucesso!");
      popModal("EditVendorStatusConfirmationDialog");
    },
    onError: () => {
      popModal("EditVendorStatusConfirmationDialog");
    },
  });

  if (!data) {
    return null;
  }

  const isPending = deleteIsPending || activeIsPending;

  return (
    <>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Deseja {vendorActive ? "inativar" : "reativar"} o fornecedor{" "}
            {data.name}?
          </DialogTitle>
        </DialogHeader>
        <div>
          <p className={"font-medium text-sm"}>
            O fornecedor ficará como {vendorActive ? "inativo" : "ativo"}.
          </p>
          {vendorActive && (
            <DialogDescription>
              <Info className={"inline-block size-4 mr-2 text-blue-500"} />
              <span className={"text-xs"}>
                Para reativar o fornecedor, refaça o processo clicando no ícone
                ao lado dos Dados Cadastrais.
              </span>
            </DialogDescription>
          )}
        </div>
        <DialogFooter>
          <Button
            type={"button"}
            onClick={() => popModal("EditVendorStatusConfirmationDialog")}
            variant={"outline"}
            disabled={isPending}
          >
            Cancelar
          </Button>
          <Button
            variant={"default"}
            disabled={isPending}
            onClick={() =>
              data.status === "active"
                ? deleteMutate(data)
                : activeMutation(data)
            }
          >
            {isPending ? <Loader2 className={"animate-spin"} /> : "Confirmar"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </>
  );
}
