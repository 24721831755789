import { Outlet } from "react-router-dom";
import { Separator } from "@/components/ui/separator.tsx";

export function InvoicesImportLayout() {
  return (
    <div className="space-y-6 px-10 pt-5 pb-16">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">
          Emissão de documentos fiscais em lote
        </h2>
        <p className="text-muted-foreground">
          Faça a emissão de documentos fiscais de maneira prática e eficiente. O
          modelo de emissão de documentos fiscais é baseado em lotes, o que
          significa que você pode enviar vários documentos fiscais ao mesmo
          tempo, reduzindo o tempo de processamento.
        </p>
      </div>
      <Separator className="my-6" />
      <Outlet />
    </div>
  );
}
