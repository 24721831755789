import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

export function BatchBack() {
  return (
    <div className={"flex text-muted-foreground text-sm mb-5"}>
      <Link to={"/invoices/batches"} className={"flex items-center gap-x-2"}>
        <ArrowLeft className={"size-5"} />
        <span>Voltar para a lista de lotes</span>
      </Link>
    </div>
  );
}
