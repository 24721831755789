import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useQuery } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";

export function CityStatus() {
  const { data } = useQuery<{
    count: {
      total: number;
      nfse: number;
      nfce: number;
      nfe: number;
    };
    states: {
      state: string;
      stateCode: string;
      total: number;
      nfse: number;
      nfce: number;
      nfe: number;
    }[];
  }>({
    queryKey: ["/status/states"],
    staleTime: Infinity,
  });

  const sorttedData = data?.states?.sort((a, b) => {
    return a.state.localeCompare(b.state);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Status dos Municípios</CardTitle>
        <div className="text-sm text-muted-foreground flex flex-col items-start justify-center">
          <span>Conexão com a API e disponibilidade dos serviços.</span>
          <div className="flex flex-row flex-wrap justify-center md:justify-start gap-2 mt-2">
            {data && (
              <>
                <Badge>{data?.count.total} municípios com NFE</Badge>
                <Badge>{data?.count.nfse} municípios com NFS-e</Badge>
              </>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent className="relative">
        {!data && (
          <div className="absolute inset-0 flex justify-center items-center">
            <Loader2 className="w-8 h-8 animate-spin" />
          </div>
        )}
        <ScrollArea>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 p-4 max-h-[500px] text-sm items-center">
            {sorttedData?.map((state) => (
              <Card
                className="flex flex-col"
                key={`${state.state}-${state.stateCode}`}
              >
                <CardHeader>
                  <CardTitle className="gap-2 flex justify-center items-center text-lg">
                    <Badge className="hidden lg:block">{state.stateCode}</Badge>
                    <span className="text-pretty text-center">
                      {state.state}
                    </span>
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-row justify-center items-center gap-2 flex-1">
                  <Badge className="flex gap-2 text-sm" variant="outline">
                    <span className="font-bold">NFE: </span>
                    {state.nfe ?? 0}
                  </Badge>
                  <div className="h-1 w-1 rounded-full bg-black" />
                  <Badge className="flex gap-2 text-sm" variant="outline">
                    <span className="font-bold">NFS: </span>
                    {state.nfse ?? 0}
                  </Badge>
                </CardContent>
              </Card>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
