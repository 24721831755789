import type { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "@/components/table/data-table-column-header";
import { formatCNPJ } from "@shared/format.ts";
import { Button } from "@/components/ui/button.tsx";
import { Link } from "react-router-dom";
import { EditIcon } from "lucide-react";

export const vendorsTableColumns: ColumnDef<{
  id: string;
  name: string;
  document: string;
}>[] = [
  {
    accessorKey: "document",
    enableSorting: false,
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader title="CNPJ" column={column} />
    ),
    cell: ({ row, column }) => <div>{formatCNPJ(row.getValue(column.id))}</div>,
  },
  {
    accessorKey: "name",
    enableHiding: false,
    header: ({ column }) => (
      <DataTableColumnHeader
        className="w-[400px]"
        column={column}
        title="Razão Social"
      />
    ),
    cell: ({ row }) => {
      return (
        <span className="w-[200px] truncate font-medium">
          {row.getValue("name")}
        </span>
      );
    },
  },
  {
    id: "actions",
    enableSorting: false,
    enableHiding: false,
    header: () => (
      <div className="w-12">
        <span className="sr-only">Ações</span>
      </div>
    ),
    cell: ({ row }) => (
      <Button variant="pop" size="icon" asChild={true}>
        <Link to={`/vendors/${row.original.document}/edit`}>
          <EditIcon size={16} />
        </Link>
      </Button>
    ),
  },
];
