import { PatternFormat } from "react-number-format";
import type { ComponentPropsWithoutRef } from "react";
import { Input } from "@/components/ui/input.tsx";

interface Props
  extends Omit<
    ComponentPropsWithoutRef<"input">,
    "value" | "onChange" | "type" | "defaultValue"
  > {
  value: string | number | null;
  onChange: (value: string) => void;
  format: "CPF" | "CNPJ";
}

const documentFormats = {
  CPF: "###.###.###-##",
  CNPJ: "##.###.###/####-##",
} as const;

export function DocumentInput({ value, onChange, format, ...props }: Props) {
  if (!["CPF", "CNPJ"].includes(format)) {
    throw new Error("Invalid input format");
  }

  return (
    <PatternFormat
      format={documentFormats[format]}
      mask={"_"}
      onValueChange={(value) => onChange(value.value || "")}
      customInput={Input}
      value={value || ""}
      type="text"
      {...props}
    />
  );
}
