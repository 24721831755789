import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { FilePlus, FileScan, FileX } from "lucide-react";

import { CityStatus } from "@/pages/overview/city-status.tsx";

function Index() {
  return (
    <div className="flex flex-col gap-2">
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-8">
        <div className="flex flex-col col-span-6 space-y-2">
          <div className="flex flex-col md:flex-row gap-2 w-full">
            <Card className="w-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Notas emitidas
                </CardTitle>
                <FileScan className="size-6 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">+10</div>
                <p className="text-xs text-muted-foreground">
                  40 notas emitidas no total
                </p>
              </CardContent>
            </Card>
            <Card className="w-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Notas concluídas
                </CardTitle>
                <FilePlus className="size-6 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">+50</div>
                <p className="text-xs text-muted-foreground">
                  50 notas concluídas na plataforma
                </p>
              </CardContent>
            </Card>
            <Card className="w-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Notas canceladas
                </CardTitle>
                <FileX />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">5%</div>
                <p className="text-xs text-muted-foreground">
                  Aumento de 4% em relação ao mês passado
                </p>
              </CardContent>
            </Card>
          </div>
          <CityStatus />
        </div>
        <Card className="relative col-span-2">
          <CardHeader>
            <CardTitle>Documentos recentes</CardTitle>
            <CardDescription>
              Documentos recentes emitidos na plataforma
            </CardDescription>
          </CardHeader>
          <CardContent>Em breve</CardContent>
        </Card>
      </div>
    </div>
  );
}

export { Index };
