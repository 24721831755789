import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { columnsSent } from "./components/columns-sent.tsx";
import { InvoiceOptions } from "@/pages/invoices/components/invoice-options.tsx";
import { useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { DataTableToolbar } from "@/components/table/data-table-toolbar.tsx";
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";

export function IssuedDocuments() {
  const { data: invoice } = useQuery<{
    invoices: Array<{
      id: string;
      status: string;
      type: "product" | "service";
      issuer: {
        document: string;
        name: string;
      };
      recipient: {
        document: string;
        name: string;
      };
      createdAt: string;
    }>;
  }>({
    queryKey: ["/invoices?type=sent"],
    refetchInterval: 15000,
  });

  const table = useGenericTable({
    columns: columnsSent,
    data: invoice?.invoices,
  });

  return (
    <>
      <InvoiceOptions />
      <div className="space-y-4">
        <DataTableToolbar table={table}>
          {table.getColumn("type") && (
            <DataTableFacetedFilter
              column={table.getColumn("type")}
              title="Tipo da Nota"
              options={[
                {
                  label: "Serviço",
                  value: "service",
                },
                {
                  label: "Produto",
                  value: "product",
                },
              ]}
            />
          )}
          <DataTableSearch
            placeholder="Emitente"
            column={table.getColumn("issuer")!}
          />
          <DataTableSearch
            placeholder="Tomador"
            column={table.getColumn("recipient")!}
          />
        </DataTableToolbar>
        <div className="rounded-md border">
          <DataTable table={table} />
        </div>
        <DataTablePagination table={table} />
      </div>
    </>
  );
}
