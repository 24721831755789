import { create } from "zustand";
import type { z } from "zod";
import {
  NfeDocumentDestination,
  NfeDocumentPaymentType,
  NfeDocumentType,
  NfeOperationType,
} from "@shared/schemas/invoices/nfe/shared/general.ts";
import { RegimeTributary } from "@shared/schemas/company.ts";
import type {
  CreateProductInvoice,
  ProductInvoiceItem,
  productInvoiceSchema,
} from "@shared/schemas/invoices/product.ts";
import { RecipientStateInscriptionType } from "@shared/schemas/invoices/nfe/focus";

type State = {
  nfeData: z.infer<typeof productInvoiceSchema>;
};

type Action = {
  setInvoiceSchema: (schema: CreateProductInvoice["invoice"]) => void;
  setIssuerSchema: (schema: CreateProductInvoice["issuer"]) => void;
  setRecipientSchema: (schema: CreateProductInvoice["recipient"]) => void;
  addItemSchema: (schema: ProductInvoiceItem) => void;
  removeItemSchema: (schema: ProductInvoiceItem) => void;
  setValuesSchema: (schema: CreateProductInvoice["values"]) => void;
  reset: () => void;
};

const defaultValues = {
  invoice: {
    documentType: NfeDocumentType.NOTA_FISCAL_SAIDA,
    destination: NfeDocumentDestination.OPERACAO_INTERNA,
    paymentType: NfeDocumentPaymentType.NAO_SE_APLICA,
    operationNature: "",
    issuedAt: new Date().toISOString(),
    issueReason: NfeOperationType.NORMAL,
    endConsumer: true,
  } satisfies CreateProductInvoice["invoice"],
  issuer: {
    name: "",
    document: "",
    documentType: "CNPJ" as const,
    fantasyName: "",
    address: {
      street: "",
      number: "",
      district: "",
      city: "",
      state: "",
      zipCode: "",
      country: "Brasil",
    },
    stateRegistration: "",
    tributaryRegime: RegimeTributary.REGIME_NORMAL,
  },
  recipient: {
    name: "",
    document: "",
    documentType: "CNPJ" as const,
    phone: "",
    email: "",
    address: {
      street: "",
      number: "",
      district: "",
      city: "",
      state: "",
      zipCode: "",
      country: "Brasil",
    },
    stateRegistration: "",
    stateRegistrationType: RecipientStateInscriptionType.NAO_CONTRIBUINTE,
  },
  items: [],
  values: {
    freight: "",
    freightType: 9,
    insurance: "",
    total: "",
    totalProducts: "",
    discount: "",
  },
};

const useNfeStore = create<State & Action>((set) => ({
  nfeData: {
    ...defaultValues,
  },
  setInvoiceSchema: (schema) =>
    set((state) => ({
      nfeData: { ...state.nfeData, invoice: schema },
    })),
  setIssuerSchema: (schema) =>
    set((state) => ({
      nfeData: { ...state.nfeData, issuer: schema },
    })),
  setRecipientSchema: (schema) =>
    set((state) => ({
      nfeData: { ...state.nfeData, recipient: schema },
    })),
  addItemSchema: (schema) => {
    set((state) => ({
      nfeData: {
        ...state.nfeData,
        items: [...state.nfeData.items, schema],
      },
    }));
    return schema;
  },
  removeItemSchema: (schema) =>
    set((state) => ({
      nfeData: {
        ...state.nfeData,
        items: state.nfeData.items.filter((item) => item !== schema),
      },
    })),
  setValuesSchema: (schema) =>
    set((state) => ({
      nfeData: { ...state.nfeData, values: schema },
    })),
  reset: () =>
    set({
      nfeData: {
        ...defaultValues,
      },
    }),
}));

export { useNfeStore };
