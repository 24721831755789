import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import type { UseFormReturn } from "react-hook-form";
import type { z } from "zod";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import { useExternalCity } from "@/hooks/use-external-city.ts";
import {
  CurrencyInput,
  PercentageInput,
} from "@/components/validators/currency-input.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { Autocomplete } from "@/components/autocomplete.tsx";

export function ServicesForm({
  form,
  companies,
}: {
  form: UseFormReturn<z.infer<typeof serviceInvoiceSchema>>;
  companies?: CompanyWithAddress[];
}) {
  const cityCode = companies?.find(
    (company) => company.document === form.getValues("issuer.document")
  )?.address.cityCode;

  const { data: externalCity } = useExternalCity({
    cityCode: cityCode ?? "",
    includeServiceList: true,
  });

  const serviceList = () => {
    if (!externalCity) {
      return [];
    }

    return externalCity.serviceList.map((service) => ({
      label: service.description,
      value: service.code,
    }));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Discriminação dos serviços</CardTitle>
        <CardContent>
          <div className="grid grid-cols-5 gap-x-4 gap-y-2 mt-2">
            <FormField
              name="items.0.item"
              render={({ field }) => (
                <FormItem className="flex flex-col col-span-5">
                  <FormLabel>Informe o serviço</FormLabel>
                  <Autocomplete
                    options={serviceList()}
                    onValueChange={(option) => {
                      console.log(option.value);
                      field.onChange(option.value);
                    }}
                    emptyMessage={
                      "Serviço não encontrado. Iremos levar em consideração o que você informar."
                    }
                    setInput={true}
                  />
                </FormItem>
              )}
            />
            <FormField
              name="items.0.description"
              render={({ field }) => (
                <FormItem className="col-span-5">
                  <FormLabel>Descrição do serviço</FormLabel>
                  <FormControl>
                    <Textarea {...field} className="resize-none" />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="items.0.issRetained"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Retenção do ISS na Fonte?</FormLabel>
                  <Select
                    onValueChange={(value) => field.onChange(value === "1")}
                    defaultValue={field.value}
                    value={field.value ? "1" : "0"}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione (Sim ou Não)" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">Sim</SelectItem>
                      <SelectItem value="0">Não</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              name="items.0.aliquot"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Aliquota</FormLabel>
                  <FormControl>
                    <PercentageInput {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="items.0.value"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Valor do serviço</FormLabel>
                  <FormControl>
                    <CurrencyInput {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </CardContent>
      </CardHeader>
    </Card>
  );
}
