import { MobileNavbar } from "@/components/layout/navbar/mobile-navbar.tsx";
import { DesktopNavbar } from "@/components/layout/navbar/desktop-navbar.tsx";

export function Header() {
  return (
    <>
      <MobileNavbar />
      <DesktopNavbar />
    </>
  );
}
