import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import type { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { AlertTriangle, Check } from "lucide-react";
import { formatCurrency } from "@/lib/currency.ts";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { format } from "date-fns";

interface ProcessedDataTableColumn {
  status: "success" | "error";
  invoice: CreateServiceInvoice;
  errors?: string[];
}

export const processedNfseColumns: ColumnDef<ProcessedDataTableColumn>[] = [
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {row.getValue("status") === "success" ? (
            <Check className="ml-2 size-5 text-green-500" />
          ) : (
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <AlertTriangle className="ml-2 size-5 text-orange-500 hover:cursor-pointer" />
                </TooltipTrigger>
                <TooltipContent
                  className="min-w-fit"
                  side={"top"}
                  align={"start"}
                >
                  {!!row.original.errors && (
                    <>
                      <p className="text-orange-500 font-semibold text-lg">
                        {row.original.errors.length}{" "}
                        {row.original.errors.length != 1 ? "campos" : "campo"}{" "}
                        {row.original.errors.length != 1
                          ? "necessitam de ser corrigidos"
                          : "necessita de ser corrigido"}
                      </p>
                      <ul className="list-disc ml-4 mt-2">
                        {row.original.errors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                      <p className="mt-2 text-xs">
                        Por favor, corrija os dados e envie o arquivo novamente.
                      </p>
                    </>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.issuedAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data de Emissão" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{format(row.original.invoice.issuedAt, "dd/MM/yyyy")}</div>;
    },
  },
  {
    accessorKey: "invoice.issuer.document",
    header: ({ column }) => (
      <DataTableColumnHeader title="CNPJ Emissor" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.issuer.document}</div>;
    },
  },

  {
    accessorKey: "invoice.recipient.document",
    header: ({ column }) => (
      <DataTableColumnHeader title="CPF/CNPJ" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.document}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.companyName",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[300px]"}
        title="Razão Social"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.companyName}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.street",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[300px]"}
        title="Logradouro"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.street}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.number",
    header: ({ column }) => (
      <DataTableColumnHeader title="Numero" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.number}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.complement",
    header: ({ column }) => (
      <DataTableColumnHeader title="Complemento" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.complement}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.district",
    header: ({ column }) => (
      <DataTableColumnHeader title="Bairro" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.district}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.cityCode",
    header: ({ column }) => (
      <DataTableColumnHeader title="Código do Município" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.cityCode}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.zipCode",
    header: ({ column }) => (
      <DataTableColumnHeader title="CEP" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.zipCode}</div>;
    },
  },

  {
    accessorKey: "invoice.recipient.email",
    header: ({ column }) => (
      <DataTableColumnHeader title="Email" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.email}</div>;
    },
  },

  {
    accessorKey: "invoice.items[0].item",
    header: ({ column }) => (
      <DataTableColumnHeader title="Item Lista Serviço" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.items[0].item}</div>;
    },
  },
  {
    accessorKey: "invoice.items[0].description",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[300px]"}
        title="Descrição"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.items[0].description}</div>;
    },
  },
  {
    accessorKey: "invoice.items[0].municipalTaxCode",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Código Tributário Municipio"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.items[0].municipalTaxCode}</div>;
    },
  },
  {
    accessorKey: "invoice.items[0].aliquot",
    header: ({ column }) => (
      <DataTableColumnHeader title="Alíquota (%)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{row.original.invoice.items[0].aliquot.toFixed(2) + "%"}</div>
      );
    },
  },
  {
    accessorKey: "invoice.items[0].issRetained",
    header: ({ column }) => (
      <DataTableColumnHeader title="Retenção ISS" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{row.original.invoice.items[0].issRetained ? "Sim" : "Não"}</div>
      );
    },
  },
  {
    accessorKey: "invoice.items[0].value",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor dos Serviços (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.items[0].value, "BRL")}</div>
      );
    },
  },

  {
    accessorKey: "invoice.totals.inss",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor INSS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.totals.inss, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.totals.pis",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor PIS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.totals.pis, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.totals.cofins",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor COFINS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.totals.cofins, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.totals.csll",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor CSLL (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.totals.csll, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.totals.ir",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor IR (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{formatCurrency(row.original.invoice.totals.ir, "BRL")}</div>;
    },
  },
  {
    accessorKey: "invoice.totals.deductions",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor Deduções (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(row.original.invoice.totals.deductions, "BRL")}
        </div>
      );
    },
  },
];
