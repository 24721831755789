import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { NfeInvoiceBasic } from "@/pages/invoices/product/components/invoice-basic-info.tsx";
import { NfeIssuer } from "@/pages/invoices/product/components/invoice-issuer.tsx";
import { NfeRecipient } from "@/pages/invoices/product/components/invoice-recipient.tsx";
import { NfeItems } from "@/pages/invoices/product/components/invoice-products.tsx";
import { NfeReview } from "@/pages/invoices/product/components/invoice-review.tsx";

export function CreateProductInvoice() {
  return (
    <>
      <div className="space-y-6 px-10 pt-5 pb-16">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight ">
            Nova Nota Fiscal de Produto
          </h2>
          <p className="text-muted-foreground">
            Preencha os campos abaixo para criar uma nova nota fiscal de produto
          </p>
          <Separator />
        </div>

        <Tabs defaultValue="basic">
          <TabsList className="flex">
            <TabsTrigger value="basic">Informações Básicas</TabsTrigger>
            <TabsTrigger value="issuer">Emitente</TabsTrigger>
            <TabsTrigger value="recipient">Destinatário</TabsTrigger>
            <TabsTrigger value="items">Produtos</TabsTrigger>
            <TabsTrigger value="transport" disabled={true}>
              Transporte
            </TabsTrigger>
            <TabsTrigger value="charge" disabled={true}>
              Cobrança
            </TabsTrigger>
            <TabsTrigger value="additional" disabled={true}>
              Dados Adicionais
            </TabsTrigger>
            <TabsTrigger value="validation">
              Validação e Transmissão
            </TabsTrigger>
          </TabsList>

          <TabsContent value="basic">
            <NfeInvoiceBasic />
          </TabsContent>
          <TabsContent value="issuer">
            <NfeIssuer />
          </TabsContent>
          <TabsContent value="recipient">
            <NfeRecipient />
          </TabsContent>
          <TabsContent value="items">
            <NfeItems />
          </TabsContent>
          <TabsContent value="validation">
            <NfeReview />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
