import { Outlet } from "react-router-dom";
import { Header } from "@/components/header.tsx";
import { useTitle } from "@/hooks/use-title.ts";

export function Layout() {
  useTitle("Sistema");

  return (
    <main className="relative flex min-h-screen w-full flex-col overflow-hidden">
      <Header />
      <div className="container py-5 flex-1 relative h-full flex-col items-center justify-center">
        <Outlet />
      </div>
    </main>
  );
}
