import { Button } from "@/components/ui/button.tsx";
import { Bell, Dot } from "lucide-react";

export function NotificationNav() {
  return (
    <div className="relative">
      <Button size={"icon"} variant={"ghost"} className={"rounded-full"}>
        <Dot className={"absolute right-0 top-0 text-destructive"} />
        <Bell className={"size-5 text-muted-foreground"} />
      </Button>
    </div>
  );
}
