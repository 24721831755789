import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AuthLayout } from "@/pages/auth/_layout.tsx";
import { Layout } from "@/pages/_layout.tsx";
import { UserAuthForm } from "@/pages/auth/login.tsx";
import { AuthCodeUserForm } from "@/pages/auth/code.tsx";
import { Index } from "@/pages";
import { CreateCompany } from "@/pages/companies/create";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { CompaniesView } from "@/pages/companies";
import { CompanyEditLayout } from "@/pages/companies/[document]/_layout.tsx";
import { CompanyBasicInfoEdit } from "@/pages/companies/[document]/edit.tsx";
import { CompanyTaxesEdit } from "@/pages/companies/[document]/taxes.tsx";
import { CompanyServicesEdit } from "@/pages/companies/[document]/services.tsx";
import { CompanySettingsEdit } from "@/pages/companies/[document]/settings.tsx";
import { CreateProductInvoice } from "@/pages/invoices/product/create.tsx";
import { IssuedDocuments } from "@/pages/invoices/sent.tsx";
import { ImportNfe } from "@/pages/invoices/import/nfe.tsx";
import { ImportServiceDocument } from "@/pages/invoices/import/nfse.tsx";
import { InvoicesImportLayout } from "@/pages/invoices/import/_layout.tsx";
import { CreateServiceInvoice } from "@/pages/invoices/service/create.tsx";
import { setDefaultOptions } from "date-fns";
import { ptBR } from "date-fns/locale";
import { toast } from "sonner";
import { Users } from "@/pages/users";
import { ReceivedDocuments } from "@/pages/invoices/received.tsx";
import { ViewVendors } from "@/pages/vendors";
import { CreateNewVendor } from "@/pages/vendors/new";
import { VendorEditLayout } from "@/pages/vendors/[document]/_layout.tsx";
import { VendorEdit } from "@/pages/vendors/[document]/edit.tsx";
import { VendorHistory } from "@/pages/vendors/[document]/history.tsx";
import { ModalProvider } from "@/modals";
import { isAxiosError } from "axios";
import { BaseLayout } from "@/components/layout/base-layout.tsx";
import { InvoicesBatchesLayout } from "@/pages/invoices/batches/_layout.tsx";
import { Batches } from "@/pages/invoices/batches";
import { BatchView } from "@/pages/invoices/batches/[batchId]";

setDefaultOptions({
  locale: ptBR,
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BaseLayout />}>
      <Route path="/auth">
        <Route element={<AuthLayout />}>
          <Route path="login" element={<UserAuthForm />} />
          <Route path="code" element={<AuthCodeUserForm />} />
        </Route>
      </Route>
      <Route element={<Layout />}>
        <Route path="/" index={true} element={<Index />} />
        <Route path="/invoices">
          <Route path="sent">
            <Route index={true} element={<IssuedDocuments />} />
          </Route>
          <Route path="received">
            <Route index={true} element={<ReceivedDocuments />} />
          </Route>
          <Route path="new">
            <Route path="product" element={<CreateProductInvoice />} />
            <Route path="service" element={<CreateServiceInvoice />} />
          </Route>
          <Route path="import" element={<InvoicesImportLayout />}>
            <Route path="product" element={<ImportNfe />} />
            <Route path="service" element={<ImportServiceDocument />} />
          </Route>
          <Route path="batches" element={<InvoicesBatchesLayout />}>
            <Route index={true} element={<Batches />} />
            <Route path=":batchId" element={<BatchView />} />
          </Route>
        </Route>
        <Route path="/companies">
          <Route index={true} element={<CompaniesView />} />
          <Route path="create" element={<CreateCompany />} />
          <Route path=":document" element={<CompanyEditLayout />}>
            <Route path="edit" element={<CompanyBasicInfoEdit />} />
            <Route path="taxes" element={<CompanyTaxesEdit />} />
            <Route path="services" element={<CompanyServicesEdit />} />
            <Route path="settings" element={<CompanySettingsEdit />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
        <Route path="/vendors">
          <Route index={true} element={<ViewVendors />} />
          <Route path="new" element={<CreateNewVendor />} />
          <Route path=":document" element={<VendorEditLayout />}>
            <Route path="edit" element={<VendorEdit />} />
            <Route path="history" element={<VendorHistory />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
        <Route path="/users" element={<Users />} />
      </Route>
    </Route>
  )
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey }) =>
        fetchApi(`${queryKey[0]}`).then((res) => res.data.data),
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response) {
          if ([404, 401].includes(error.response.status)) {
            return false;
          }
        }

        return failureCount < 3;
      },
    },
    mutations: {
      onSuccess: () => {
        toast.success("Operação realizada com sucesso.", {
          position: "bottom-right",
        });
      },
      onError: () => {
        toast.error("Erro ao realizar operação.", {
          position: "bottom-right",
          className: "bg-red-500",
        });
      },
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ModalProvider />
      <ReactQueryDevtools
        initialIsOpen={true}
        position="bottom"
        buttonPosition="bottom-right"
      />
    </QueryClientProvider>
  );
}
