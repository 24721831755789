import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { InfoIcon } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { useNfeStore } from "@/pages/invoices/product/store/useNfeStore.ts";
import { toast } from "sonner";
import { useVendors } from "@/hooks/use-vendors.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api";
import type { Vendor } from "@shared/schemas/vendor.ts";
import {
  type CreateProductInvoice,
  productInvoiceSchema,
} from "@shared/schemas/invoices/product.ts";

const vendorSelectSchema = z.object({
  vendor: z.string(),
});

export function NfeRecipient() {
  const { data } = useVendors();
  const { mutateAsync } = useMutation({
    mutationKey: ["/vendors"],
    mutationFn: async (data: z.infer<typeof vendorSelectSchema>) => {
      const res = await fetchApi<{ data: Vendor }>(`/vendors/${data.vendor}`, {
        method: "GET",
      });
      return res.data.data;
    },
  });

  const { recipient, setRecipient } = useNfeStore((state) => ({
    recipient: state.nfeData.recipient,
    setRecipient: state.setRecipientSchema,
  }));

  const form = useForm<CreateProductInvoice["recipient"]>({
    resolver: zodResolver(productInvoiceSchema.shape.recipient),
    defaultValues: {
      ...recipient,
    },
  });

  const vendorSelectForm = useForm<z.infer<typeof vendorSelectSchema>>({
    resolver: zodResolver(vendorSelectSchema),
    defaultValues: {
      vendor: "",
    },
  });

  const submit = form.handleSubmit((data) => {
    setRecipient(data);
    toast.success("As informações do Destinatário foram salvas com sucesso!");
  });

  const updateFields = async (vendor: string) => {
    const vendorDocument = data?.vendors.find((v) => v.document === vendor);

    if (!vendorDocument) {
      toast.error(
        "Houve um erro ao carregar os dados do destinatário, por favor, tente novamente."
      );
      return;
    }

    const vendorData = await mutateAsync({ vendor: vendorDocument.document });

    form.setValue("name", vendorData.name);
    form.setValue("document", vendorData.document);
    form.setValue("stateRegistration", vendorData.stateRegistration ?? "");
    // form.setValue("email", vendorData.email ?? "");
    // form.setValue("phone", vendorData.phone ?? "");
    form.setValue("address.street", vendorData.address?.street ?? "");
    form.setValue("address.number", vendorData.address?.number ?? "");
    form.setValue("address.district", vendorData.address?.district ?? "");
    form.setValue("address.city", vendorData.address?.city ?? "");
    form.setValue("address.state", vendorData.address?.state ?? "");
    form.setValue("address.zipCode", vendorData.address?.zipCode ?? "");

    toast.success(
      "As informações do destinatário foram carregadas com sucesso!"
    );
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Dados do Destinatário</CardTitle>
          <CardDescription>
            Preencha os dados da empresa que você irá emitir a nota fiscal
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          {!!data && (
            <Form {...vendorSelectForm}>
              <form>
                <FormField
                  name="vendor"
                  render={({ field }) => (
                    <Select
                      onValueChange={async (value) => {
                        field.onChange(value);
                        await updateFields(value);
                      }}
                      defaultValue={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione o CNPJ" />
                      </SelectTrigger>
                      <SelectContent>
                        {data.vendors.map((vendor) => (
                          <SelectItem
                            key={vendor.document}
                            value={vendor.document}
                          >
                            {vendor.document} - {vendor.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </form>
            </Form>
          )}
          <Separator />
          <Form {...form}>
            <form onSubmit={submit}>
              <div className="grid grid-cols-6 gap-3">
                <FormField
                  name="name"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormLabel>Razão Social</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="documentType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="space-x-2 inline-flex items-center">
                        <span>Tipo do Documento</span>
                        <InfoIcon size={14} />
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <div className="inline-flex space-x-3">
                            <FormItem className="flex items-center space-x-2">
                              <FormControl>
                                <RadioGroupItem value="CNPJ" />
                              </FormControl>
                              <FormLabel className="font-normal">
                                CNPJ
                              </FormLabel>
                            </FormItem>
                            <FormItem className="flex items-center space-x-2">
                              <FormControl>
                                <RadioGroupItem value="CPF" />
                              </FormControl>
                              <FormLabel className="font-normal">CPF</FormLabel>
                            </FormItem>
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="document"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel className="space-x-2 inline-flex items-center">
                        <span>CNPJ</span>
                        <InfoIcon size={14} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="stateRegistration"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inscrição Estadual</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.street"
                  render={({ field }) => (
                    <FormItem className="col-span-5">
                      <FormLabel>Logradouro</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Número</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.district"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Bairro</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.city"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Município</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>UF</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.zipCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>CEP</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="address.country"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>País</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/*<FormField*/}
                {/*  name="phone"*/}
                {/*  render={({ field }) => (*/}
                {/*    <FormItem>*/}
                {/*      <FormLabel>Telefone</FormLabel>*/}
                {/*      <FormControl>*/}
                {/*        <Input {...field} />*/}
                {/*      </FormControl>*/}
                {/*      <FormMessage />*/}
                {/*    </FormItem>*/}
                {/*  )}*/}
                {/*/>*/}
                {/*<FormField*/}
                {/*  name="email"*/}
                {/*  render={({ field }) => (*/}
                {/*    <FormItem className="col-span-3">*/}
                {/*      <FormLabel>E-mail</FormLabel>*/}
                {/*      <FormControl>*/}
                {/*        <Input {...field} />*/}
                {/*      </FormControl>*/}
                {/*      <FormMessage />*/}
                {/*    </FormItem>*/}
                {/*  )}*/}
                {/*/>*/}
              </div>
              <div className="flex justify-end mt-6">
                <Button variant="outline">Salvar</Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
