import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { Link, useLocation } from "react-router-dom";

interface SidebarNavProps extends React.HTMLAttributes<HTMLDivElement> {
  items: {
    label: string;
    href: string;
  }[];
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  const { pathname } = useLocation();

  return (
    <nav
      className={cn(
        "flex space-x-2 flex-col lg:space-x-0 lg:space-y-1",
        className
      )}
      {...props}
    >
      {items.map((item) => {
        const isActive = pathname.endsWith(item.href);

        return (
          <Button
            asChild={true}
            key={item.href}
            variant={isActive ? "secondary" : "ghost"}
            className="justify-start"
          >
            <Link to={item.href}>{item.label}</Link>
          </Button>
        );
      })}
    </nav>
  );
}
