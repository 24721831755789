import { useQueries } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { FormEditNfseTaxes } from "@/pages/companies/[document]/components/form-edit-nfse-taxes.tsx";
import type { NfeTaxes } from "@shared/schemas/taxes/nfe-taxes.ts";
import type { NfseTaxes } from "@shared/schemas/taxes/nfse-taxes.ts";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { FormEditNfeTaxes } from "@/pages/companies/[document]/components/form-edit-nfe-taxes.tsx";

const services = [
  {
    item: "service",
    component: FormEditNfseTaxes,
  },
  {
    item: "product",
    component: FormEditNfeTaxes,
  },
];

export function TaxesComponent() {
  const { document } = useParams();

  const { taxes, allowedServices, isLoading } = useQueries({
    queries: [
      {
        queryKey: [`/companies/${document}/services`],
        enabled: !!document,
      },
      {
        queryKey: [`/companies/${document}/taxes`],
        enabled: !!document,
      },
    ],
    combine: (results) => {
      return {
        allowedServices: results?.[0]?.data as { services: string[] },
        taxes: results?.[1]?.data as {
          product: NfeTaxes | null;
          service: NfseTaxes | null;
        },
        isLoading: results.some((result) => result.isLoading),
      };
    },
  });

  if (isLoading) {
    return <Loader2 className="animate-spin" />;
  }

  const enabledServices = services.filter((service) => {
    return allowedServices.services.includes(service.item);
  });

  if (!enabledServices.length) {
    return (
      <Alert>
        <AlertTitle>Esta empresa não possui serviços habilitados.</AlertTitle>
        <AlertDescription>
          Você pode{" "}
          <span className="font-bold">habilitá-los na aba de serviços</span> da
          empresa.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      {enabledServices.map((service) => (
        <service.component taxes={taxes} key={service.item} />
      ))}
    </>
  );
}
